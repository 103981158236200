
import React, { useState } from 'react';

import gradient from 'random-gradient'
import html2canvas from 'html2canvas';
import { getPos, pSBC, getRandomImage, getRandomFont, upload } from "./util"
import {dados} from './data_base'

const name = 'vertical'
export default ({ name }) => {
  const defaultColor = 'rgb(255 255 255 / 21%)'
  const [idx, setIdx] = useState(0);

  let x = dados()[0];
  const [texto, setTexto] = useState(x['descricao']);
  const [textoCategoria, setTextoCategoria] = useState(x['descricao_categoria'] + ' - frase: ' + x['id']);
  const [tipo, setTipo] = useState();
  const [colorTexto, setColorTexto] = useState('#fff');
  const [bgDivTexto, setBgDivTexto] = useState(defaultColor);
  const [font, setFont] = useState(getRandomFont());
  const [url, setUrl] = useState('https://picsum.photos/seed/picsum/1080/1080.jpg');

  const printRef = React.useRef();
  const skip = () => {
    next()
  }
  
  const handleDownloadImage = async () => {

    html2canvas(document.querySelector("#print"), {
      logging: true, letterRendering: 1, // allowTaint: false  
      useCORS: true
    }).then(canvas => {

      let frase = dados()[idx];
      const nome = frase['descricao_categoria'].replaceAll(/ /g,'_') + '_' + frase['id'] + '.jpg';

      // let data = canvas.toDataURL('image/jpg');
      // data = data.replace(/^data:image\/(png|jpg);base64,/, "");
      
      canvas.toBlob(function(blob) {
        const formData = new FormData();
        formData.append('nome', blob, nome);
      
        // Post via axios or other transport method
        upload(nome,  frase['id_categoria'], formData);
      });

      // const link = document.createElement('a');

      // if (typeof link.download === 'string') {
      //   link.href = data;
      //   let frase = dados()[idx];
      //   link.download = frase['descricao_categoria'] + '_' + frase['id'] + '.jpg';

      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      // } else {
      //   window.open(data);
      // }
    })

    next();
  };

  function next() {
    setIdx(idx + 1);
    const frase = dados()[idx]
    setTexto(frase['descricao']);
    setTextoCategoria(frase['descricao_categoria'] + ' - frase: ' + x['id']);
    setTipo(getPos());
    setUrl(getRandomImage());

    const txt_gradient = gradient(texto, tipo);

    let color = '';
    if (txt_gradient.indexOf('rgb') == -1) {
      color = '#' + (txt_gradient.split('#')[1]).split(',')[0];
    } else if (txt_gradient.indexOf('rgb') > -1) {
      color = 'rgb' + txt_gradient.split('rgb')[1];
      color = color.substring(0, color.length - 2);
    }

    if (color == '') color = defaultColor;
    else color = color ;

    let color1 = pSBC(0.8, color);
    setBgDivTexto(color1 + '5f');
  }

  let txt_gradient = gradient(texto, tipo);
  let vet = txt_gradient.split('#')
  txt_gradient = vet[0] + "#" + vet[1].replace(',', '42') + ',' + '#' + vet[2].replace(')', '42') + ')'

  let bgGradient = { 'display': 'grid', 'placeItems': 'center', 'width': '1080px', 'height': '1080px', 'backgroundImage': txt_gradient + ', url(' + url + ')' }

  return (
    <div>
      <button type="button" onClick={handleDownloadImage}>Download</button>
      <button type="button" onClick={skip}>Skip</button>
      <button type="button" onClick={() =>  setUrl(getRandomImage())}>Trocar Imagem</button>
      <button type="button" onClick={() =>  setColorTexto(colorTexto=='#fff'?'#000':'#fff')}>Trocar Cor</button>
      <button type="button" onClick={() =>  setFont(getRandomFont())}>Trocar fonte</button>
      <label style={{fontFamily:'verdana'}}>{textoCategoria}</label>
      <div id="print" ref={printRef} style={bgGradient} >
        <div style={{
          display: 'grid',
          textAlign: 'center',
          color: colorTexto,
          fontSize: (70) + 'px',
          padding: '50px',
          width: '50%',
          fontFamily: font,
          borderRadius: '10px',
          boxShadow: '10px 10px 20px 3px rgba(219, 219, 219,0.46)',
          backgroundColor: bgDivTexto
        }}>
          <span style={{ textShadow: colorTexto=='#fff'?'rgba(85, 85, 85, 56%) 3px 2px 4px':'none'}}>{texto}</span>
        </div>
        <h1 style={{color:'#fff', fontFamily:"monospace", fontWeight:'bold', fontSize:'35px'}}>App - Tema Bíblico</h1>
      </div>
    </div>
  )
}


