export const dados = () => {
    const a = [
        {"descricao_categoria":"O Adultério","descricao":"Cova profunda é a boca das mulheres estranhas; aquele contra quem o Senhor se irar, cairá nela. Provérbios 22:14","id_categoria":"52","id":"1068"},
        {"descricao_categoria":"O Adultério","descricao":"Porque do coração procedem os maus pensamentos, mortes, adultérios, fornicação, furtos, falsos testemunhos e blasfêmias. Mateus 15:19","id_categoria":"52","id":"1070"},
        {"descricao_categoria":"O Adultério","descricao":"Qualquer que deixa sua mulher, e casa com outra, adultera; e aquele que casa com a repudiada pelo marido, adultera também. Lucas 16:18","id_categoria":"52","id":"1077"},
        {"descricao_categoria":"O que é Bom","descricao":"E viu Deus tudo quanto tinha feito, e eis que era muito bom; e foi a tarde e a manhã, o dia sexto. Gênesis 1:31","id_categoria":"53","id":"1100"},
        {"descricao_categoria":"O que é Bom","descricao":"Mas para mim, bom é aproximar-me de Deus; pus a minha confiança no Senhor DEUS, para anunciar todas as tuas obras. Salmos 73:28","id_categoria":"53","id":"1101"},
        {"descricao_categoria":"O que é Bom","descricao":"Toda a boa dádiva e todo o dom perfeito vem do alto, descendo do Pai das luzes, em quem não há mudança nem sombra de variação. Tiago 1:17","id_categoria":"53","id":"1102"},
        {"descricao_categoria":"O que é Bom","descricao":"Bom é o sal; mas, se o sal se tornar insípido, com que o temperareis? Tende sal em vós mesmos, e paz uns com os outros. Marcos 9:50","id_categoria":"53","id":"1103"},
        {"descricao_categoria":"O que é Bom","descricao":"Oh! quão bom e quão suave é que os irmãos vivam em união. Salmos 133:1","id_categoria":"53","id":"1104"},
        {"descricao_categoria":"O que é Bom","descricao":"Porque o Senhor é bom, e eterna a sua misericórdia; e a sua verdade dura de geração em geração. Salmos 100:5","id_categoria":"53","id":"1107"},
        {"descricao_categoria":"O que é Bom","descricao":"Não seja, pois, blasfemado o vosso bem; Romanos 14:16","id_categoria":"53","id":"1108"},
        {"descricao_categoria":"O Deus do Impossível","descricao":"Mas ele respondeu: As coisas que são impossíveis aos homens são possíveis a Deus. Lucas 18:27","id_categoria":"54","id":"1112"},
        {"descricao_categoria":"O Deus do Impossível","descricao":"Por isso vos digo que todas as coisas que pedirdes, orando, crede receber, e tê-las-eis. Marcos 11:24","id_categoria":"54","id":"1113"},
        {"descricao_categoria":"O Deus do Impossível","descricao":"Bem sei eu que tudo podes, e que nenhum dos teus propósitos pode ser impedido. Jó 42:2","id_categoria":"54","id":"1114"},
        {"descricao_categoria":"O Deus do Impossível","descricao":"E Jesus disse-lhe: Se tu podes crer, tudo é possível ao que crê. Marcos 9:23","id_categoria":"54","id":"1115"},
        {"descricao_categoria":"O Deus do Impossível","descricao":"Grande é o nosso Senhor, e de grande poder; o seu entendimento é infinito. Salmos 147:5","id_categoria":"54","id":"1118"},
        {"descricao_categoria":"O Deus do Impossível","descricao":"E Jesus, olhando para eles, disse-lhes: Aos homens é isso impossível, mas a Deus tudo é possível. Mateus 19:26","id_categoria":"54","id":"1119"},
        {"descricao_categoria":"O Espírito Santo","descricao":"O qual também nos selou e deu o penhor do Espírito em nossos corações. 2 Coríntios 1:22","id_categoria":"55","id":"1124"},
        {"descricao_categoria":"O Espírito Santo","descricao":"E a esperança não traz confusão, porquanto o amor de Deus está derramado em nossos corações pelo Espírito Santo que nos foi dado. Romanos 5:5","id_categoria":"55","id":"1157"},
        {"descricao_categoria":"O Espírito Santo","descricao":"E, servindo eles ao Senhor, e jejuando, disse o Espírito Santo: Apartai-me a Barnabé e a Saulo para a obra a que os tenho chamado. Atos 13:2","id_categoria":"55","id":"1159"},
        {"descricao_categoria":"O Espírito Santo","descricao":"Não sabeis vós que sois o templo de Deus e que o Espírito de Deus habita em vós? 1 Coríntios 3:16","id_categoria":"55","id":"1161"},
        {"descricao_categoria":"O Espírito Santo","descricao":"Porque o reino de Deus não é comida nem bebida, mas justiça, e paz, e alegria no Espírito Santo. Romanos 14:17","id_categoria":"55","id":"1162"},
        {"descricao_categoria":"O Espírito Santo","descricao":"Ora o Deus de esperança vos encha de todo o gozo e paz em crença, para que abundeis em esperança pela virtude do Espírito Santo. Romanos 15:13","id_categoria":"55","id":"1164"},
        {"descricao_categoria":"O Espírito Santo","descricao":"E não vos embriagueis com vinho, em que há contenda, mas enchei-vos do Espírito; Efésios 5:18","id_categoria":"55","id":"1165"},
        {"descricao_categoria":"O Juízo Final","descricao":"Porque Deus há de trazer a juízo toda a obra, e até tudo o que está encoberto, quer seja bom, quer seja mau. Eclesiastes 12:14","id_categoria":"56","id":"1168"},
        {"descricao_categoria":"O Juízo Final","descricao":"Ante a face do Senhor, porque vem, porque vem a julgar a terra; julgará o mundo com justiça e os povos com a sua verdade. Salmos 96:13","id_categoria":"56","id":"1173"},
        {"descricao_categoria":"O Juízo Final","descricao":"E, eis que cedo venho, e o meu galardão está comigo, para dar a cada um segundo a sua obra. Apocalipse 22:12","id_categoria":"56","id":"1181"},
        {"descricao_categoria":"O Juízo Final","descricao":"No dia em que Deus há de julgar os segredos dos homens, por Jesus Cristo, segundo o meu evangelho. Romanos 2:16","id_categoria":"56","id":"1183"},
        {"descricao_categoria":"O Juízo Final","descricao":"Eu disse no meu coração: Deus julgará o justo e o ímpio; porque há um tempo para todo o propósito e para toda a obra. Eclesiastes 3:17","id_categoria":"56","id":"1189"},
        {"descricao_categoria":"O Marido","descricao":"Fugi da fornicação. Todo o pecado que o homem comete é fora do corpo; mas o que fornica peca contra o seu próprio corpo. 1 Coríntios 6:18","id_categoria":"57","id":"1190"},
        {"descricao_categoria":"O Marido","descricao":"Aquele que encontra uma esposa, acha o bem, e alcança a benevolência do Senhor. Provérbios 18:22","id_categoria":"57","id":"1194"},
        {"descricao_categoria":"O Marido","descricao":"Eu, porém, vos digo, que qualquer que atentar numa mulher para a cobiçar, já em seu coração cometeu adultério com ela. Mateus 5:28","id_categoria":"57","id":"1195"},
        {"descricao_categoria":"O Marido","descricao":"E, sobre tudo isto, revesti-vos de amor, que é o vínculo da perfeição. Colossenses 3:14","id_categoria":"57","id":"1197"},
        {"descricao_categoria":"O Marido","descricao":"Vede que ninguém dê a outrem mal por mal, mas segui sempre o bem, tanto uns para com os outros, como para com todos. 1 Tessalonicenses 5:15","id_categoria":"57","id":"1200"},
        {"descricao_categoria":"O Marido","descricao":"Assim devem os maridos amar as suas próprias mulheres, como a seus próprios corpos. Quem ama a sua mulher, ama-se a si mesmo. Efésios 5:28","id_categoria":"57","id":"1202"},
        {"descricao_categoria":"O Marido","descricao":"Assim não são mais dois, mas uma só carne. Portanto, o que Deus ajuntou não o separe o homem. Mateus 19:6","id_categoria":"57","id":"1204"},
        {"descricao_categoria":"O Marido","descricao":"Vós, maridos, amai vossas mulheres, como também Cristo amou a igreja, e a si mesmo se entregou por ela, Efésios 5:25","id_categoria":"57","id":"1209"},
        {"descricao_categoria":"O Marido","descricao":"Portanto deixará o homem o seu pai e a sua mãe, e apegar-se-á à sua mulher, e serão ambos uma carne. Gênesis 2:24","id_categoria":"57","id":"1210"},
        {"descricao_categoria":"O Marido","descricao":"Assim também vós, cada um em particular, ame a sua própria mulher como a si mesmo, e a mulher reverencie o marido. Efésios 5:33","id_categoria":"57","id":"1211"},
        {"descricao_categoria":"O Marido","descricao":"Vós, maridos, amai a vossas mulheres, e não vos irriteis contra elas. Colossenses 3:19","id_categoria":"57","id":"1215"},
        {"descricao_categoria":"Obediência aos Pais","descricao":"O que ferir a seu pai, ou a sua mãe, certamente será morto. Êxodo 21:15","id_categoria":"58","id":"1216"},
        {"descricao_categoria":"Obediência aos Pais","descricao":"A vara e a repreensão dão sabedoria, mas a criança entregue a si mesma, envergonha a sua mãe. Provérbios 29:15","id_categoria":"58","id":"1217"},
        {"descricao_categoria":"Obediência aos Pais","descricao":"O filho sábio atende à instrução do pai; mas o escarnecedor não ouve a repreensão. Provérbios 13:1","id_categoria":"58","id":"1218"},
        {"descricao_categoria":"Obediência aos Pais","descricao":"Porque Deus ordenou, dizendo: Honra a teu pai e a tua mãe; e: Quem maldisser ao pai ou à mãe, certamente morrerá. Mateus 15:4","id_categoria":"58","id":"1226"},
        {"descricao_categoria":"Obediência aos Pais","descricao":"Honra a teu pai e a tua mãe, para que se prolonguem os teus dias na terra que o Senhor teu Deus te dá. Êxodo 20:12","id_categoria":"58","id":"1227"},
        {"descricao_categoria":"Obediência aos Pais","descricao":"Educa a criança no caminho em que deve andar; e até quando envelhecer não se desviará dele. Provérbios 22:6","id_categoria":"58","id":"1229"},
        {"descricao_categoria":"Obediência aos Pais","descricao":"Vós, filhos, obedecei em tudo a vossos pais, porque isto é agradável ao Senhor. Colossenses 3:20","id_categoria":"58","id":"1230"},
        {"descricao_categoria":"Obediência","descricao":"Porém, respondendo Pedro e os apóstolos, disseram: Mais importa obedecer a Deus do que aos homens. Atos 5:29","id_categoria":"59","id":"1231"},
        {"descricao_categoria":"Obediência","descricao":"E nós somos testemunhas acerca destas palavras, nós e também o Espírito Santo, que Deus deu àqueles que lhe obedecem. Atos 5:32","id_categoria":"59","id":"1232"},
        {"descricao_categoria":"Obediência","descricao":"Se me amais, guardai os meus mandamentos. João 14:15","id_categoria":"59","id":"1234"},
        {"descricao_categoria":"Obediência","descricao":"Jesus respondeu, e disse-lhe: Se alguém me ama, guardará a minha palavra, e meu Pai o amará, e viremos para ele, e faremos nele morada. João 14:23","id_categoria":"59","id":"1240"},
        {"descricao_categoria":"Obediência","descricao":"O caminho para a vida é daquele que guarda a instrução, mas o que deixa a repreensão comete erro. Provérbios 10:17","id_categoria":"59","id":"1243"},
        {"descricao_categoria":"Obediência","descricao":"Em ouvindo a minha voz, me obedecerão; os estranhos se submeterão a mim. Salmos 18:44","id_categoria":"59","id":"1244"},
        {"descricao_categoria":"Obediência","descricao":"Como filhos obedientes, não vos conformando com as concupiscências que antes havia em vossa ignorância; 1 Pedro 1:14","id_categoria":"59","id":"1245"},
        {"descricao_categoria":"Obediência","descricao":"A fim de que, pela vontade de Deus, chegue a vós com alegria, e possa recrear-me convosco. Romanos 15:32","id_categoria":"59","id":"1246"},
        {"descricao_categoria":"Obediência","descricao":"Veio, porém, a lei para que a ofensa abundasse; mas, onde o pecado abundou, superabundou a graça; Romanos 5:20","id_categoria":"59","id":"1247"},
        {"descricao_categoria":"Obediência","descricao":"Não sejais vagarosos no cuidado; sede fervorosos no espírito, servindo ao Senhor; Romanos 12:11","id_categoria":"59","id":"1248"},
        {"descricao_categoria":"Obediência","descricao":"Porque todos os que são guiados pelo Espírito de Deus esses são filhos de Deus. Romanos 8:14","id_categoria":"59","id":"1249"},
        {"descricao_categoria":"Obediência","descricao":"Sujeitai-vos, pois, a Deus, resisti ao diabo, e ele fugirá de vós. Tiago 4:7","id_categoria":"59","id":"1251"},
        {"descricao_categoria":"Obediência","descricao":"E dizia a todos: Se alguém quer vir após mim, negue-se a si mesmo, e tome cada dia a sua cruz, e siga-me. Lucas 9:23","id_categoria":"59","id":"1252"},
        {"descricao_categoria":"Obediência","descricao":"Porque este é o amor de Deus: que guardemos os seus mandamentos; e os seus mandamentos não são pesados. 1 João 5:3","id_categoria":"59","id":"1254"},
        {"descricao_categoria":"Obediência","descricao":"E sede cumpridores da palavra, e não somente ouvintes, enganando-vos a vós mesmos. Tiago 1:22","id_categoria":"59","id":"1255"},
        {"descricao_categoria":"Obediência","descricao":"E toda a língua confesse que Jesus Cristo é o Senhor, para glória de Deus Pai. Filipenses 2:11","id_categoria":"59","id":"1257"},
        {"descricao_categoria":"Obediência","descricao":"Filho meu, guarda o mandamento de teu pai, e não deixes a lei da tua mãe; Provérbios 6:20","id_categoria":"59","id":"1265"},
        {"descricao_categoria":"Obediência","descricao":"E por que me chamais, Senhor, Senhor, e não fazeis o que eu digo? Lucas 6:46","id_categoria":"59","id":"1266"},
        {"descricao_categoria":"Oportunidades","descricao":"Do qual fui feito ministro, pelo dom da graça de Deus, que me foi dado segundo a operação do seu poder. Efésios 3:7","id_categoria":"60","id":"1268"},
        {"descricao_categoria":"Oportunidades","descricao":"Remindo o tempo; porquanto os dias são maus. Efésios 5:16","id_categoria":"60","id":"1269"},
        {"descricao_categoria":"Oportunidades","descricao":"Andai com sabedoria para com os que estão de fora, remindo o tempo. Colossenses 4:5","id_categoria":"60","id":"1270"},
        {"descricao_categoria":"Oportunidades","descricao":"E agora por que te deténs? Levanta-te, e batiza-te, e lava os teus pecados, invocando o nome do Senhor. Atos 22:16","id_categoria":"60","id":"1271"},
        {"descricao_categoria":"Oportunidades","descricao":"E eles, ouvindo-o, folgaram, e prometeram dar-lhe dinheiro; e buscava como o entregaria em ocasião oportuna. Marcos 14:11","id_categoria":"60","id":"1272"},
        {"descricao_categoria":"Oportunidades","descricao":"Que pregues a palavra, instes a tempo e fora de tempo, redarguas, repreendas, exortes, com toda a longanimidade e doutrina. 2 Timóteo 4:2","id_categoria":"60","id":"1274"},
        {"descricao_categoria":"Oportunidades","descricao":"Convém que eu faça as obras daquele que me enviou, enquanto é dia; a noite vem, quando ninguém pode trabalhar. João 9:4","id_categoria":"60","id":"1277"},
        {"descricao_categoria":"Oportunidades","descricao":"Então, enquanto temos tempo, façamos bem a todos, mas principalmente aos domésticos da fé. Gálatas 6:10","id_categoria":"60","id":"1278"},
        {"descricao_categoria":"Oração","descricao":"Chegai-vos a Deus, e ele se chegará a vós. Alimpai as mãos, pecadores; e, vós de duplo ânimo, purificai os corações. Tiago 4:8","id_categoria":"61","id":"1279"},
        {"descricao_categoria":"Oração","descricao":"Orai sem cessar. 1 Tessalonicenses 5:17","id_categoria":"61","id":"1280"},
        {"descricao_categoria":"Oração","descricao":"Clama a mim, e responder-te-ei, e anunciar-te-ei coisas grandes e firmes que não sabes. Jeremias 33:3","id_categoria":"61","id":"1282"},
        {"descricao_categoria":"Oração","descricao":"Se vós estiverdes em mim, e as minhas palavras estiverem em vós, pedireis tudo o que quiserdes, e vos será feito. João 15:7","id_categoria":"61","id":"1301"},
        {"descricao_categoria":"Oração","descricao":"E, perto da meia-noite, Paulo e Silas oravam e cantavam hinos a Deus, e os outros presos os escutavam. Atos 16:25","id_categoria":"61","id":"1302"},
        {"descricao_categoria":"Oração","descricao":"Regozijai-vos sempre. Orai sem cessar. Em tudo dai graças, porque esta é a vontade de Deus em Cristo Jesus para convosco. 1 Tessalonicenses 5:16-18","id_categoria":"61","id":"1353"},
        {"descricao_categoria":"Órfãos","descricao":"Não vos deixarei órfãos; voltarei para vós. João 14:18","id_categoria":"62","id":"1367"},
        {"descricao_categoria":"Órfãos","descricao":"O Senhor guarda os estrangeiros; sustém o órfão e a viúva, mas transtorna o caminho dos ímpios. Salmos 146:9","id_categoria":"62","id":"1369"},
        {"descricao_categoria":"Órfãos","descricao":"Aprendei a fazer bem; procurai o que é justo; ajudai o oprimido; fazei justiça ao órfão; tratai da causa das viúvas. Isaías 1:17","id_categoria":"62","id":"1373"},
        {"descricao_categoria":"Orgulho","descricao":"Sede unânimes entre vós; não ambicioneis coisas altas, mas acomodai-vos às humildes; não sejais sábios em vós mesmos; Romanos 12:16","id_categoria":"63","id":"1374"},
        {"descricao_categoria":"Orgulho","descricao":"Em vindo a soberba, virá também a afronta; mas com os humildes está a sabedoria. Provérbios 11:2","id_categoria":"63","id":"1376"},
        {"descricao_categoria":"Orgulho","descricao":"O galardão da humildade e o temor do Senhor são riquezas, honra e vida. Provérbios 22:4","id_categoria":"63","id":"1400"},
        {"descricao_categoria":"Orgulho","descricao":"Humilhai-vos perante o Senhor, e ele vos exaltará. Tiago 4:10","id_categoria":"63","id":"1401"},
        {"descricao_categoria":"Ouvir a voz de Deus","descricao":"No princípio era o Verbo, e o Verbo estava com Deus, e o Verbo era Deus. João 1:1","id_categoria":"64","id":"1405"},
        {"descricao_categoria":"Ouvir a voz de Deus","descricao":"Quem é de Deus escuta as palavras de Deus; por isso vós não as escutais, porque não sois de Deus. João 8:47","id_categoria":"64","id":"1419"},
        {"descricao_categoria":"Ouvir","descricao":"Quem tem ouvidos para ouvir, ouça. Mateus 13:9","id_categoria":"65","id":"1421"},
        {"descricao_categoria":"Ouvir","descricao":"E se andardes contrariamente para comigo, e não me quiserdes ouvir, trar-vos-ei pragas sete vezes mais, conforme os vossos pecados. Levítico 26:21","id_categoria":"65","id":"1423"},
        {"descricao_categoria":"Ouvir","descricao":"O que desvia os seus ouvidos de ouvir a lei, até a sua oração será abominável. Provérbios 28:9","id_categoria":"65","id":"1424"},
        {"descricao_categoria":"Ouvir","descricao":"Porquanto tudo o que em trevas dissestes, à luz será ouvido; e o que falastes ao ouvido no gabinete, sobre os telhados será apregoado. Lucas 12:3","id_categoria":"65","id":"1457"},
        {"descricao_categoria":"Ouvir","descricao":"Melhor é ouvir a repreensão do sábio, do que ouvir alguém a canção do tolo. Eclesiastes 7:5","id_categoria":"65","id":"1461"},
        {"descricao_categoria":"Ouvir","descricao":"Eis que a mão do SENHOR não está encolhida, para que não possa salvar; nem agravado o seu ouvido, para não poder ouvir. Isaías 59:1","id_categoria":"65","id":"1462"},
        {"descricao_categoria":"Ouvir","descricao":"O caminho para a vida é daquele que guarda a instrução, mas o que deixa a repreensão comete erro. Provérbios 10:17","id_categoria":"65","id":"1463"},
        {"descricao_categoria":"O Pão da Vida","descricao":"E Jesus lhes disse: Eu sou o pão da vida; aquele que vem a mim não terá fome, e quem crê em mim nunca terá sede. João 6:35","id_categoria":"66","id":"1465"},
        {"descricao_categoria":"O Pão da Vida","descricao":"Disse-lhe Jesus: Eu sou o caminho, e a verdade e a vida; ninguém vem ao Pai, senão por mim. João 14:6","id_categoria":"66","id":"1467"},
        {"descricao_categoria":"O Pão da Vida","descricao":"Bem-aventurados os que têm fome e sede de justiça, porque eles serão fartos; Mateus 5:6","id_categoria":"66","id":"1469"},
        {"descricao_categoria":"O Papel do Homem","descricao":"Vós, maridos, amai a vossas mulheres, e não vos irriteis contra elas. Colossenses 3:19","id_categoria":"68","id":"1493"},
        {"descricao_categoria":"O Papel do Homem","descricao":"E não vos embriagueis com vinho, em que há contenda, mas enchei-vos do Espírito; Efésios 5:18","id_categoria":"68","id":"1494"},
        {"descricao_categoria":"O Papel do Homem","descricao":"Mas, se alguém não tem cuidado dos seus, e principalmente dos da sua família, negou a fé, e é pior do que o infiel. 1 Timóteo 5:8","id_categoria":"68","id":"1496"},
        {"descricao_categoria":"O Papel do Homem","descricao":"Vós, maridos, amai vossas mulheres, como também Cristo amou a igreja, e a si mesmo se entregou por ela, Efésios 5:25","id_categoria":"68","id":"1497"},
        {"descricao_categoria":"O Papel do Homem","descricao":"E tudo quanto fizerdes, fazei-o de todo o coração, como ao Senhor, e não aos homens, Colossenses 3:23","id_categoria":"68","id":"1498"},
        {"descricao_categoria":"O Papel do Homem","descricao":"E vós, pais, não provoqueis à ira a vossos filhos, mas criai-os na doutrina e admoestação do Senhor. Efésios 6:4","id_categoria":"68","id":"1500"},
        {"descricao_categoria":"O Poder da Oração","descricao":"Pedi, e dar-se-vos-á; buscai, e encontrareis; batei, e abrir-se-vos-á. Mateus 7:7","id_categoria":"69","id":"1503"},
        {"descricao_categoria":"O Poder da Oração","descricao":"Por isso vos digo que todas as coisas que pedirdes, orando, crede receber, e tê-las-eis. Marcos 11:24","id_categoria":"69","id":"1507"},
        {"descricao_categoria":"O Poder da Oração","descricao":"E disse-lhes: Esta casta não pode sair com coisa alguma, a não ser com oração e jejum. Marcos 9:29","id_categoria":"69","id":"1508"},
        {"descricao_categoria":"O Poder da Oração","descricao":"E, tudo o que pedirdes em oração, crendo, o recebereis. Mateus 21:22","id_categoria":"69","id":"1510"},
        {"descricao_categoria":"O Sangue de Jesus","descricao":"E quase todas as coisas, segundo a lei, se purificam com sangue; e sem derramamento de sangue não há remissão. Hebreus 9:22","id_categoria":"70","id":"1513"},
        {"descricao_categoria":"O Sangue de Jesus","descricao":"Em quem temos a redenção pelo seu sangue, a remissão das ofensas, segundo as riquezas da sua graça, Efésios 1:7","id_categoria":"70","id":"1515"},
        {"descricao_categoria":"O Sangue de Jesus","descricao":"E a Jesus, o Mediador de uma nova aliança, e ao sangue da aspersão, que fala melhor do que o de Abel. Hebreus 12:24","id_categoria":"70","id":"1527"},
        {"descricao_categoria":"O Sangue de Jesus","descricao":"Logo muito mais agora, tendo sido justificados pelo seu sangue, seremos por ele salvos da ira. Romanos 5:9","id_categoria":"70","id":"1530"},
        {"descricao_categoria":"O Senhor é o Meu Pastor","descricao":"O SENHOR é o meu pastor, nada me faltará. Salmos 23:1","id_categoria":"71","id":"1533"},
        {"descricao_categoria":"O Senhor é o Meu Pastor","descricao":"Salva o teu povo, e abençoa a tua herança; e apascenta-os e exalta-os para sempre. Salmos 28:9","id_categoria":"71","id":"1535"},
        {"descricao_categoria":"Batismo","descricao":"E agora por que te deténs? Levanta-te, e batiza-te, e lava os teus pecados, invocando o nome do Senhor. Atos 22:16","id_categoria":"72","id":"1569"},
        {"descricao_categoria":"Batismo","descricao":"E os que ouviram foram batizados em nome do Senhor Jesus. Atos 19:5","id_categoria":"72","id":"1570"},
        {"descricao_categoria":"Batismo","descricao":"Então veio Jesus da Galiléia ter com João, junto do Jordão, para ser batizado por ele. Mateus 3:13","id_categoria":"72","id":"1573"},
        {"descricao_categoria":"Beleza","descricao":"Porque somos feitura sua, criados em Cristo Jesus para as boas obras, as quais Deus preparou para que andássemos nelas. Efésios 2:10","id_categoria":"73","id":"1574"},
        {"descricao_categoria":"Beleza","descricao":"Levanta-te, resplandece, porque vem a tua luz, e a glória do SENHOR vai nascendo sobre ti; Isaías 60:1","id_categoria":"73","id":"1575"},
        {"descricao_categoria":"Beleza","descricao":"Por isso não desfalecemos; mas, ainda que o nosso homem exterior se corrompa, o interior, contudo, se renova de dia em dia. 2 Coríntios 4:16","id_categoria":"73","id":"1576"},
        {"descricao_categoria":"Beleza","descricao":"Seca-se a erva, e cai a flor, porém a palavra de nosso Deus subsiste eternamente. Isaías 40:8","id_categoria":"73","id":"1579"},
        {"descricao_categoria":"Bem","descricao":"E qual é aquele que vos fará mal, se fordes seguidores do bem? 1 Pedro 3:13","id_categoria":"74","id":"1598"},
        {"descricao_categoria":"Bem","descricao":"O que o homem mais deseja é o que lhe faz bem; porém é melhor ser pobre do que mentiroso. Provérbios 19:22","id_categoria":"74","id":"1599"},
        {"descricao_categoria":"Bem","descricao":"Sede, pois, misericordiosos, como também vosso Pai é misericordioso. Lucas 6:36","id_categoria":"74","id":"1600"},
        {"descricao_categoria":"Bem","descricao":"O qual se deu a si mesmo por nós para nos remir de toda a iniqüidade, e purificar para si um povo seu especial, zeloso de boas obras. Tito 2:14","id_categoria":"74","id":"1601"},
        {"descricao_categoria":"Bem","descricao":"Então entenderás a justiça, o juízo, a eqüidade e todas as boas veredas. Provérbios 2:9","id_categoria":"74","id":"1602"},
        {"descricao_categoria":"Bem","descricao":"Não te deixes vencer do mal, mas vence o mal com o bem. Romanos 12:21","id_categoria":"74","id":"1603"},
        {"descricao_categoria":"Benção","descricao":"Abençoai aos que vos perseguem, abençoai, e não amaldiçoeis. Romanos 12:14","id_categoria":"75","id":"1618"},
        {"descricao_categoria":"Benção","descricao":"O meu Deus, segundo as suas riquezas, suprirá todas as vossas necessidades em glória, por Cristo Jesus. Filipenses 4:19","id_categoria":"75","id":"1619"},
        {"descricao_categoria":"Benção","descricao":"Nós, pois, jejuamos, e pedimos isto ao nosso Deus, e moveu-se pelas nossas orações. Esdras 8:23","id_categoria":"75","id":"1620"},
        {"descricao_categoria":"Benção","descricao":"A graça de nosso Senhor Jesus Cristo seja com vós todos. Amém. Filipenses 4:23","id_categoria":"75","id":"1621"},
        {"descricao_categoria":"Benção","descricao":"A bênção do Senhor é que enriquece; e não traz consigo dores. Provérbios 10:22","id_categoria":"75","id":"1622"},
        {"descricao_categoria":"Benção","descricao":"E servireis ao Senhor vosso Deus, e ele abençoará o vosso pão e a vossa água; e eu tirarei do meio de vós as enfermidades. Êxodo 23:25","id_categoria":"75","id":"1623"},
        {"descricao_categoria":"Bom Ânimo","descricao":"Porque Deus não nos deu o espírito de temor, mas de fortaleza, e de amor, e de moderação. 2 Timóteo 1:7","id_categoria":"77","id":"1688"},
        {"descricao_categoria":"Bom Ânimo","descricao":"Por isso estamos sempre de bom ânimo, sabendo que, enquanto estamos no corpo, vivemos ausentes do Senhor 2 Coríntios 5:6","id_categoria":"77","id":"1689"},
        {"descricao_categoria":"Bom Dia","descricao":"Não ames o sono, para que não empobreças; abre os teus olhos, e te fartarás de pão. Provérbios 20:13","id_categoria":"78","id":"1714"},
        {"descricao_categoria":"Bom Dia","descricao":"Farta-nos de madrugada com a tua benignidade, para que nos regozijemos, e nos alegremos todos os nossos dias. Salmos 90:14","id_categoria":"78","id":"1715"},
        {"descricao_categoria":"Bom Dia","descricao":"Porque a sua ira dura só um momento; no seu favor está a vida. O choro pode durar uma noite, mas a alegria vem pela manhã. Salmos 30:5","id_categoria":"78","id":"1723"},
        {"descricao_categoria":"Buscar a Deus","descricao":"Buscai ao Senhor e a sua força; buscai a sua face continuamente. 1 Crônicas 16:11","id_categoria":"79","id":"1724"},
        {"descricao_categoria":"Buscar a Deus","descricao":"Para que, segundo as riquezas da sua glória, vos conceda que sejais corroborados com poder pelo seu Espírito no homem interior; Efésios 3:16","id_categoria":"79","id":"1726"},
        {"descricao_categoria":"Buscar a Deus","descricao":"SENHOR, tu me sondaste, e me conheces. Tu sabes o meu assentar e o meu levantar; de longe entendes o meu pensamento. Salmos 139:1,2","id_categoria":"79","id":"1765"},
        {"descricao_categoria":"Buscar a Deus","descricao":"Porque o Filho do homem veio buscar e salvar o que se havia perdido. Lucas 19:10","id_categoria":"79","id":"1766"},
        {"descricao_categoria":"Casa de Deus","descricao":"Mas eu sou como a oliveira verde na casa de Deus; confio na misericórdia de Deus para sempre, eternamente. Salmos 52:8","id_categoria":"80","id":"1773"},
        {"descricao_categoria":"Casa de Deus","descricao":"Porque vale mais um dia nos teus átrios do que mil. Preferiria estar à porta da casa do meu Deus, a habitar nas tendas dos ímpios. Salmos 84:10","id_categoria":"80","id":"1776"},
        {"descricao_categoria":"Casa de Deus","descricao":"Mas, se tardar, para que saibas como convém andar na casa de Deus, que é a igreja do Deus vivo, a coluna e firmeza da verdade. 1 Timóteo 3:15","id_categoria":"80","id":"1777"},
        {"descricao_categoria":"Casa de Deus","descricao":"Alegrei-me quando me disseram: Vamos à casa do Senhor. Salmos 122:1","id_categoria":"80","id":"1778"},
        {"descricao_categoria":"Casa de Deus","descricao":"Pois o zelo da tua casa me devorou, e as afrontas dos que te afrontam caíram sobre mim. Salmos 69:9","id_categoria":"80","id":"1780"},
        {"descricao_categoria":"Casa de Deus","descricao":"Certamente que a bondade e a misericórdia me seguirão todos os dias da minha vida; e habitarei na casa do Senhor por longos dias. Salmos 23:6","id_categoria":"80","id":"1781"},
        {"descricao_categoria":"Casa do Senhor","descricao":"Mas, se tardar, para que saibas como convém andar na casa de Deus, que é a igreja do Deus vivo, a coluna e firmeza da verdade. 1 Timóteo 3:15","id_categoria":"81","id":"1782"},
        {"descricao_categoria":"Casa do Senhor","descricao":"Alegrei-me quando me disseram: Vamos à casa do Senhor. Salmos 122:1","id_categoria":"81","id":"1785"},
        {"descricao_categoria":"Casa do Senhor","descricao":"Jesus respondeu, e disse-lhe: Se alguém me ama, guardará a minha palavra, e meu Pai o amará, e viremos para ele, e faremos nele morada. João 14:23","id_categoria":"81","id":"1790"},
        {"descricao_categoria":"Casa do Senhor","descricao":"Certamente que a bondade e a misericórdia me seguirão todos os dias da minha vida; e habitarei na casa do Senhor por longos dias. Salmos 23:6","id_categoria":"81","id":"1791"},
        {"descricao_categoria":"Casa do Senhor","descricao":"Porque, onde estiverem dois ou três reunidos em meu nome, aí estou eu no meio deles. Mateus 18:20","id_categoria":"81","id":"1793"},
        {"descricao_categoria":"Casal","descricao":"Portanto deixará o homem o seu pai e a sua mãe, e apegar-se-á à sua mulher, e serão ambos uma carne. Gênesis 2:24","id_categoria":"82","id":"1796"},
        {"descricao_categoria":"Casal","descricao":"Assim não são mais dois, mas uma só carne. Portanto, o que Deus ajuntou não o separe o homem. Mateus 19:6","id_categoria":"82","id":"1799"},
        {"descricao_categoria":"Casal","descricao":"Portanto, o que Deus ajuntou não o separe o homem. Marcos 10:9","id_categoria":"82","id":"1800"},
        {"descricao_categoria":"Casal","descricao":"Vede que ninguém dê a outrem mal por mal, mas segui sempre o bem, tanto uns para com os outros, como para com todos. 1 Tessalonicenses 5:15","id_categoria":"82","id":"1803"},
        {"descricao_categoria":"Casal","descricao":"Aquele que encontra uma esposa, acha o bem, e alcança a benevolência do Senhor. Provérbios 18:22","id_categoria":"82","id":"1804"},
        {"descricao_categoria":"Casal","descricao":"Melhor é serem dois do que um, porque têm melhor paga do seu trabalho. Eclesiastes 4:9","id_categoria":"82","id":"1807"},
        {"descricao_categoria":"Casal","descricao":"Todavia, nem o homem é sem a mulher, nem a mulher sem o homem, no Senhor. 1 Coríntios 11:11","id_categoria":"82","id":"1810"},
        {"descricao_categoria":"Casal","descricao":"E, sobre tudo isto, revesti-vos de amor, que é o vínculo da perfeição. Colossenses 3:14","id_categoria":"82","id":"1811"},
        {"descricao_categoria":"Casal","descricao":"Por isso deixará o homem seu pai e sua mãe, e se unirá a sua mulher; e serão dois numa carne. Efésios 5:31","id_categoria":"82","id":"1812"},
        {"descricao_categoria":"Casamento","descricao":"Assim não são mais dois, mas uma só carne. Portanto, o que Deus ajuntou não o separe o homem. Mateus 19:6","id_categoria":"83","id":"1850"},
        {"descricao_categoria":"Casamento","descricao":"Mulher virtuosa quem a achará? O seu valor muito excede ao de rubis. Provérbios 31:10","id_categoria":"83","id":"1902"},
        {"descricao_categoria":"Casamento","descricao":"Assim, o que adultera com uma mulher é falto de entendimento; aquele que faz isso destrói a sua alma. Provérbios 6:32","id_categoria":"83","id":"1907"},
        {"descricao_categoria":"Casamento","descricao":"Assim também vós, cada um em particular, ame a sua própria mulher como a si mesmo, e a mulher reverencie o marido. Efésios 5:33","id_categoria":"83","id":"1908"},
        {"descricao_categoria":"Casamento","descricao":"Portanto deixará o homem o seu pai e a sua mãe, e apegar-se-á à sua mulher, e serão ambos uma carne. Gênesis 2:24","id_categoria":"83","id":"1915"},
        {"descricao_categoria":"Casamento","descricao":"E, se a mulher deixar a seu marido, e casar com outro, adultera. Marcos 10:12","id_categoria":"83","id":"1919"},
        {"descricao_categoria":"Casamento","descricao":"Não adulterarás. Êxodo 20:14","id_categoria":"83","id":"1921"},
        {"descricao_categoria":"Céu","descricao":"Mas a nossa cidade está nos céus, de onde também esperamos o Salvador, o Senhor Jesus Cristo, Filipenses 3:20","id_categoria":"84","id":"1923"},
        {"descricao_categoria":"Céu","descricao":"E, quando dizia isto, vendo-o eles, foi elevado às alturas, e uma nuvem o recebeu, ocultando-o a seus olhos. Atos 1:9","id_categoria":"84","id":"1925"},
        {"descricao_categoria":"Céu","descricao":"E o Senhor me livrará de toda a má obra, e guardar-me-á para o seu reino celestial; a quem seja glória para todo o sempre. Amém. 2 Timóteo 4:18","id_categoria":"84","id":"1928"},
        {"descricao_categoria":"Céu","descricao":"Exalta-te sobre os céus, ó Deus, e a tua glória sobre toda a terra. Salmos 108:5","id_categoria":"84","id":"1930"},
        {"descricao_categoria":"Céu","descricao":"Quem tenho eu no céu senão a ti? e na terra não há quem eu deseje além de ti. Salmos 73:25","id_categoria":"84","id":"1931"},
        {"descricao_categoria":"Céu","descricao":"Jesus, porém, disse: Deixai os meninos, e não os estorveis de vir a mim; porque dos tais é o reino dos céus. Mateus 19:14","id_categoria":"84","id":"1932"},
        {"descricao_categoria":"Céu","descricao":"Pensai nas coisas que são de cima, e não nas que são da terra; Colossenses 3:2","id_categoria":"84","id":"1934"},
        {"descricao_categoria":"Chamada","descricao":"Fiel é Deus, pelo qual fostes chamados para a comunhão de seu Filho Jesus Cristo nosso Senhor. 1 Coríntios 1:9","id_categoria":"85","id":"1957"},
        {"descricao_categoria":"Chamada","descricao":"Para o que pelo nosso evangelho vos chamou, para alcançardes a glória de nosso Senhor Jesus Cristo. 2 Tessalonicenses 2:14","id_categoria":"85","id":"1959"},
        {"descricao_categoria":"Colheita","descricao":"Porque o que semeia na sua carne, da carne ceifará a corrupção; mas o que semeia no Espírito, do Espírito ceifará a vida eterna. Gálatas 6:8","id_categoria":"86","id":"1961"},
        {"descricao_categoria":"Colheita","descricao":"Ora, o fruto da justiça semeia-se na paz, para os que exercitam a paz. Tiago 3:18","id_categoria":"86","id":"1962"},
        {"descricao_categoria":"Colheita","descricao":"Não erreis: Deus não se deixa escarnecer; porque tudo o que o homem semear, isso também ceifará. Gálatas 6:7","id_categoria":"86","id":"1965"},
        {"descricao_categoria":"Despedida","descricao":"A graça seja com todos vós. Amém. Hebreus 13:25","id_categoria":"119","id":"2695"},
        {"descricao_categoria":"Despedida","descricao":"Assim Josué os abençoou, e despediu-os; e foram-se às suas tendas. Josué 22:6","id_categoria":"119","id":"2696"},
        {"descricao_categoria":"Despedida","descricao":"E, depois que cessou o alvoroço, Paulo chamou a si os discípulos e, abraçando-os, saiu para a macedônia. Atos 20:1","id_categoria":"119","id":"2697"},
        {"descricao_categoria":"Deus é Amor","descricao":"Porém tu, Senhor, és um Deus cheio de compaixão, e piedoso, sofredor, e grande em benignidade e em verdade. Salmos 86:15","id_categoria":"120","id":"2699"},
        {"descricao_categoria":"Deus é Amor","descricao":"Quão preciosa é, ó Deus, a tua benignidade, pelo que os filhos dos homens se abrigam à sombra das tuas asas. Salmos 36:7","id_categoria":"120","id":"2702"},
        {"descricao_categoria":"Deus é Amor","descricao":"Mas Deus prova o seu amor para conosco, em que Cristo morreu por nós, sendo nós ainda pecadores. Romanos 5:8","id_categoria":"120","id":"2703"},
        {"descricao_categoria":"Deus é Amor","descricao":"Nós o amamos a ele porque ele nos amou primeiro. 1 João 4:19","id_categoria":"120","id":"2705"},
        {"descricao_categoria":"Deus nos chamou","descricao":"Tendo por certo isto mesmo, que aquele que em vós começou a boa obra a aperfeiçoará até ao dia de Jesus Cristo; Filipenses 1:6","id_categoria":"123","id":"2753"},
        {"descricao_categoria":"Deus nunca falha","descricao":"Retenhamos firmes a confissão da nossa esperança; porque fiel é o que prometeu. Hebreus 10:23","id_categoria":"124","id":"2756"},
        {"descricao_categoria":"Deus nunca falha","descricao":"A tua palavra é muito pura; portanto, o teu servo a ama. Salmos 119:140","id_categoria":"124","id":"2757"},
        {"descricao_categoria":"Deus nunca falha","descricao":"Palavra alguma falhou de todas as boas coisas que o Senhor falou à casa de Israel; tudo se cumpriu. Josué 21:45","id_categoria":"124","id":"2760"},
        {"descricao_categoria":"Deus nunca falha","descricao":"O conselho do Senhor permanece para sempre; os intentos do seu coração de geração em geração. Salmos 33:11","id_categoria":"124","id":"2766"},
        {"descricao_categoria":"Deus","descricao":"Porque toda a casa é edificada por alguém, mas o que edificou todas as coisas é Deus. Hebreus 3:4","id_categoria":"125","id":"2791"},
        {"descricao_categoria":"Deus","descricao":"Posso todas as coisas em Cristo que me fortalece. Filipenses 4:13","id_categoria":"125","id":"2792"},
        {"descricao_categoria":"Dia das Crianças","descricao":"Jesus, porém, disse: Deixai os meninos, e não os estorveis de vir a mim; porque dos tais é o reino dos céus. Mateus 19:14","id_categoria":"126","id":"2793"},
        {"descricao_categoria":"Dia das Crianças","descricao":"Educa a criança no caminho em que deve andar; e até quando envelhecer não se desviará dele. Provérbios 22:6","id_categoria":"126","id":"2794"},
        {"descricao_categoria":"Dia das Crianças","descricao":"E todos os teus filhos serão ensinados do Senhor; e a paz de teus filhos será abundante. Isaías 54:13","id_categoria":"126","id":"2795"},
        {"descricao_categoria":"Dia das Crianças","descricao":"Eis que os filhos são herança do Senhor, e o fruto do ventre o seu galardão. Salmos 127:3","id_categoria":"126","id":"2802"},
        {"descricao_categoria":"Dia dos Avós","descricao":"Fui moço, e agora sou velho; mas nunca vi desamparado o justo, nem a sua semente a mendigar o pão. Salmos 37:25","id_categoria":"127","id":"2806"},
        {"descricao_categoria":"Dia dos Avós","descricao":"Coroa de honra são as cãs, quando elas estão no caminho da justiça. Provérbios 16:31","id_categoria":"127","id":"2808"},
        {"descricao_categoria":"Dificuldades","descricao":"Do açoite da língua estarás encoberto; e não temerás a assolação, quando vier. Jó 5:21","id_categoria":"129","id":"2829"},
        {"descricao_categoria":"Dificuldades","descricao":"Tu dominas o ímpeto do mar; quando as suas ondas se levantam, tu as fazes aquietar. Salmos 89:9","id_categoria":"129","id":"2830"},
        {"descricao_categoria":"Dificuldades","descricao":"Direi do Senhor: Ele é o meu Deus, o meu refúgio, a minha fortaleza, e nele confiarei. Salmos 91:2","id_categoria":"129","id":"2831"},
        {"descricao_categoria":"Dificuldades","descricao":"Fui moço, e agora sou velho; mas nunca vi desamparado o justo, nem a sua semente a mendigar o pão. Salmos 37:25","id_categoria":"129","id":"2832"},
        {"descricao_categoria":"Dificuldades","descricao":"E haverá um tabernáculo para sombra contra o calor do dia; e para refúgio e esconderijo contra a tempestade e a chuva. Isaías 4:6","id_categoria":"129","id":"2834"},
        {"descricao_categoria":"Dificuldades","descricao":"Mas o Senhor nas alturas é mais poderoso do que o ruído das grandes águas e do que as grandes ondas do mar. Salmos 93:4","id_categoria":"129","id":"2839"},
        {"descricao_categoria":"Dificuldades","descricao":"Confiai nele, ó povo, em todos os tempos; derramai perante ele o vosso coração. Deus é o nosso refúgio. (Selá.) Salmos 62:8","id_categoria":"129","id":"2841"},
        {"descricao_categoria":"Dificuldades","descricao":"O Senhor é bom, ele serve de fortaleza no dia da angústia, e conhece os que confiam nele. Naum 1:7","id_categoria":"129","id":"2842"},
        {"descricao_categoria":"Dificuldades","descricao":"Faz cessar a tormenta, e acalmam-se as suas ondas. Salmos 107:29","id_categoria":"129","id":"2846"},
        {"descricao_categoria":"Dificuldades","descricao":"Eis que vos dou poder para pisar serpentes e escorpiões, e toda a força do inimigo, e nada vos fará dano algum. Lucas 10:19","id_categoria":"129","id":"2848"},
        {"descricao_categoria":"Dinheiro","descricao":"E disse-lhes: Acautelai-vos e guardai-vos da avareza; porque a vida de qualquer não consiste na abundância do que possui. Lucas 12:15","id_categoria":"130","id":"2849"},
        {"descricao_categoria":"Dinheiro","descricao":"Porventura fixarás os teus olhos naquilo que não é nada? porque certamente criará asas e voará ao céu como a águia. Provérbios 23:5","id_categoria":"130","id":"2850"},
        {"descricao_categoria":"Dinheiro","descricao":"Não te fatigues para enriqueceres; e não apliques nisso a tua sabedoria. Provérbios 23:4","id_categoria":"130","id":"2853"},
        {"descricao_categoria":"Dinheiro","descricao":"Quem amar o dinheiro jamais dele se fartará; e quem amar a abundância nunca se fartará da renda; também isto é vaidade. Eclesiastes 5:10","id_categoria":"130","id":"2854"},
        {"descricao_categoria":"Dinheiro","descricao":"Porque onde estiver o vosso tesouro, aí estará também o vosso coração. Mateus 6:21","id_categoria":"130","id":"2855"},
        {"descricao_categoria":"Dinheiro","descricao":"E o seu senhor lhe disse: Bem está, servo bom e fiel. Sobre o pouco foste fiel, sobre muito te colocarei; entra no gozo do teu senhor. Mateus 25:21","id_categoria":"130","id":"2856"},
        {"descricao_categoria":"Dinheiro","descricao":"Quão melhor é adquirir a sabedoria do que o ouro! e quão mais excelente é adquirir a prudência do que a prata! Provérbios 16:16","id_categoria":"130","id":"2857"},
        {"descricao_categoria":"Dinheiro","descricao":"De nada aproveitam as riquezas no dia da ira, mas a justiça livra da morte. Provérbios 11:4","id_categoria":"130","id":"2858"},
        {"descricao_categoria":"Dinheiro","descricao":"Melhor é o pouco com o temor do Senhor, do que um grande tesouro onde há inquietação. Provérbios 15:16","id_categoria":"130","id":"2859"},
        {"descricao_categoria":"Dinheiro","descricao":"Portanto, dai a cada um o que deveis: a quem tributo, tributo; a quem imposto, imposto; a quem temor, temor; a quem honra, honra. Romanos 13:7","id_categoria":"130","id":"2860"},
        {"descricao_categoria":"Dinheiro","descricao":"Por esta razão também pagais tributos, porque são ministros de Deus, atendendo sempre a isto mesmo. Romanos 13:6","id_categoria":"130","id":"2862"},
        {"descricao_categoria":"Dinheiro","descricao":"O que o homem mais deseja é o que lhe faz bem; porém é melhor ser pobre do que mentiroso. Provérbios 19:22","id_categoria":"130","id":"2863"},
        {"descricao_categoria":"Dinheiro","descricao":"Honra ao Senhor com os teus bens, e com a primeira parte de todos os teus ganhos; Provérbios 3:9","id_categoria":"130","id":"2864"},
        {"descricao_categoria":"Dinheiro","descricao":"Folguei tanto no caminho dos teus testemunhos, como em todas as riquezas. Salmos 119:14","id_categoria":"130","id":"2865"},
        {"descricao_categoria":"Dinheiro","descricao":"A riqueza de procedência vã diminuirá, mas quem a ajunta com o próprio trabalho a aumentará. Provérbios 13:11","id_categoria":"130","id":"2866"},
        {"descricao_categoria":"Tomar uma Decisão","descricao":"E, quando chegaram a Mísia, intentavam ir para Bitínia, mas o Espírito não lho permitiu. Atos 16:7","id_categoria":"135","id":"2961"},
        {"descricao_categoria":"Tomar uma Decisão","descricao":"Portanto, quer comais quer bebais, ou façais outra qualquer coisa, fazei tudo para glória de Deus. 1 Coríntios 10:31","id_categoria":"135","id":"2964"},
        {"descricao_categoria":"Tomar uma Decisão","descricao":"E, se algum de vós tem falta de sabedoria, peça-a a Deus, que a todos dá liberalmente, e o não lança em rosto, e ser-lhe-á dada. Tiago 1:5","id_categoria":"135","id":"2965"},
        {"descricao_categoria":"Tomar uma Decisão","descricao":"Toda a Escritura é divinamente inspirada, e proveitosa para ensinar, para redargüir, para corrigir, para instruir em justiça; 2 Timóteo 3:16","id_categoria":"135","id":"2968"},
        {"descricao_categoria":"Tomar uma Decisão","descricao":"Não havendo sábios conselhos, o povo cai, mas na multidão de conselhos há segurança. Provérbios 11:14","id_categoria":"135","id":"2969"},
        {"descricao_categoria":"Tomar uma Decisão","descricao":"A sorte se lança no regaço, mas do Senhor procede toda a determinação. Provérbios 16:33","id_categoria":"135","id":"2971"},
        {"descricao_categoria":"Tomar uma Decisão","descricao":"Reconhece-o em todos os teus caminhos, e ele endireitará as tuas veredas. Provérbios 3:6","id_categoria":"135","id":"2972"},
        {"descricao_categoria":"Tomar uma Decisão","descricao":"E esta é a confiança que temos nele, que, se pedirmos alguma coisa, segundo a sua vontade, ele nos ouve. 1 João 5:14","id_categoria":"135","id":"2973"},
        {"descricao_categoria":"Tomar uma Decisão","descricao":"Para que o homem de Deus seja perfeito, e perfeitamente instruído para toda a boa obra. 2 Timóteo 3:17","id_categoria":"135","id":"2975"},
        {"descricao_categoria":"Tomar uma Decisão","descricao":"Se confessarmos os nossos pecados, ele é fiel e justo para nos perdoar os pecados, e nos purificar de toda a injustiça. 1 João 1:9","id_categoria":"135","id":"2977"},
        {"descricao_categoria":"Temor de Deus","descricao":"E não temais os que matam o corpo e não podem matar a alma; temei antes aquele que pode fazer perecer no inferno a alma e o corpo. Mateus 10:28","id_categoria":"136","id":"2980"},
        {"descricao_categoria":"Temor de Deus","descricao":"E guarda os mandamentos do Senhor teu Deus, para andares nos seus caminhos e para o temeres. Deuteronômio 8:6","id_categoria":"136","id":"2981"},
        {"descricao_categoria":"Temor de Deus","descricao":"Melhor é o pouco com o temor do Senhor, do que um grande tesouro onde há inquietação. Provérbios 15:16","id_categoria":"136","id":"2984"},
        {"descricao_categoria":"Temor de Deus","descricao":"De tudo o que se tem ouvido, o fim é: Teme a Deus, e guarda os seus mandamentos; porque isto é o dever de todo o homem. Eclesiastes 12:13","id_categoria":"136","id":"2985"},
        {"descricao_categoria":"Temor de Deus","descricao":"Porque, como na multidão dos sonhos há vaidades, assim também nas muitas palavras; mas tu teme a Deus. Eclesiastes 5:7","id_categoria":"136","id":"2989"},
        {"descricao_categoria":"Temor do Senhor","descricao":"O galardão da humildade e o temor do Senhor são riquezas, honra e vida. Provérbios 22:4","id_categoria":"137","id":"2992"},
        {"descricao_categoria":"Temor do Senhor","descricao":"Não amaldiçoarás ao surdo, nem porás tropeço diante do cego; mas temerás o teu Deus. Eu sou o Senhor. Levítico 19:14","id_categoria":"137","id":"2993"},
        {"descricao_categoria":"Temor do Senhor","descricao":"O temor do Senhor aumenta os dias, mas os perversos terão os anos da vida abreviados. Provérbios 10:27","id_categoria":"137","id":"2996"},
        {"descricao_categoria":"Temor dos Homens","descricao":"O Senhor está comigo; não temerei o que me pode fazer o homem. Salmos 118:6","id_categoria":"138","id":"3016"},
        {"descricao_categoria":"Temor dos Homens","descricao":"Não os temais, porque o Senhor vosso Deus é o que peleja por vós. Deuteronômio 3:22","id_categoria":"138","id":"3018"},
        {"descricao_categoria":"Temor","descricao":"O temor do Senhor é a instrução da sabedoria, e precedendo a honra vai a humildade. Provérbios 15:33","id_categoria":"139","id":"3020"},
        {"descricao_categoria":"Temor","descricao":"Servi ao Senhor com temor, e alegrai-vos com tremor. Salmos 2:11","id_categoria":"139","id":"3021"},
        {"descricao_categoria":"Temor","descricao":"O SENHOR reina; tremam os povos. Ele está assentado entre os querubins; comova-se a terra. Salmos 99:1","id_categoria":"139","id":"3022"},
        {"descricao_categoria":"Temor","descricao":"Vós, os que temeis ao Senhor, confiai no Senhor; ele é o seu auxílio e o seu escudo. Salmos 115:11","id_categoria":"139","id":"3024"},
        {"descricao_categoria":"Tentação","descricao":"Sabendo que a prova da vossa fé opera a paciência. Tiago 1:3","id_categoria":"140","id":"3042"},
        {"descricao_categoria":"Tentação","descricao":"E não nos conduzas à tentação; mas livra-nos do mal; porque teu é o reino, e o poder, e a glória, para sempre. Amém. Mateus 6:13","id_categoria":"140","id":"3043"},
        {"descricao_categoria":"Todo Poderoso","descricao":"Do SENHOR é a terra e a sua plenitude, o mundo e aqueles que nele habitam. Salmos 24:1","id_categoria":"141","id":"3046"},
        {"descricao_categoria":"Todo Poderoso","descricao":"O Senhor empobrece e enriquece; abaixa e também exalta. 1 Samuel 2:7","id_categoria":"141","id":"3050"},
        {"descricao_categoria":"Todo Poderoso","descricao":"Eu sou o Alfa e o Ômega, o princípio e o fim, diz o Senhor, que é, e que era, e que há de vir, o Todo-Poderoso. Apocalipse 1:8","id_categoria":"141","id":"3051"},
        {"descricao_categoria":"Trabalho","descricao":"E, quanto fizerdes por palavras ou por obras, fazei tudo em nome do Senhor Jesus, dando por ele graças a Deus Pai. Colossenses 3:17","id_categoria":"143","id":"3084"},
        {"descricao_categoria":"Trabalho","descricao":"A mão dos diligentes dominará, mas os negligentes serão tributários. Provérbios 12:24","id_categoria":"143","id":"3086"},
        {"descricao_categoria":"Trabalho","descricao":"Todas as vossas coisas sejam feitas com amor. 1 Coríntios 16:14","id_categoria":"143","id":"3087"},
        {"descricao_categoria":"Trabalho","descricao":"O que trabalha com mão displicente empobrece, mas a mão dos diligentes enriquece. Provérbios 10:4","id_categoria":"143","id":"3090"},
        {"descricao_categoria":"Trabalho","descricao":"A bênção do Senhor é que enriquece; e não traz consigo dores. Provérbios 10:22","id_categoria":"143","id":"3091"},
        {"descricao_categoria":"Trabalho","descricao":"Procura apresentar-te a Deus aprovado, como obreiro que não tem de que se envergonhar, que maneja bem a palavra da verdade. 2 Timóteo 2:15","id_categoria":"143","id":"3092"},
        {"descricao_categoria":"Trabalho","descricao":"Confia ao Senhor as tuas obras, e teus pensamentos serão estabelecidos. Provérbios 16:3","id_categoria":"143","id":"3093"},
        {"descricao_categoria":"Trabalho","descricao":"Melhor é serem dois do que um, porque têm melhor paga do seu trabalho. Eclesiastes 4:9","id_categoria":"143","id":"3095"},
        {"descricao_categoria":"Trabalho","descricao":"Não ames o sono, para que não empobreças; abre os teus olhos, e te fartarás de pão. Provérbios 20:13","id_categoria":"143","id":"3096"},
        {"descricao_categoria":"Trabalho","descricao":"Olhai por vós mesmos, para que não percamos o que temos ganho, antes recebamos o inteiro galardão. 2 João 1:8","id_categoria":"143","id":"3102"},
        {"descricao_categoria":"Trabalho","descricao":"A riqueza de procedência vã diminuirá, mas quem a ajunta com o próprio trabalho a aumentará. Provérbios 13:11","id_categoria":"143","id":"3104"},
        {"descricao_categoria":"Trabalho","descricao":"E seja sobre nós a formosura do Senhor nosso Deus, e confirma sobre nós a obra das nossas mãos; sim, confirma a obra das nossas mãos. Salmos 90:17","id_categoria":"143","id":"3110"},
        {"descricao_categoria":"Trabalho","descricao":"E qual é aquele que vos fará mal, se fordes seguidores do bem? 1 Pedro 3:13","id_categoria":"143","id":"3111"},
        {"descricao_categoria":"Transformação","descricao":"Desde então começou Jesus a pregar, e a dizer: Arrependei-vos, porque é chegado o reino dos céus. Mateus 4:17","id_categoria":"144","id":"3122"},
        {"descricao_categoria":"Transformação","descricao":"Ora o Senhor encaminhe os vossos corações no amor de Deus, e na paciência de Cristo. 2 Tessalonicenses 3:5","id_categoria":"144","id":"3123"},
        {"descricao_categoria":"Transformação","descricao":"Porque Cristo, estando nós ainda fracos, morreu a seu tempo pelos ímpios. Romanos 5:6","id_categoria":"144","id":"3125"},
        {"descricao_categoria":"Tristeza","descricao":"Como contristados, mas sempre alegres; como pobres, mas enriquecendo a muitos; como nada tendo, e possuindo tudo. 2 Coríntios 6:10","id_categoria":"145","id":"3127"},
        {"descricao_categoria":"Tristeza","descricao":"Provérbios de Salomão: O filho sábio alegra a seu pai, mas o filho insensato é a tristeza de sua mãe. Provérbios 10:1","id_categoria":"145","id":"3128"},
        {"descricao_categoria":"Tristeza","descricao":"Alegrai-vos com os que se alegram; e chorai com os que choram; Romanos 12:15","id_categoria":"145","id":"3129"},
        {"descricao_categoria":"Tudo posso nAquele que me fortalece","descricao":"Sujeitai-vos, pois, a Deus, resisti ao diabo, e ele fugirá de vós. Tiago 4:7","id_categoria":"146","id":"3150"},
        {"descricao_categoria":"Tudo posso nAquele que me fortalece","descricao":"Porque Deus não nos deu o espírito de temor, mas de fortaleza, e de amor, e de moderação. 2 Timóteo 1:7","id_categoria":"146","id":"3151"},
        {"descricao_categoria":"Tudo posso nAquele que me fortalece","descricao":"O SENHOR é a minha luz e a minha salvação; a quem temerei? O SENHOR é a força da minha vida; de quem me recearei? Salmos 27:1","id_categoria":"146","id":"3152"},
        {"descricao_categoria":"Tudo posso nAquele que me fortalece","descricao":"Lançando sobre ele toda a vossa ansiedade, porque ele tem cuidado de vós. 1 Pedro 5:7","id_categoria":"146","id":"3153"},
        {"descricao_categoria":"Educação das Crianças","descricao":"Castiga o teu filho enquanto há esperança, mas não deixes que o teu ânimo se exalte até o matar. Provérbios 19:18","id_categoria":"147","id":"3155"},
        {"descricao_categoria":"Educação das Crianças","descricao":"Abre a sua boca com sabedoria, e a lei da beneficência está na sua língua. Provérbios 31:26","id_categoria":"147","id":"3157"},
        {"descricao_categoria":"Educação das Crianças","descricao":"O que não faz uso da vara odeia seu filho, mas o que o ama, desde cedo o castiga. Provérbios 13:24","id_categoria":"147","id":"3160"},
        {"descricao_categoria":"Educação das Crianças","descricao":"A estultícia está ligada ao coração da criança, mas a vara da correção a afugentará dela. Provérbios 22:15","id_categoria":"147","id":"3161"},
        {"descricao_categoria":"Educação das Crianças","descricao":"A vara e a repreensão dão sabedoria, mas a criança entregue a si mesma, envergonha a sua mãe. Provérbios 29:15","id_categoria":"147","id":"3162"},
        {"descricao_categoria":"Educação das Crianças","descricao":"O vivente, o vivente, esse te louvará, como eu hoje o faço; o pai aos filhos fará notória a tua verdade. Isaías 38:19","id_categoria":"147","id":"3184"},
        {"descricao_categoria":"Educação das Crianças","descricao":"E todos os teus filhos serão ensinados do Senhor; e a paz de teus filhos será abundante. Isaías 54:13","id_categoria":"147","id":"3185"},
        {"descricao_categoria":"Egoísmo","descricao":"E o segundo, semelhante a este, é: Amarás o teu próximo como a ti mesmo. Não há outro mandamento maior do que estes. Marcos 12:31","id_categoria":"148","id":"3186"},
        {"descricao_categoria":"Egoísmo","descricao":"Portanto cada um de nós agrade ao seu próximo no que é bom para edificação. Romanos 15:2","id_categoria":"148","id":"3187"},
        {"descricao_categoria":"Egoísmo","descricao":"Nada façais por contenda ou por vanglória, mas por humildade; cada um considere os outros superiores a si mesmo. Filipenses 2:3","id_categoria":"148","id":"3190"},
        {"descricao_categoria":"Egoísmo","descricao":"Ninguém se engane a si mesmo. Se alguém dentre vós se tem por sábio neste mundo, faça-se louco para ser sábio. 1 Coríntios 3:18","id_categoria":"148","id":"3191"},
        {"descricao_categoria":"Egoísmo","descricao":"Inclina o meu coração aos teus testemunhos, e não à cobiça. Salmos 119:36","id_categoria":"148","id":"3192"},
        {"descricao_categoria":"Encorajamento","descricao":"Porque, se há prontidão de vontade, será aceita segundo o que qualquer tem, e não segundo o que não tem. 2 Coríntios 8:12","id_categoria":"149","id":"3206"},
        {"descricao_categoria":"Encorajamento","descricao":"Lançando sobre ele toda a vossa ansiedade, porque ele tem cuidado de vós. 1 Pedro 5:7","id_categoria":"149","id":"3207"},
        {"descricao_categoria":"Encorajamento","descricao":"Por isso não desfalecemos; mas, ainda que o nosso homem exterior se corrompa, o interior, contudo, se renova de dia em dia. 2 Coríntios 4:16","id_categoria":"149","id":"3208"},
        {"descricao_categoria":"Encorajamento","descricao":"Porque Deus não nos deu o espírito de temor, mas de fortaleza, e de amor, e de moderação. 2 Timóteo 1:7","id_categoria":"149","id":"3209"},
        {"descricao_categoria":"Encorajamento","descricao":"Tenho-vos dito isto, para que em mim tenhais paz; no mundo tereis aflições, mas tende bom ânimo, eu venci o mundo. João 16:33","id_categoria":"149","id":"3210"},
        {"descricao_categoria":"Encorajamento","descricao":"Depois disto ouvi a voz do Senhor, que dizia: A quem enviarei, e quem há de ir por nós? Então disse eu: Eis-me aqui, envia-me a mim. Isaías 6:8","id_categoria":"149","id":"3212"},
        {"descricao_categoria":"Encorajamento","descricao":"Instruir-te-ei, e ensinar-te-ei o caminho que deves seguir; guiar-te-ei com os meus olhos. Salmos 32:8","id_categoria":"149","id":"3217"},
        {"descricao_categoria":"Encorajamento","descricao":"Vigiai, estai firmes na fé; portai-vos varonilmente, e fortalecei-vos. 1 Coríntios 16:13","id_categoria":"149","id":"3219"},
        {"descricao_categoria":"Encorajamento","descricao":"Porque a nossa leve e momentânea tribulação produz para nós um peso eterno de glória mui excelente; 2 Coríntios 4:17","id_categoria":"149","id":"3220"},
        {"descricao_categoria":"Encorajamento","descricao":"Vinde a mim, todos os que estais cansados e oprimidos, e eu vos aliviarei. Mateus 11:28","id_categoria":"149","id":"3221"},
        {"descricao_categoria":"Encorajamento","descricao":"E seja sobre nós a formosura do Senhor nosso Deus, e confirma sobre nós a obra das nossas mãos; sim, confirma a obra das nossas mãos. Salmos 90:17","id_categoria":"149","id":"3222"},
        {"descricao_categoria":"Encorajamento","descricao":"Deixo-vos a paz, a minha paz vos dou; não vo-la dou como o mundo a dá. Não se turbe o vosso coração, nem se atemorize. João 14:27","id_categoria":"149","id":"3223"},
        {"descricao_categoria":"Encorajamento","descricao":"E Jesus lhes disse: Vinde após mim, e eu farei que sejais pescadores de homens. E, deixando logo as suas redes, o seguiram. Marcos 1:17,18","id_categoria":"149","id":"3224"},
        {"descricao_categoria":"Encorajamento","descricao":"Por isso exortai-vos uns aos outros, e edificai-vos uns aos outros, como também o fazeis. 1 Tessalonicenses 5:11","id_categoria":"149","id":"3225"},
        {"descricao_categoria":"Encorajamento","descricao":"Em todo trabalho há proveito, mas ficar só em palavras leva à pobreza. Provérbios 14:23","id_categoria":"149","id":"3226"},
        {"descricao_categoria":"Encorajamento","descricao":"O SENHOR é a minha luz e a minha salvação; a quem temerei? O SENHOR é a força da minha vida; de quem me recearei? Salmos 27:1","id_categoria":"149","id":"3231"},
        {"descricao_categoria":"Encorajamento","descricao":"Disse então Maria: Eis aqui a serva do Senhor; cumpra-se em mim segundo a tua palavra. E o anjo ausentou-se dela. Lucas 1:38","id_categoria":"149","id":"3234"},
        {"descricao_categoria":"Encorajamento","descricao":"Tendo por certo isto mesmo, que aquele que em vós começou a boa obra a aperfeiçoará até ao dia de Jesus Cristo; Filipenses 1:6","id_categoria":"149","id":"3235"},
        {"descricao_categoria":"Encorajamento","descricao":"Visto que foste precioso aos meus olhos, também foste honrado, e eu te amei, assim dei os homens por ti, e os povos pela tua vida. Isaías 43:4","id_categoria":"149","id":"3237"},
        {"descricao_categoria":"Encorajamento","descricao":"Ainda que eu andasse pelo vale da sombra da morte, não temeria mal algum, porque tu estás comigo; a tua vara e o teu cajado me consolam. Salmos 23:4","id_categoria":"149","id":"3238"},
        {"descricao_categoria":"Encorajamento","descricao":"Esforçai-vos, e ele fortalecerá o vosso coração, vós todos que esperais no Senhor. Salmos 31:24","id_categoria":"149","id":"3239"},
        {"descricao_categoria":"Encorajamento","descricao":"E disse-me o anjo de Deus em sonhos: Jacó! E eu disse: Eis-me aqui. Gênesis 31:11","id_categoria":"149","id":"3240"},
        {"descricao_categoria":"Encorajamento","descricao":"Ora, o Deus de paciência e consolação vos conceda o mesmo sentimento uns para com os outros, segundo Cristo Jesus, Romanos 15:5","id_categoria":"149","id":"3249"},
        {"descricao_categoria":"Encorajamento","descricao":"Não temas, pois, porque estou contigo; trarei a tua descendência desde o oriente, e te ajuntarei desde o ocidente. Isaías 43:5","id_categoria":"149","id":"3252"},
        {"descricao_categoria":"Encorajamento","descricao":"Portanto cada um de nós agrade ao seu próximo no que é bom para edificação. Romanos 15:2","id_categoria":"149","id":"3253"},
        {"descricao_categoria":"Encorajamento","descricao":"Espera no Senhor, anima-te, e ele fortalecerá o teu coração; espera, pois, no Senhor. Salmos 27:14","id_categoria":"149","id":"3255"},
        {"descricao_categoria":"Encorajamento","descricao":"Tenho posto o Senhor continuamente diante de mim; por isso que ele está à minha mão direita, nunca vacilarei. Salmos 16:8","id_categoria":"149","id":"3257"},
        {"descricao_categoria":"Encorajamento","descricao":"Mas o anjo do Senhor lhe bradou desde os céus, e disse: Abraão, Abraão! E ele disse: Eis-me aqui. Gênesis 22:11","id_categoria":"149","id":"3258"},
        {"descricao_categoria":"Encorajamento","descricao":"Levantarei os meus olhos para os montes, de onde vem o meu socorro. O meu socorro vem do Senhor que fez o céu e a terra. Salmos 121:1,2","id_categoria":"149","id":"3259"},
        {"descricao_categoria":"Encorajamento","descricao":"E havia em Damasco um certo discípulo chamado Ananias; e disse-lhe o Senhor em visão: Ananias! E ele respondeu: Eis-me aqui, Senhor. Atos 9:10","id_categoria":"149","id":"3260"},
        {"descricao_categoria":"Encorajamento","descricao":"Então chamou Eli a Samuel, e disse: Samuel, meu filho. E disse ele: Eis-me aqui. 1 Samuel 3:16","id_categoria":"149","id":"3261"},
        {"descricao_categoria":"Encorajamento","descricao":"Que diremos, pois, a estas coisas? Se Deus é por nós, quem será contra nós? Romanos 8:31","id_categoria":"149","id":"3262"},
        {"descricao_categoria":"Encorajamento","descricao":"E vendo o Senhor que se virava para ver, bradou Deus a ele do meio da sarça, e disse: Moisés, Moisés. Respondeu ele: Eis-me aqui. Êxodo 3:4","id_categoria":"149","id":"3263"},
        {"descricao_categoria":"Ensinar","descricao":"O temor do Senhor é o princípio da sabedoria, e o conhecimento do Santo a prudência. Provérbios 9:10","id_categoria":"150","id":"3265"},
        {"descricao_categoria":"Ensinar","descricao":"A doutrina do sábio é uma fonte de vida para se desviar dos laços da morte. Provérbios 13:14","id_categoria":"150","id":"3276"},
        {"descricao_categoria":"Esperança","descricao":"Retenhamos firmes a confissão da nossa esperança; porque fiel é o que prometeu. Hebreus 10:23","id_categoria":"151","id":"3283"},
        {"descricao_categoria":"Esperança","descricao":"Ora o Deus de esperança vos encha de todo o gozo e paz em crença, para que abundeis em esperança pela virtude do Espírito Santo. Romanos 15:13","id_categoria":"151","id":"3284"},
        {"descricao_categoria":"Esperança","descricao":"Ora, a fé é o firme fundamento das coisas que se esperam, e a prova das coisas que se não vêem. Hebreus 11:1","id_categoria":"151","id":"3285"},
        {"descricao_categoria":"Esperança","descricao":"Mas, se esperamos o que não vemos, com paciência o esperamos. Romanos 8:25","id_categoria":"151","id":"3286"},
        {"descricao_categoria":"Esperança","descricao":"Esforçai-vos, e ele fortalecerá o vosso coração, vós todos que esperais no Senhor. Salmos 31:24","id_categoria":"151","id":"3290"},
        {"descricao_categoria":"Esperança","descricao":"Bem-aventurado aquele que tem o Deus de Jacó por seu auxílio, e cuja esperança está posta no Senhor seu Deus. Salmos 146:5","id_categoria":"151","id":"3291"},
        {"descricao_categoria":"Esperança","descricao":"E qualquer que nele tem esta esperança purifica-se a si mesmo, como também ele é puro. 1 João 3:3","id_categoria":"151","id":"3295"},
        {"descricao_categoria":"Esperança","descricao":"Tu és o meu refúgio e o meu escudo; espero na tua palavra. Salmos 119:114","id_categoria":"151","id":"3298"},
        {"descricao_categoria":"Esperança","descricao":"Há um só corpo e um só Espírito, como também fostes chamados em uma só esperança da vossa vocação; Efésios 4:4","id_categoria":"151","id":"3299"},
        {"descricao_categoria":"Esperança","descricao":"E a esperança não traz confusão, porquanto o amor de Deus está derramado em nossos corações pelo Espírito Santo que nos foi dado. Romanos 5:5","id_categoria":"151","id":"3300"},
        {"descricao_categoria":"Esperança","descricao":"A minha porção é o Senhor, diz a minha alma; portanto esperarei nele. Lamentações 3:24","id_categoria":"151","id":"3302"},
        {"descricao_categoria":"Esperança","descricao":"Desfalece a minha alma pela tua salvação, mas espero na tua palavra. Salmos 119:81","id_categoria":"151","id":"3303"},
        {"descricao_categoria":"Esperança","descricao":"Agora, pois, Senhor, que espero eu? A minha esperança está em ti. Salmos 39:7","id_categoria":"151","id":"3304"},
        {"descricao_categoria":"Esperança","descricao":"Pela manhã ouvirás a minha voz, ó Senhor; pela manhã apresentarei a ti a minha oração, e vigiarei. Salmos 5:3","id_categoria":"151","id":"3305"},
        {"descricao_categoria":"Esperança","descricao":"Seja a tua misericórdia, Senhor, sobre nós, como em ti esperamos. Salmos 33:22","id_categoria":"151","id":"3306"},
        {"descricao_categoria":"Esperança","descricao":"O Senhor te guardará de todo o mal; guardará a tua alma. O Senhor guardará a tua entrada e a tua saída, desde agora e para sempre. Salmos 121:7,8","id_categoria":"151","id":"3307"},
        {"descricao_categoria":"Esperança","descricao":"Ó minha alma, espera somente em Deus, porque dele vem a minha esperança. Salmos 62:5","id_categoria":"151","id":"3311"},
        {"descricao_categoria":"Esperança","descricao":"Guia-me na tua verdade, e ensina-me, pois tu és o Deus da minha salvação; por ti estou esperando todo o dia. Salmos 25:5","id_categoria":"151","id":"3314"},
        {"descricao_categoria":"Esperança","descricao":"Vinde a mim, todos os que estais cansados e oprimidos, e eu vos aliviarei. Mateus 11:28","id_categoria":"151","id":"3315"},
        {"descricao_categoria":"Esperança","descricao":"A esperança adiada desfalece o coração, mas o desejo atendido é árvore de vida. Provérbios 13:12","id_categoria":"151","id":"3316"},
        {"descricao_categoria":"Esperança","descricao":"Tendo, pois, tal esperança, usamos de muita ousadia no falar. 2 Coríntios 3:12","id_categoria":"151","id":"3319"},
        {"descricao_categoria":"Esperança","descricao":"Aguardo ao Senhor; a minha alma o aguarda, e espero na sua palavra. Salmos 130:5","id_categoria":"151","id":"3321"},
        {"descricao_categoria":"Esperança","descricao":"Eu, porém, olharei para o Senhor; esperarei no Deus da minha salvação; o meu Deus me ouvirá. Miquéias 7:7","id_categoria":"151","id":"3322"},
        {"descricao_categoria":"Esperança","descricao":"Alegrai-vos na esperança, sede pacientes na tribulação, perseverai na oração; Romanos 12:12","id_categoria":"151","id":"3323"},
        {"descricao_categoria":"Esperança","descricao":"A nossa alma espera no Senhor; ele é o nosso auxílio e o nosso escudo. Salmos 33:20","id_categoria":"151","id":"3324"},
        {"descricao_categoria":"Esperança","descricao":"Agora, pois, permanecem a fé, a esperança e o amor, estes três, mas o maior destes é o amor. 1 Coríntios 13:13","id_categoria":"151","id":"3328"},
        {"descricao_categoria":"Esperança","descricao":"Porque o necessitado não será esquecido para sempre, nem a expectação dos pobres perecerá perpetuamente. Salmos 9:18","id_categoria":"151","id":"3329"},
        {"descricao_categoria":"Esperança","descricao":"Para que, sendo justificados pela sua graça, sejamos feitos herdeiros segundo a esperança da vida eterna. Tito 3:7","id_categoria":"151","id":"3330"},
        {"descricao_categoria":"Esperar em Deus","descricao":"Não digas: Vingar-me-ei do mal; espera pelo Senhor, e ele te livrará. Provérbios 20:22","id_categoria":"152","id":"3333"},
        {"descricao_categoria":"Esperar em Deus","descricao":"Porque os malfeitores serão desarraigados; mas aqueles que esperam no Senhor herdarão a terra. Salmos 37:9","id_categoria":"152","id":"3334"},
        {"descricao_categoria":"Esperar em Deus","descricao":"Conservai-vos a vós mesmos no amor de Deus, esperando a misericórdia de nosso Senhor Jesus Cristo para a vida eterna. Judas 1:21","id_categoria":"152","id":"3336"},
        {"descricao_categoria":"Esperar em Deus","descricao":"Espera no Senhor, e guarda o seu caminho, e te exaltará para herdares a terra; tu o verás quando os ímpios forem desarraigados. Salmos 37:34","id_categoria":"152","id":"3338"},
        {"descricao_categoria":"Esperar em Deus","descricao":"Na verdade, não serão confundidos os que esperam em ti; confundidos serão os que transgridem sem causa. Salmos 25:3","id_categoria":"152","id":"3340"},
        {"descricao_categoria":"Esperar em Deus","descricao":"Porque em ti, Senhor, espero; tu, Senhor meu Deus, me ouvirás. Salmos 38:15","id_categoria":"152","id":"3341"},
        {"descricao_categoria":"Esperar em Deus","descricao":"Espera no Senhor, anima-te, e ele fortalecerá o teu coração; espera, pois, no Senhor. Salmos 27:14","id_categoria":"152","id":"3342"},
        {"descricao_categoria":"Esperar em Deus","descricao":"Esperei com paciência no SENHOR, e ele se inclinou para mim, e ouviu o meu clamor. Salmos 40:1","id_categoria":"152","id":"3343"},
        {"descricao_categoria":"Esperar em Deus","descricao":"Ó minha alma, espera somente em Deus, porque dele vem a minha esperança. Salmos 62:5","id_categoria":"152","id":"3345"},
        {"descricao_categoria":"Esperar em Deus","descricao":"Mas a nossa cidade está nos céus, de onde também esperamos o Salvador, o Senhor Jesus Cristo, Filipenses 3:20","id_categoria":"152","id":"3346"},
        {"descricao_categoria":"Esperar no Senhor","descricao":"Ó minha alma, espera somente em Deus, porque dele vem a minha esperança. Salmos 62:5","id_categoria":"153","id":"3353"},
        {"descricao_categoria":"Esperar no Senhor","descricao":"Espera no Senhor, anima-te, e ele fortalecerá o teu coração; espera, pois, no Senhor. Salmos 27:14","id_categoria":"153","id":"3362"},
        {"descricao_categoria":"Esperar no Senhor","descricao":"Corroborados em toda a fortaleza, segundo a força da sua glória, em toda a paciência, e longanimidade com gozo; Colossenses 1:11","id_categoria":"153","id":"3363"},
        {"descricao_categoria":"Esperar no Senhor","descricao":"Espera no Senhor, e guarda o seu caminho, e te exaltará para herdares a terra; tu o verás quando os ímpios forem desarraigados. Salmos 37:34","id_categoria":"153","id":"3364"},
        {"descricao_categoria":"Espírito","descricao":"Vigiai e orai, para que não entreis em tentação; na verdade, o espírito está pronto, mas a carne é fraca. Mateus 26:41","id_categoria":"154","id":"3365"},
        {"descricao_categoria":"Espírito","descricao":"Porque, assim como o corpo sem o espírito está morto, assim também a fé sem obras é morta. Tiago 2:26","id_categoria":"154","id":"3370"},
        {"descricao_categoria":"Espírito","descricao":"Bem-aventurados os pobres de espírito, porque deles é o reino dos céus; Mateus 5:3","id_categoria":"154","id":"3371"},
        {"descricao_categoria":"Espírito","descricao":"Perto está o Senhor dos que têm o coração quebrantado, e salva os contritos de espírito. Salmos 34:18","id_categoria":"154","id":"3373"},
        {"descricao_categoria":"Espírito","descricao":"Porque sei que disto me resultará salvação, pela vossa oração e pelo socorro do Espírito de Jesus Cristo, Filipenses 1:19","id_categoria":"154","id":"3375"},
        {"descricao_categoria":"Espírito","descricao":"Deus é Espírito, e importa que os que o adoram o adorem em espírito e em verdade. João 4:24","id_categoria":"154","id":"3376"},
        {"descricao_categoria":"Espírito","descricao":"E, se Cristo está em vós, o corpo, na verdade, está morto por causa do pecado, mas o espírito vive por causa da justiça. Romanos 8:10","id_categoria":"154","id":"3380"},
        {"descricao_categoria":"Espírito","descricao":"Assim diz o Senhor DEUS a estes ossos: Eis que farei entrar em vós o espírito, e vivereis. Ezequiel 37:5","id_categoria":"154","id":"3381"},
        {"descricao_categoria":"Espírito","descricao":"Atentai para a minha repreensão; pois eis que vos derramarei abundantemente do meu espírito e vos farei saber as minhas palavras. Provérbios 1:23","id_categoria":"154","id":"3383"},
        {"descricao_categoria":"Espírito","descricao":"Porque Deus não nos deu o espírito de temor, mas de fortaleza, e de amor, e de moderação. 2 Timóteo 1:7","id_categoria":"154","id":"3388"},
        {"descricao_categoria":"Espírito","descricao":"Não sejais vagarosos no cuidado; sede fervorosos no espírito, servindo ao Senhor; Romanos 12:11","id_categoria":"154","id":"3389"},
        {"descricao_categoria":"Espírito","descricao":"Para que o Deus de nosso Senhor Jesus Cristo, o Pai da glória, vos dê em seu conhecimento o espírito de sabedoria e de revelação; Efésios 1:17","id_categoria":"154","id":"3390"},
        {"descricao_categoria":"Espírito","descricao":"Mas o que se ajunta com o Senhor é um mesmo espírito. 1 Coríntios 6:17","id_categoria":"154","id":"3392"},
        {"descricao_categoria":"Espírito","descricao":"Nisto conhecemos que estamos nele, e ele em nós, pois que nos deu do seu Espírito. 1 João 4:13","id_categoria":"154","id":"3393"},
        {"descricao_categoria":"Espírito","descricao":"Digo, porém: Andai em Espírito, e não cumprireis a concupiscência da carne. Gálatas 5:16","id_categoria":"154","id":"3394"},
        {"descricao_categoria":"Espírito","descricao":"Porque a inclinação da carne é morte; mas a inclinação do Espírito é vida e paz. Romanos 8:6","id_categoria":"154","id":"3395"},
        {"descricao_categoria":"Espírito","descricao":"Todos os caminhos do homem são puros aos seus olhos, mas o Senhor pesa o espírito. Provérbios 16:2","id_categoria":"154","id":"3397"},
        {"descricao_categoria":"Espírito","descricao":"O mesmo Espírito testifica com o nosso espírito que somos filhos de Deus. Romanos 8:16","id_categoria":"154","id":"3399"},
        {"descricao_categoria":"Espírito","descricao":"Ora o Deus de esperança vos encha de todo o gozo e paz em crença, para que abundeis em esperança pela virtude do Espírito Santo. Romanos 15:13","id_categoria":"154","id":"3400"},
        {"descricao_categoria":"Espírito","descricao":"Na verdade, há um espírito no homem, e a inspiração do TodoPoderoso o faz entendido. Jó 32:8","id_categoria":"154","id":"3404"},
        {"descricao_categoria":"Espírito","descricao":"Porque todos os que são guiados pelo Espírito de Deus esses são filhos de Deus. Romanos 8:14","id_categoria":"154","id":"3408"},
        {"descricao_categoria":"Espírito","descricao":"A graça de nosso Senhor Jesus Cristo seja com vós todos. Amém. Filipenses 4:23","id_categoria":"154","id":"3409"},
        {"descricao_categoria":"Espírito","descricao":"Melhor é o que tarda em irar-se do que o poderoso, e o que controla o seu ânimo do que aquele que toma uma cidade. Provérbios 16:32","id_categoria":"154","id":"3410"},
        {"descricao_categoria":"Espírito","descricao":"Os sacrifícios para Deus são o espírito quebrantado; a um coração quebrantado e contrito não desprezarás, ó Deus. Salmos 51:17","id_categoria":"154","id":"3411"},
        {"descricao_categoria":"Espírito","descricao":"O espírito do homem é a lâmpada do Senhor, que esquadrinha todo o interior até o mais íntimo do ventre. Provérbios 20:27","id_categoria":"154","id":"3412"},
        {"descricao_categoria":"Espírito","descricao":"Se vivemos em Espírito, andemos também em Espírito. Gálatas 5:25","id_categoria":"154","id":"3414"},
        {"descricao_categoria":"Estudar a Bíblia","descricao":"Persiste em ler, exortar e ensinar, até que eu vá. 1 Timóteo 4:13","id_categoria":"155","id":"3419"},
        {"descricao_categoria":"Estudar a Bíblia","descricao":"Lâmpada para os meus pés é tua palavra, e luz para o meu caminho. Salmos 119:105","id_categoria":"155","id":"3420"},
        {"descricao_categoria":"Estudar a Bíblia","descricao":"Procura apresentar-te a Deus aprovado, como obreiro que não tem de que se envergonhar, que maneja bem a palavra da verdade. 2 Timóteo 2:15","id_categoria":"155","id":"3422"},
        {"descricao_categoria":"Estudar a Bíblia","descricao":"Louvar-te-ei com retidão de coração quando tiver aprendido os teus justos juízos. Salmos 119:7","id_categoria":"155","id":"3424"},
        {"descricao_categoria":"Estudar a Bíblia","descricao":"E Jesus, respondendo, disse-lhes: Porventura não errais vós em razão de não saberdes as Escrituras nem o poder de Deus? Marcos 12:24","id_categoria":"155","id":"3425"},
        {"descricao_categoria":"Estudar a Bíblia","descricao":"À lei e ao testemunho! Se eles não falarem segundo esta palavra, é porque não há luz neles. Isaías 8:20","id_categoria":"155","id":"3432"},
        {"descricao_categoria":"Estudar a Bíblia","descricao":"Examinais as Escrituras, porque vós cuidais ter nelas a vida eterna, e são elas que de mim testificam; João 5:39","id_categoria":"155","id":"3435"},
        {"descricao_categoria":"Estudar a Bíblia","descricao":"Porque o mandamento é lâmpada, e a lei é luz; e as repreensões da correção são o caminho da vida, Provérbios 6:23","id_categoria":"155","id":"3436"},
        {"descricao_categoria":"Estudar a Bíblia","descricao":"Apega-te à instrução e não a largues; guarda-a, porque ela é a tua vida. Provérbios 4:13","id_categoria":"155","id":"3437"},
        {"descricao_categoria":"Evangelização","descricao":"Assim eu me engrandecerei e me santificarei, e me darei a conhecer aos olhos de muitas nações; e saberão que eu sou o Senhor. Ezequiel 38:23","id_categoria":"156","id":"3438"},
        {"descricao_categoria":"Evangelização","descricao":"Porque nada me propus saber entre vós, senão a Jesus Cristo, e este crucificado. 1 Coríntios 2:2","id_categoria":"156","id":"3440"},
        {"descricao_categoria":"Evangelização","descricao":"De sorte que a fé é pelo ouvir, e o ouvir pela palavra de Deus. Romanos 10:17","id_categoria":"156","id":"3444"},
        {"descricao_categoria":"Evangelização","descricao":"Porque o Senhor assim no-lo mandou:eu te pus para luz dos gentios,a fim de que sejas para salvação até os confins da terra. Atos 13:47","id_categoria":"156","id":"3446"},
        {"descricao_categoria":"Evangelização","descricao":"Nisto todos conhecerão que sois meus discípulos, se vos amardes uns aos outros. João 13:35","id_categoria":"156","id":"3448"},
        {"descricao_categoria":"Evangelização","descricao":"Nisto é glorificado meu Pai, que deis muito fruto; e assim sereis meus discípulos. João 15:8","id_categoria":"156","id":"3449"},
        {"descricao_categoria":"Evangelização","descricao":"Tu, porém, fala o que convém à sã doutrina. Tito 2:1","id_categoria":"156","id":"3452"},
        {"descricao_categoria":"Evangelização","descricao":"Louvai ao SENHOR, e invocai o seu nome; fazei conhecidas as suas obras entre os povos. Salmos 105:1","id_categoria":"156","id":"3453"},
        {"descricao_categoria":"Evangelização","descricao":"Desde então começou Jesus a pregar, e a dizer: Arrependei-vos, porque é chegado o reino dos céus. Mateus 4:17","id_categoria":"156","id":"3455"},
        {"descricao_categoria":"Evangelização","descricao":"E disse-lhes: Ide por todo omundo, pregai o evangelho a toda criatura. Marcos 16:15","id_categoria":"156","id":"3457"},
        {"descricao_categoria":"Evangelização","descricao":"Para o que pelo nosso evangelho vos chamou, para alcançardes a glória de nosso Senhor Jesus Cristo. 2 Tessalonicenses 2:14","id_categoria":"156","id":"3458"},
        {"descricao_categoria":"Evangelização","descricao":"O Espírito do Senhor é sobre mim, Pois que me ungiu para evangelizar os pobres. Enviou-me a curar os quebrantados de coração, Lucas 4:18","id_categoria":"156","id":"3459"},
        {"descricao_categoria":"Evangelização","descricao":"Vós sois a luz do mundo; não se pode esconder uma cidade edificada sobre um monte; Mateus 5:14","id_categoria":"156","id":"3460"},
        {"descricao_categoria":"Simplicidade do Evangelho","descricao":"E eu, quando for levantado da terra, todos atrairei a mim. João 12:32","id_categoria":"157","id":"3466"},
        {"descricao_categoria":"Sinais do Fim dos Tempos","descricao":"E este evangelho do reino será pregado em todo o mundo, em testemunho a todas as nações, e então virá o fim. Mateus 24:14","id_categoria":"158","id":"3469"},
        {"descricao_categoria":"Sinais do Fim dos Tempos","descricao":"Mas daquele dia e hora ninguém sabe, nem os anjos que estão no céu, nem o Filho, senão o Pai. Marcos 13:32","id_categoria":"158","id":"3475"},
        {"descricao_categoria":"Sermos Imitadores de Cristo","descricao":"Sede, pois, imitadores de Deus, como filhos amados; Efésios 5:1","id_categoria":"159","id":"3483"},
        {"descricao_categoria":"Sermos Imitadores de Cristo","descricao":"E vós fostes feitos nossos imitadores, e do Senhor, recebendo a palavra em muita tribulação, com gozo do Espírito Santo. 1 Tessalonicenses 1:6","id_categoria":"159","id":"3485"},
        {"descricao_categoria":"Sermos Imitadores de Cristo","descricao":"Sede meus imitadores, como também eu de Cristo. 1 Coríntios 11:1","id_categoria":"159","id":"3486"},
        {"descricao_categoria":"Sermos Imitadores de Cristo","descricao":"Deus é Espírito, e importa que os que o adoram o adorem em espírito e em verdade. João 4:24","id_categoria":"159","id":"3487"},
        {"descricao_categoria":"Sabedoria","descricao":"Instruir-te-ei, e ensinar-te-ei o caminho que deves seguir; guiar-te-ei com os meus olhos. Salmos 32:8","id_categoria":"160","id":"3488"},
        {"descricao_categoria":"Sabedoria","descricao":"Senhor, guia-me na tua justiça, por causa dos meus inimigos; endireita diante de mim o teu caminho. Salmos 5:8","id_categoria":"160","id":"3489"},
        {"descricao_categoria":"Sabedoria","descricao":"Far-me-ás ver a vereda da vida; na tua presença há fartura de alegrias; à tua mão direita há delícias perpetuamente. Salmos 16:11","id_categoria":"160","id":"3490"},
        {"descricao_categoria":"Sabedoria","descricao":"Bem-aventurado o homem que acha sabedoria, e o homem que adquire conhecimento; Provérbios 3:13","id_categoria":"160","id":"3492"},
        {"descricao_categoria":"Sabedoria","descricao":"Nunca digas: Por que foram os dias passados melhores do que estes? Porque não provém da sabedoria esta pergunta. Eclesiastes 7:10","id_categoria":"160","id":"3494"},
        {"descricao_categoria":"Sabedoria","descricao":"Não presumas do dia de amanhã, porque não sabes o que ele trará. Provérbios 27:1","id_categoria":"160","id":"3496"},
        {"descricao_categoria":"Sabedoria","descricao":"Abre tu os meus olhos, para que veja as maravilhas da tua lei. Salmos 119:18","id_categoria":"160","id":"3497"},
        {"descricao_categoria":"Sabedoria","descricao":"Ouve o conselho, e recebe a correção, para que no fim sejas sábio. Provérbios 19:20","id_categoria":"160","id":"3498"},
        {"descricao_categoria":"Sabedoria","descricao":"Porque eu vos darei boca e sabedoria a que não poderão resistir nem contradizer todos quantos se vos opuserem. Lucas 21:15","id_categoria":"160","id":"3499"},
        {"descricao_categoria":"Sabedoria","descricao":"Ensina-nos a contar os nossos dias, de tal maneira que alcancemos corações sábios. Salmos 90:12","id_categoria":"160","id":"3500"},
        {"descricao_categoria":"Sabedoria","descricao":"Os homens maus não entendem o juízo, mas os que buscam ao Senhor entendem tudo. Provérbios 28:5","id_categoria":"160","id":"3503"},
        {"descricao_categoria":"Sabedoria","descricao":"Porque o que me achar, achará a vida, e alcançará o favor do Senhor. Provérbios 8:35","id_categoria":"160","id":"3504"},
        {"descricao_categoria":"Sabedoria","descricao":"Ninguém se engane a si mesmo. Se alguém dentre vós se tem por sábio neste mundo, faça-se louco para ser sábio. 1 Coríntios 3:18","id_categoria":"160","id":"3505"},
        {"descricao_categoria":"Sabedoria","descricao":"Com os idosos está a sabedoria, e na longevidade o entendimento. Com ele está a sabedoria e a força; conselho e entendimento tem. Jó 12:12,13","id_categoria":"160","id":"3506"},
        {"descricao_categoria":"Sabedoria","descricao":"E, se algum de vós tem falta de sabedoria, peça-a a Deus, que a todos dá liberalmente, e o não lança em rosto, e ser-lhe-á dada. Tiago 1:5","id_categoria":"160","id":"3507"},
        {"descricao_categoria":"Sabedoria","descricao":"Em vindo a soberba, virá também a afronta; mas com os humildes está a sabedoria. Provérbios 11:2","id_categoria":"160","id":"3508"},
        {"descricao_categoria":"Sabedoria","descricao":"Não vos inquieteis, pois, pelo dia de amanhã, porque o dia de amanhã cuidará de si mesmo. Basta a cada dia o seu mal. Mateus 6:34","id_categoria":"160","id":"3512"},
        {"descricao_categoria":"Sabedoria","descricao":"Portanto, vede prudentemente como andais, não como néscios, mas como sábios, Remindo o tempo; porquanto os dias são maus. Efésios 5:15,16","id_categoria":"160","id":"3517"},
        {"descricao_categoria":"Sabedoria","descricao":"O temor do Senhor é a instrução da sabedoria, e precedendo a honra vai a humildade. Provérbios 15:33","id_categoria":"160","id":"3518"},
        {"descricao_categoria":"Sabedoria","descricao":"O que adquire entendimento ama a sua alma; o que cultiva a inteligência achará o bem. Provérbios 19:8","id_categoria":"160","id":"3519"},
        {"descricao_categoria":"Sabedoria","descricao":"O sábio ouvirá e crescerá em conhecimento, e o entendido adquirirá sábios conselhos; Provérbios 1:5","id_categoria":"160","id":"3520"},
        {"descricao_categoria":"Sabedoria","descricao":"Da soberba só provém a contenda, mas com os que se aconselham se acha a sabedoria. Provérbios 13:10","id_categoria":"160","id":"3522"},
        {"descricao_categoria":"Sabedoria","descricao":"O Senhor, com sabedoria fundou a terra; com entendimento preparou os céus. Provérbios 3:19","id_categoria":"160","id":"3526"},
        {"descricao_categoria":"Sabedoria","descricao":"Ao único Deus, sábio, seja dada glória por Jesus Cristo para todo o sempre. Amém. Romanos 16:27","id_categoria":"160","id":"3527"},
        {"descricao_categoria":"Sabedoria","descricao":"Até isto procede do Senhor dos Exércitos; porque é maravilhoso em conselho e grande em obra. Isaías 28:29","id_categoria":"160","id":"3530"},
        {"descricao_categoria":"Sabedoria","descricao":"Porque o Senhor dá a sabedoria; da sua boca é que vem o conhecimento e o entendimento. Provérbios 2:6","id_categoria":"160","id":"3531"},
        {"descricao_categoria":"Sabedoria","descricao":"Guiará os mansos em justiça e aos mansos ensinará o seu caminho. Salmos 25:9","id_categoria":"160","id":"3532"},
        {"descricao_categoria":"Sabedoria","descricao":"O sábio de coração aceita os mandamentos, mas o insensato de lábios ficará transtornado. Provérbios 10:8","id_categoria":"160","id":"3533"},
        {"descricao_categoria":"Sabedoria","descricao":"Até o tolo, quando se cala, é reputado por sábio; e o que cerra os seus lábios é tido por entendido. Provérbios 17:28","id_categoria":"160","id":"3534"},
        {"descricao_categoria":"Sabedoria","descricao":"Cada pensamento se confirma com conselho e com bons conselhos se faz a guerra. Provérbios 20:18","id_categoria":"160","id":"3535"},
        {"descricao_categoria":"Sabedoria","descricao":"O caminho do insensato é reto aos seus próprios olhos, mas o que dá ouvidos ao conselho é sábio. Provérbios 12:15","id_categoria":"160","id":"3537"},
        {"descricao_categoria":"Sabedoria","descricao":"Ó Senhor, quão variadas são as tuas obras! Todas as coisas fizeste com sabedoria; cheia está a terra das tuas riquezas. Salmos 104:24","id_categoria":"160","id":"3538"},
        {"descricao_categoria":"Sabedoria","descricao":"Porque os meus pensamentos não são os vossos pensamentos, nem os vossos caminhos os meus caminhos, diz o Senhor. Isaías 55:8","id_categoria":"160","id":"3542"},
        {"descricao_categoria":"Sabedoria","descricao":"Não sejas sábio a teus próprios olhos; teme ao Senhor e aparta-te do mal. Provérbios 3:7","id_categoria":"160","id":"3543"},
        {"descricao_categoria":"Sabedoria","descricao":"A boca do justo fala a sabedoria; a sua língua fala do juízo. Salmos 37:30","id_categoria":"160","id":"3545"},
        {"descricao_categoria":"Sabedoria","descricao":"Lâmpada para os meus pés é tua palavra, e luz para o meu caminho. Salmos 119:105","id_categoria":"160","id":"3547"},
        {"descricao_categoria":"Sabedoria","descricao":"Os passos de um homem bom são confirmados pelo Senhor, e deleita-se no seu caminho. Salmos 37:23","id_categoria":"160","id":"3548"},
        {"descricao_categoria":"Sabedoria","descricao":"O que guarda a sua boca conserva a sua alma, mas o que abre muito os seus lábios se destrói. Provérbios 13:3","id_categoria":"160","id":"3550"},
        {"descricao_categoria":"Sabedoria","descricao":"Ele fez a terra com o seu poder; ele estabeleceu o mundo com a sua sabedoria, e com a sua inteligência estendeu os céus. Jeremias 10:12","id_categoria":"160","id":"3551"},
        {"descricao_categoria":"Sabedoria","descricao":"Com conselhos prudentes tu farás a guerra; e há vitória na multidão dos conselheiros. Provérbios 24:6","id_categoria":"160","id":"3552"},
        {"descricao_categoria":"Sabedoria","descricao":"Eu sei, ó Senhor, que não é do homem o seu caminho; nem do homem que caminha o dirigir os seus passos. Jeremias 10:23","id_categoria":"160","id":"3553"},
        {"descricao_categoria":"Sabedoria","descricao":"O tolo não tem prazer na sabedoria, mas só em que se manifeste aquilo que agrada o seu coração. Provérbios 18:2","id_categoria":"160","id":"3555"},
        {"descricao_categoria":"Sabedoria","descricao":"Falou Daniel, dizendo: Seja bendito o nome de Deus de eternidade a eternidade, porque dele são a sabedoria e a força; Daniel 2:20","id_categoria":"160","id":"3557"},
        {"descricao_categoria":"Sabedoria","descricao":"Do homem são as preparações do coração, mas do SENHOR a resposta da língua. Provérbios 16:1","id_categoria":"160","id":"3558"},
        {"descricao_categoria":"Sabedoria","descricao":"Grande é o nosso Senhor, e de grande poder; o seu entendimento é infinito. Salmos 147:5","id_categoria":"160","id":"3560"},
        {"descricao_categoria":"Sabedoria","descricao":"O coração do entendido adquire o conhecimento, e o ouvido dos sábios busca a sabedoria. Provérbios 18:15","id_categoria":"160","id":"3561"},
        {"descricao_categoria":"Sabedoria","descricao":"Guiar-me-ás com o teu conselho, e depois me receberás na glória. Salmos 73:24","id_categoria":"160","id":"3562"},
        {"descricao_categoria":"Sabedoria","descricao":"O longânimo é grande em entendimento, mas o que é de espírito impaciente mostra a sua loucura. Provérbios 14:29","id_categoria":"160","id":"3565"},
        {"descricao_categoria":"Sabedoria","descricao":"Quão melhor é adquirir a sabedoria do que o ouro! e quão mais excelente é adquirir a prudência do que a prata! Provérbios 16:16","id_categoria":"160","id":"3566"},
        {"descricao_categoria":"Sabedoria","descricao":"Deleita-te também no Senhor, e te concederá os desejos do teu coração. Salmos 37:4","id_categoria":"160","id":"3567"},
        {"descricao_categoria":"Sabedoria","descricao":"É esta a terceira vez que vou ter convosco. Por boca de duas ou três testemunhas será confirmada toda a palavra. 2 Coríntios 13:1","id_categoria":"160","id":"3569"},
        {"descricao_categoria":"Sabedoria","descricao":"Quando caminhares, te guiará; quando te deitares, te guardará; quando acordares, falará contigo. Provérbios 6:22","id_categoria":"160","id":"3570"},
        {"descricao_categoria":"Sabedoria","descricao":"Quem dentre vós é sábio e entendido? Mostre pelo seu bom trato as suas obras em mansidão de sabedoria. Tiago 3:13","id_categoria":"160","id":"3571"},
        {"descricao_categoria":"Sabedoria","descricao":"A entrada das tuas palavras dá luz, dá entendimento aos símplices. Salmos 119:130","id_categoria":"160","id":"3575"},
        {"descricao_categoria":"Sabedoria","descricao":"Não julgueis segundo a aparência, mas julgai segundo a reta justiça. João 7:24","id_categoria":"160","id":"3576"},
        {"descricao_categoria":"Sabedoria","descricao":"O coração do homem planeja o seu caminho, mas o Senhor lhe dirige os passos. Provérbios 16:9","id_categoria":"160","id":"3577"},
        {"descricao_categoria":"Sabedoria","descricao":"Ensina-me, Senhor, o teu caminho, e guia-me pela vereda direita, por causa dos meus inimigos. Salmos 27:11","id_categoria":"160","id":"3580"},
        {"descricao_categoria":"Sabedoria","descricao":"O temor do Senhor é o princípio do conhecimento; os loucos desprezam a sabedoria e a instrução. Provérbios 1:7","id_categoria":"160","id":"3582"},
        {"descricao_categoria":"Sacrifício","descricao":"Porque Cristo, estando nós ainda fracos, morreu a seu tempo pelos ímpios. Romanos 5:6","id_categoria":"161","id":"3584"},
        {"descricao_categoria":"Sacrifício","descricao":"Porque o Filho do homem também não veio para ser servido, mas para servir e dar a sua vida em resgate de muitos. Marcos 10:45","id_categoria":"161","id":"3585"},
        {"descricao_categoria":"Sacrifício","descricao":"Mas eu te oferecerei sacrifício com a voz do agradecimento; o que votei pagarei. Do Senhor vem a salvação. Jonas 2:9","id_categoria":"161","id":"3587"},
        {"descricao_categoria":"Sacrifício","descricao":"Aquele que nem mesmo a seu próprio Filho poupou, antes o entregou por todos nós, como nos não dará também com ele todas as coisas? Romanos 8:32","id_categoria":"161","id":"3589"},
        {"descricao_categoria":"Sacrifício","descricao":"Honra ao Senhor com os teus bens, e com a primeira parte de todos os teus ganhos; Provérbios 3:9","id_categoria":"161","id":"3592"},
        {"descricao_categoria":"Sacrifício","descricao":"E andai em amor, como também Cristo vos amou, e se entregou a si mesmo por nós, em oferta e sacrifício a Deus, em cheiro suave. Efésios 5:2","id_categoria":"161","id":"3594"},
        {"descricao_categoria":"Sacrifício","descricao":"Bem como o Filho do homem não veio para ser servido, mas para servir, e para dar a sua vida em resgate de muitos. Mateus 20:28","id_categoria":"161","id":"3596"},
        {"descricao_categoria":"Sacrifício","descricao":"O qual se deu a si mesmo por nós para nos remir de toda a iniqüidade, e purificar para si um povo seu especial, zeloso de boas obras. Tito 2:14","id_categoria":"161","id":"3597"},
        {"descricao_categoria":"Sacrifício","descricao":"E, clamando Jesus com grande voz, disse: Pai, nas tuas mãos entrego o meu espírito. E, havendo dito isto, expirou. Lucas 23:46","id_categoria":"161","id":"3598"},
        {"descricao_categoria":"Sacrifício","descricao":"Fazer justiça e juízo é mais aceitável ao Senhor do que sacrifício. Provérbios 21:3","id_categoria":"161","id":"3599"},
        {"descricao_categoria":"Sacrifício","descricao":"Ninguém tem maior amor do que este, de dar alguém a sua vida pelos seus amigos. João 15:13","id_categoria":"161","id":"3600"},
        {"descricao_categoria":"Sacrifício","descricao":"Porque, qualquer que quiser salvar a sua vida, perdê-la-á; mas qualquer que, por amor de mim, perder a sua vida, a salvará. Lucas 9:24","id_categoria":"161","id":"3602"},
        {"descricao_categoria":"Salvação","descricao":"Jesus respondeu, e disse-lhe: Na verdade, na verdade te digo que aquele que não nascer de novo, não pode ver o reino de Deus. João 3:3","id_categoria":"162","id":"3604"},
        {"descricao_categoria":"Salvação","descricao":"Porque, assim como todos morrem em Adão, assim também todos serão vivificados em Cristo. 1 Coríntios 15:22","id_categoria":"162","id":"3607"},
        {"descricao_categoria":"Salvação","descricao":"Aquele que crê no Filho tem a vida eterna; mas aquele que não crê no Filho não verá a vida, mas a ira de Deus sobre ele permanece. João 3:36","id_categoria":"162","id":"3608"},
        {"descricao_categoria":"Salvação","descricao":"Porque o Senhor assim no-lo mandou:eu te pus para luz dos gentios,a fim de que sejas para salvação até os confins da terra. Atos 13:47","id_categoria":"162","id":"3610"},
        {"descricao_categoria":"Salvação","descricao":"Porque o Filho do homem veio buscar e salvar o que se havia perdido. Lucas 19:10","id_categoria":"162","id":"3611"},
        {"descricao_categoria":"Salvação","descricao":"Disse-lhe Jesus: Eu sou o caminho, e a verdade e a vida; ninguém vem ao Pai, senão por mim. João 14:6","id_categoria":"162","id":"3612"},
        {"descricao_categoria":"Salvação","descricao":"E quase todas as coisas, segundo a lei, se purificam com sangue; e sem derramamento de sangue não há remissão. Hebreus 9:22","id_categoria":"162","id":"3615"},
        {"descricao_categoria":"Salvação","descricao":"Visto que com o coração se crê para a justiça, e com a boca se faz confissão para a salvação. Romanos 10:10","id_categoria":"162","id":"3618"},
        {"descricao_categoria":"Salvação","descricao":"E dou-lhes a vida eterna, e nunca hão de perecer, e ninguém as arrebatará da minha mão. João 10:28","id_categoria":"162","id":"3620"},
        {"descricao_categoria":"Salvação","descricao":"Quem crer e for batizado será salvo; mas quem não crer será condenado. Marcos 16:16","id_categoria":"162","id":"3622"},
        {"descricao_categoria":"Salvação","descricao":"Mas, a todos quantos o receberam, deu-lhes o poder de serem feitos filhos de Deus, aos que crêem no seu nome; João 1:12","id_categoria":"162","id":"3623"},
        {"descricao_categoria":"Salvação","descricao":"Se confessarmos os nossos pecados, ele é fiel e justo para nos perdoar os pecados, e nos purificar de toda a injustiça. 1 João 1:9","id_categoria":"162","id":"3627"},
        {"descricao_categoria":"Salvação","descricao":"E disse: Em verdade vos digo que, se não vos converterdes e não vos fizerdes como meninos, de modo algum entrareis no reino dos céus. Mateus 18:3","id_categoria":"162","id":"3628"},
        {"descricao_categoria":"Salvação","descricao":"E eles disseram: Crê no Senhor Jesus Cristo e serás salvo, tu e a tua casa. Atos 16:31","id_categoria":"162","id":"3630"},
        {"descricao_categoria":"Salvação","descricao":"Eis que estou à porta, e bato; se alguém ouvir a minha voz, e abrir a porta, entrarei em sua casa, e com ele cearei, e ele comigo. Apocalipse 3:20","id_categoria":"162","id":"3631"},
        {"descricao_categoria":"Salvação","descricao":"A minha alma espera somente em Deus; dele vem a minha salvação. Salmos 62:1","id_categoria":"162","id":"3632"},
        {"descricao_categoria":"Salvação","descricao":"E em nenhum outro há salvação, porque também debaixo do céu nenhum outro nome há, dado entre os homens, pelo qual devamos ser salvos. Atos 4:12","id_categoria":"162","id":"3633"},
        {"descricao_categoria":"Salvação","descricao":"Mas ele respondeu: As coisas que são impossíveis aos homens são possíveis a Deus. Lucas 18:27","id_categoria":"162","id":"3635"},
        {"descricao_categoria":"Salvação","descricao":"Porque todos pecaram e destituídos estão da glória de Deus; Romanos 3:23","id_categoria":"162","id":"3636"},
        {"descricao_categoria":"Salvação","descricao":"Assim que, se alguém está em Cristo, nova criatura é; as coisas velhas já passaram; eis que tudo se fez novo. 2 Coríntios 5:17","id_categoria":"162","id":"3637"},
        {"descricao_categoria":"Salvação","descricao":"E Jesus lhe disse: Vai, a tua fé te salvou. E logo viu, e seguiu a Jesus pelo caminho. Marcos 10:52","id_categoria":"162","id":"3638"},
        {"descricao_categoria":"Salvação","descricao":"Porque o salário do pecado é a morte, mas o dom gratuito de Deus é a vida eterna, por Cristo Jesus nosso Senhor. Romanos 6:23","id_categoria":"162","id":"3639"},
        {"descricao_categoria":"Salvação","descricao":"Visto que foste precioso aos meus olhos, também foste honrado, e eu te amei, assim dei os homens por ti, e os povos pela tua vida. Isaías 43:4","id_categoria":"162","id":"3642"},
        {"descricao_categoria":"Salvação","descricao":"E acontecerá que todo aquele que invocar o nome do Senhor será salvo. Atos 2:21","id_categoria":"162","id":"3643"},
        {"descricao_categoria":"Salvador","descricao":"E ele é a propiciação pelos nossos pecados, e não somente pelos nossos, mas também pelos de todo o mundo. 1 João 2:2","id_categoria":"163","id":"3645"},
        {"descricao_categoria":"Salvador","descricao":"Pois, na cidade de Davi, vos nasceu hoje o Salvador, que é Cristo, o Senhor. Lucas 2:11","id_categoria":"163","id":"3647"},
        {"descricao_categoria":"Salvador","descricao":"E, sendo ele consumado, veio a ser a causa da eterna salvação para todos os que lhe obedecem; Hebreus 5:9","id_categoria":"163","id":"3651"},
        {"descricao_categoria":"Salvador","descricao":"Portanto, pode também salvar perfeitamente os que por ele se chegam a Deus, vivendo sempre para interceder por eles. Hebreus 7:25","id_categoria":"163","id":"3653"},
        {"descricao_categoria":"Salvador","descricao":"Em quem temos a redenção pelo seu sangue, a remissão das ofensas, segundo as riquezas da sua graça, Efésios 1:7","id_categoria":"163","id":"3660"},
        {"descricao_categoria":"Salvador","descricao":"Porque eu sei que o meu Redentor vive, e que por fim se levantará sobre a terra. Jó 19:25","id_categoria":"163","id":"3661"},
        {"descricao_categoria":"Santa Ceia","descricao":"E aconteceu que, estando com eles à mesa, tomando o pão, o abençoou e partiu-o, e lho deu. Lucas 24:30","id_categoria":"164","id":"3666"},
        {"descricao_categoria":"Santa Ceia","descricao":"E perseveravam na doutrina dos apóstolos, e na comunhão, e no partir do pão, e nas orações. Atos 2:42","id_categoria":"164","id":"3667"},
        {"descricao_categoria":"Santa Ceia","descricao":"Porque todas as vezes que comerdes este pão e beberdes este cálice anunciais a morte do Senhor, até que venha. 1 Coríntios 11:26","id_categoria":"164","id":"3670"},
        {"descricao_categoria":"Santidade","descricao":"Mas a fornicação, e toda a impureza ou avareza, nem ainda se nomeie entre vós, como convém a santos; Efésios 5:3","id_categoria":"165","id":"3673"},
        {"descricao_categoria":"Santidade","descricao":"Segui a paz com todos, e a santificação, sem a qual ninguém verá o Senhor; Hebreus 12:14","id_categoria":"165","id":"3674"},
        {"descricao_categoria":"Santidade","descricao":"Buscai o bem, e não o mal, para que vivais; e assim o Senhor, o Deus dos Exércitos, estará convosco, como dizeis. Amós 5:14","id_categoria":"165","id":"3677"},
        {"descricao_categoria":"Santidade","descricao":"Como também nos elegeu nele antes da fundação do mundo, para que fôssemos santos e irrepreensíveis diante dele em amor; Efésios 1:4","id_categoria":"165","id":"3679"},
        {"descricao_categoria":"Santidade","descricao":"O temor do Senhor é o princípio da sabedoria, e o conhecimento do Santo a prudência. Provérbios 9:10","id_categoria":"165","id":"3681"},
        {"descricao_categoria":"Santidade","descricao":"Porém tu és santo, tu que habitas entre os louvores de Israel. Salmos 22:3","id_categoria":"165","id":"3683"},
        {"descricao_categoria":"Santidade","descricao":"De sorte que haja em vós o mesmo sentimento que houve também em Cristo Jesus, Filipenses 2:5","id_categoria":"165","id":"3684"},
        {"descricao_categoria":"Santidade","descricao":"Sede vós pois perfeitos, como é perfeito o vosso Pai que está nos céus. Mateus 5:48","id_categoria":"165","id":"3685"},
        {"descricao_categoria":"Santidade","descricao":"Laço é para o homem apropriar-se do que é santo, e só refletir depois de feitos os votos. Provérbios 20:25","id_categoria":"165","id":"3686"},
        {"descricao_categoria":"Santidade","descricao":"Não sabeis vós que sois o templo de Deus e que o Espírito de Deus habita em vós? 1 Coríntios 3:16","id_categoria":"165","id":"3687"},
        {"descricao_categoria":"Santidade","descricao":"E ser-me-eis santos, porque eu, o Senhor, sou santo, e vos separei dos povos, para serdes meus. Levítico 20:26","id_categoria":"165","id":"3688"},
        {"descricao_categoria":"Santidade","descricao":"Assim eu me engrandecerei e me santificarei, e me darei a conhecer aos olhos de muitas nações; e saberão que eu sou o Senhor. Ezequiel 38:23","id_categoria":"165","id":"3689"},
        {"descricao_categoria":"Santidade","descricao":"Santifica-os na tua verdade; a tua palavra é a verdade. João 17:17","id_categoria":"165","id":"3691"},
        {"descricao_categoria":"Santidade","descricao":"Com que purificará o jovem o seu caminho? Observando-o conforme a tua palavra. Salmos 119:9","id_categoria":"165","id":"3693"},
        {"descricao_categoria":"Santidade","descricao":"Mas agora, libertados do pecado, e feitos servos de Deus, tendes o vosso fruto para santificação, e por fim a vida eterna. Romanos 6:22","id_categoria":"165","id":"3694"},
        {"descricao_categoria":"Santidade","descricao":"Bendize, ó minha alma, ao SENHOR, e tudo o que há em mim bendiga o seu santo nome. Salmos 103:1","id_categoria":"165","id":"3695"},
        {"descricao_categoria":"Santidade","descricao":"Portanto santificai-vos, e sede santos, pois eu sou o Senhor vosso Deus. Levítico 20:7","id_categoria":"165","id":"3696"},
        {"descricao_categoria":"Santidade","descricao":"E rogo-vos, irmãos, que noteis os que promovem dissensões e escândalos contra a doutrina que aprendestes; desviai-vos deles. Romanos 16:17","id_categoria":"165","id":"3697"},
        {"descricao_categoria":"Santidade","descricao":"Não há santo como o Senhor; porque não há outro fora de ti; e rocha nenhuma há como o nosso Deus. 1 Samuel 2:2","id_categoria":"165","id":"3702"},
        {"descricao_categoria":"Saúde","descricao":"Afasta, pois, a ira do teu coração, e remove da tua carne o mal, porque a adolescência e a juventude são vaidade. Eclesiastes 11:10","id_categoria":"166","id":"3707"},
        {"descricao_categoria":"Saúde","descricao":"Curai os enfermos, limpai os leprosos, ressuscitai os mortos, expulsai os demônios; de graça recebestes, de graça dai. Mateus 10:8","id_categoria":"166","id":"3708"},
        {"descricao_categoria":"Saúde","descricao":"Amado, desejo que te vá bem em todas as coisas, e que tenhas saúde, assim como bem vai a tua alma. 3 João 1:2","id_categoria":"166","id":"3709"},
        {"descricao_categoria":"Saúde","descricao":"E servireis ao Senhor vosso Deus, e ele abençoará o vosso pão e a vossa água; e eu tirarei do meio de vós as enfermidades. Êxodo 23:25","id_categoria":"166","id":"3710"},
        {"descricao_categoria":"Saúde","descricao":"Jesus, porém, ouvindo, disse-lhes: Não necessitam de médico os sãos, mas, sim, os doentes. Mateus 9:12","id_categoria":"166","id":"3713"},
        {"descricao_categoria":"Saúde","descricao":"As palavras suaves são favos de mel, doces para a alma, e saúde para os ossos. Provérbios 16:24","id_categoria":"166","id":"3714"},
        {"descricao_categoria":"Saúde","descricao":"A esperança adiada desfalece o coração, mas o desejo atendido é árvore de vida. Provérbios 13:12","id_categoria":"166","id":"3715"},
        {"descricao_categoria":"Saúde","descricao":"Sara os quebrantados de coração, e lhes ata as suas feridas. Salmos 147:3","id_categoria":"166","id":"3716"},
        {"descricao_categoria":"Saúde","descricao":"O coração alegre é como o bom remédio, mas o espírito abatido seca até os ossos. Provérbios 17:22","id_categoria":"166","id":"3717"},
        {"descricao_categoria":"Seguir a Jesus","descricao":"Antes, seguindo a verdade em amor, cresçamos em tudo naquele que é a cabeça, Cristo, Efésios 4:15","id_categoria":"167","id":"3718"},
        {"descricao_categoria":"Seguir a Jesus","descricao":"Falou-lhes, pois, Jesus outra vez, dizendo: Eu sou a luz do mundo; quem me segue não andará em trevas, mas terá a luz da vida. João 8:12","id_categoria":"167","id":"3720"},
        {"descricao_categoria":"Seguir a Jesus","descricao":"No dia seguinte quis Jesus ir à Galiléia, e achou a Filipe, e disse-lhe: Segue-me. João 1:43","id_categoria":"167","id":"3722"},
        {"descricao_categoria":"Seguir a Jesus","descricao":"Fareis conforme os meus juízos, e os meus estatutos guardareis, para andardes neles. Eu sou o Senhor vosso Deus. Levítico 18:4","id_categoria":"167","id":"3723"},
        {"descricao_categoria":"Seguir a Jesus","descricao":"E disse-lhes: Vinde após mim, e eu vos farei pescadores de homens. Então eles, deixando logo as redes, seguiram-no. Mateus 4:19,20","id_categoria":"167","id":"3725"},
        {"descricao_categoria":"Seguir a Jesus","descricao":"Se alguém me serve, siga-me, e onde eu estiver, ali estará também o meu servo. E, se alguém me servir, meu Pai o honrará. João 12:26","id_categoria":"167","id":"3726"},
        {"descricao_categoria":"Seguir a Jesus","descricao":"Então disse Jesus aos seus discípulos: Se alguém quiser vir após mim, renuncie-se a si mesmo, tome sobre si a sua cruz, e siga-me; Mateus 16:24","id_categoria":"167","id":"3728"},
        {"descricao_categoria":"Seguir","descricao":"Apressei-me, e não me detive, a observar os teus mandamentos. Salmos 119:60","id_categoria":"168","id":"3730"},
        {"descricao_categoria":"Seguir","descricao":"Nisto é glorificado meu Pai, que deis muito fruto; e assim sereis meus discípulos. João 15:8","id_categoria":"168","id":"3732"},
        {"descricao_categoria":"Seguir","descricao":"Se alguém me serve, siga-me, e onde eu estiver, ali estará também o meu servo. E, se alguém me servir, meu Pai o honrará. João 12:26","id_categoria":"168","id":"3733"},
        {"descricao_categoria":"Seguir","descricao":"Nisto todos conhecerão que sois meus discípulos, se vos amardes uns aos outros. João 13:35","id_categoria":"168","id":"3734"},
        {"descricao_categoria":"Seguir","descricao":"Bem-aventurado aquele que teme ao SENHOR e anda nos seus caminhos. Salmos 128:1","id_categoria":"168","id":"3736"},
        {"descricao_categoria":"Seguir","descricao":"Assim também a fé, se não tiver as obras, é morta em si mesma. Tiago 2:17","id_categoria":"168","id":"3737"},
        {"descricao_categoria":"Seguir","descricao":"Falou-lhes, pois, Jesus outra vez, dizendo: Eu sou a luz do mundo; quem me segue não andará em trevas, mas terá a luz da vida. João 8:12","id_categoria":"168","id":"3739"},
        {"descricao_categoria":"Seguir","descricao":"Porque somos feitura sua, criados em Cristo Jesus para as boas obras, as quais Deus preparou para que andássemos nelas. Efésios 2:10","id_categoria":"168","id":"3740"},
        {"descricao_categoria":"Seguir","descricao":"Não declines nem para a direita nem para a esquerda; retira o teu pé do mal. Provérbios 4:27","id_categoria":"168","id":"3742"},
        {"descricao_categoria":"Seguir","descricao":"Disse-lhe Jesus: Se queres ser perfeito, vai, vende tudo o que tens e dá-o aos pobres, e terás um tesouro no céu; e vem, e segue-me. Mateus 19:21","id_categoria":"168","id":"3744"},
        {"descricao_categoria":"Seguir","descricao":"Porque para isto sois chamados; pois também Cristo padeceu por nós, deixando-nos o exemplo, para que sigais as suas pisadas. 1 Pedro 2:21","id_categoria":"168","id":"3745"},
        {"descricao_categoria":"Seguir","descricao":"Ordena os meus passos na tua palavra, e não se apodere de mim iniqüidade alguma. Salmos 119:133","id_categoria":"168","id":"3747"},
        {"descricao_categoria":"Seguir","descricao":"Os passos de um homem bom são confirmados pelo Senhor, e deleita-se no seu caminho. Salmos 37:23","id_categoria":"168","id":"3748"},
        {"descricao_categoria":"Seguir","descricao":"Ora o Senhor encaminhe os vossos corações no amor de Deus, e na paciência de Cristo. 2 Tessalonicenses 3:5","id_categoria":"168","id":"3749"},
        {"descricao_categoria":"Seguir","descricao":"Se vivemos em Espírito, andemos também em Espírito. Gálatas 5:25","id_categoria":"168","id":"3751"},
        {"descricao_categoria":"Seguir","descricao":"E quem não toma a sua cruz, e não segue após mim, não é digno de mim. Mateus 10:38","id_categoria":"168","id":"3752"},
        {"descricao_categoria":"Seguir","descricao":"E, quanto fizerdes por palavras ou por obras, fazei tudo em nome do Senhor Jesus, dando por ele graças a Deus Pai. Colossenses 3:17","id_categoria":"168","id":"3753"},
        {"descricao_categoria":"Seguir","descricao":"Sede, pois, imitadores de Deus, como filhos amados; Efésios 5:1","id_categoria":"168","id":"3754"},
        {"descricao_categoria":"Separação","descricao":"Ainda que eu andasse pelo vale da sombra da morte, não temeria mal algum, porque tu estás comigo; a tua vara e o teu cajado me consolam. Salmos 23:4","id_categoria":"169","id":"3756"},
        {"descricao_categoria":"Separação","descricao":"Perto está o Senhor dos que têm o coração quebrantado, e salva os contritos de espírito. Salmos 34:18","id_categoria":"169","id":"3757"},
        {"descricao_categoria":"Separação","descricao":"Sara os quebrantados de coração, e lhes ata as suas feridas. Salmos 147:3","id_categoria":"169","id":"3760"},
        {"descricao_categoria":"Separação","descricao":"E Mispá, porquanto disse: Atente o Senhor entre mim e ti, quando nós estivermos apartados um do outro. Gênesis 31:49","id_categoria":"169","id":"3761"},
        {"descricao_categoria":"Separação","descricao":"Não vos deixarei órfãos; voltarei para vós. João 14:18","id_categoria":"169","id":"3764"},
        {"descricao_categoria":"Separação","descricao":"Porque necessitais de paciência, para que, depois de haverdes feito a vontade de Deus, possais alcançar a promessa. Hebreus 10:36","id_categoria":"169","id":"3765"},
        {"descricao_categoria":"Separação","descricao":"Porque, quando meu pai e minha mãe me desampararem, o Senhor me recolherá. Salmos 27:10","id_categoria":"169","id":"3767"},
        {"descricao_categoria":"Ser de Confiança","descricao":"Porque a palavra do Senhor é reta, e todas as suas obras são fiéis. Salmos 33:4","id_categoria":"170","id":"3769"},
        {"descricao_categoria":"Ser de Confiança","descricao":"E odiados de todos sereis por causa do meu nome; mas aquele que perseverar até ao fim, esse será salvo. Mateus 10:22","id_categoria":"170","id":"3771"},
        {"descricao_categoria":"Ser de Confiança","descricao":"Quem é, pois, o servo fiel e prudente, que o seu senhor constituiu sobre a sua casa, para dar o sustento a seu tempo? Mateus 24:45","id_categoria":"170","id":"3772"},
        {"descricao_categoria":"Servir","descricao":"Não sejais vagarosos no cuidado; sede fervorosos no espírito, servindo ao Senhor; Romanos 12:11","id_categoria":"171","id":"3774"},
        {"descricao_categoria":"Servir","descricao":"Os passos de um homem bom são confirmados pelo Senhor, e deleita-se no seu caminho. Salmos 37:23","id_categoria":"171","id":"3775"},
        {"descricao_categoria":"Servir","descricao":"E ele, assentando-se, chamou os doze, e disse-lhes: Se alguém quiser ser o primeiro, será o derradeiro de todos e o servo de todos. Marcos 9:35","id_categoria":"171","id":"3782"},
        {"descricao_categoria":"Servir","descricao":"Se alguém me serve, siga-me, e onde eu estiver, ali estará também o meu servo. E, se alguém me servir, meu Pai o honrará. João 12:26","id_categoria":"171","id":"3783"},
        {"descricao_categoria":"Servir","descricao":"Bem como o Filho do homem não veio para ser servido, mas para servir, e para dar a sua vida em resgate de muitos. Mateus 20:28","id_categoria":"171","id":"3787"},
        {"descricao_categoria":"Servir","descricao":"E o seu senhor lhe disse: Bem está, servo bom e fiel. Sobre o pouco foste fiel, sobre muito te colocarei; entra no gozo do teu senhor. Mateus 25:21","id_categoria":"171","id":"3789"},
        {"descricao_categoria":"Servir","descricao":"Ora, se eu, Senhor e Mestre, vos lavei os pés, vós deveis também lavar os pés uns aos outros. João 13:14","id_categoria":"171","id":"3790"},
        {"descricao_categoria":"Servir","descricao":"Como livres, e não tendo a liberdade por cobertura da malícia, mas como servos de Deus. 1 Pedro 2:16","id_categoria":"171","id":"3791"},
        {"descricao_categoria":"Servir","descricao":"Porque o Filho do homem também não veio para ser servido, mas para servir e dar a sua vida em resgate de muitos. Marcos 10:45","id_categoria":"171","id":"3793"},
        {"descricao_categoria":"Servir","descricao":"O Senhor resgata a alma dos seus servos, e nenhum dos que nele confiam será punido. Salmos 34:22","id_categoria":"171","id":"3794"},
        {"descricao_categoria":"Sexo antes do casamento","descricao":"Porque bem sabeis isto: que nenhum devasso, ou impuro, ou avarento, o qual é idólatra, tem herança no reino de Cristo e de Deus. Efésios 5:5","id_categoria":"172","id":"3797"},
        {"descricao_categoria":"Sexo","descricao":"Mas, se não podem conter-se, casem-se. Porque é melhor casar do que abrasar-se. 1 Coríntios 7:9","id_categoria":"173","id":"3801"},
        {"descricao_categoria":"Sexo","descricao":"Não adulterarás. Êxodo 20:14","id_categoria":"173","id":"3804"},
        {"descricao_categoria":"Sexo","descricao":"Mas a fornicação, e toda a impureza ou avareza, nem ainda se nomeie entre vós, como convém a santos; Efésios 5:3","id_categoria":"173","id":"3810"},
        {"descricao_categoria":"Sexo","descricao":"Eu, porém, vos digo, que qualquer que atentar numa mulher para a cobiçar, já em seu coração cometeu adultério com ela. Mateus 5:28","id_categoria":"173","id":"3811"},
        {"descricao_categoria":"Sexo","descricao":"Portanto deixará o homem o seu pai e a sua mãe, e apegar-se-á à sua mulher, e serão ambos uma carne. Gênesis 2:24","id_categoria":"173","id":"3815"},
        {"descricao_categoria":"Sofrimento","descricao":"Quem achar a sua vida perdê-la-á; e quem perder a sua vida, por amor de mim, achá-la-á. Mateus 10:39","id_categoria":"174","id":"3817"},
        {"descricao_categoria":"Sofrimento","descricao":"Porque a vós vos foi concedido, em relação a Cristo, não somente crer nele, como também padecer por ele, Filipenses 1:29","id_categoria":"174","id":"3818"},
        {"descricao_categoria":"Sofrimento","descricao":"Muitas são as aflições do justo, mas o Senhor o livra de todas. Salmos 34:19","id_categoria":"174","id":"3819"},
        {"descricao_categoria":"Sofrimento","descricao":"Eis que bem-aventurado é o homem a quem Deus repreende; não desprezes, pois, a correção do Todo-Poderoso. Jó 5:17","id_categoria":"174","id":"3823"},
        {"descricao_categoria":"Sofrimento","descricao":"Porque, como as aflições de Cristo são abundantes em nós, assim também é abundante a nossa consolação por meio de Cristo. 2 Coríntios 1:5","id_categoria":"174","id":"3824"},
        {"descricao_categoria":"Sofrimento","descricao":"Porque a nossa leve e momentânea tribulação produz para nós um peso eterno de glória mui excelente; 2 Coríntios 4:17","id_categoria":"174","id":"3825"},
        {"descricao_categoria":"Sofrimento","descricao":"Porque para isto sois chamados; pois também Cristo padeceu por nós, deixando-nos o exemplo, para que sigais as suas pisadas. 1 Pedro 2:21","id_categoria":"174","id":"3827"},
        {"descricao_categoria":"Sofrimento","descricao":"Levai as cargas uns dos outros, e assim cumprireis a lei de Cristo. Gálatas 6:2","id_categoria":"174","id":"3830"},
        {"descricao_categoria":"Sofrimento","descricao":"Para conhecê-lo, e à virtude da sua ressurreição, e à comunicação de suas aflições, sendo feito conforme à sua morte; Filipenses 3:10","id_categoria":"174","id":"3831"},
        {"descricao_categoria":"Sofrimento","descricao":"Mas também, se padecerdes por amor da justiça, sois bemaventurados. E não temais com medo deles, nem vos turbeis; 1 Pedro 3:14","id_categoria":"174","id":"3833"},
        {"descricao_categoria":"Sofrimento","descricao":"E quem não toma a sua cruz, e não segue após mim, não é digno de mim. Mateus 10:38","id_categoria":"174","id":"3841"},
        {"descricao_categoria":"Sonhos","descricao":"Quando o SENHOR trouxe do cativeiro os que voltaram a Sião, estávamos como os que sonham. Salmos 126:1","id_categoria":"175","id":"3843"},
        {"descricao_categoria":"Sonhos","descricao":"Porque, da muita ocupação vêm os sonhos, e a voz do tolo da multidão das palavras. Eclesiastes 5:3","id_categoria":"175","id":"3849"},
        {"descricao_categoria":"Sonhos","descricao":"Confia ao Senhor as tuas obras, e teus pensamentos serão estabelecidos. Provérbios 16:3","id_categoria":"175","id":"3851"},
        {"descricao_categoria":"Sonhos","descricao":"Porque, como na multidão dos sonhos há vaidades, assim também nas muitas palavras; mas tu teme a Deus. Eclesiastes 5:7","id_categoria":"175","id":"3854"},
        {"descricao_categoria":"Sonhos","descricao":"Muitos propósitos há no coração do homem, porém o conselho do Senhor permanecerá. Provérbios 19:21","id_categoria":"175","id":"3857"},
        {"descricao_categoria":"Superação","descricao":"E o Senhor me livrará de toda a má obra, e guardar-me-á para o seu reino celestial; a quem seja glória para todo o sempre. Amém. 2 Timóteo 4:18","id_categoria":"176","id":"3860"},
        {"descricao_categoria":"Superação","descricao":"Mas fiel é o Senhor, que vos confirmará, e guardará do maligno. 2 Tessalonicenses 3:3","id_categoria":"176","id":"3861"},
        {"descricao_categoria":"Superação","descricao":"Vinde a mim, todos os que estais cansados e oprimidos, e eu vos aliviarei. Mateus 11:28","id_categoria":"176","id":"3862"},
        {"descricao_categoria":"Superação","descricao":"Tenho-vos dito isto, para que em mim tenhais paz; no mundo tereis aflições, mas tende bom ânimo, eu venci o mundo. João 16:33","id_categoria":"176","id":"3863"},
        {"descricao_categoria":"Superação","descricao":"Não te deixes vencer do mal, mas vence o mal com o bem. Romanos 12:21","id_categoria":"176","id":"3864"},
        {"descricao_categoria":"Superação","descricao":"E eles o venceram pelo sangue do Cordeiro e pela palavra do seu testemunho; e não amaram as suas vidas até à morte. Apocalipse 12:11","id_categoria":"176","id":"3865"},
        {"descricao_categoria":"Superação","descricao":"E disse-lhes: Por que estais dormindo? Levantai-vos, e orai, para que não entreis em tentação. Lucas 22:46","id_categoria":"176","id":"3866"},
        {"descricao_categoria":"Superação","descricao":"Sejam vossos costumes sem avareza, contentando-vos com o que tendes; porque ele disse: Não te deixarei, nem te desampararei. Hebreus 13:5","id_categoria":"176","id":"3867"},
        {"descricao_categoria":"Superação","descricao":"Mas graças a Deus que nos dá a vitória por nosso Senhor Jesus Cristo. 1 Coríntios 15:57","id_categoria":"176","id":"3868"},
        {"descricao_categoria":"Superação","descricao":"Porque para Deus nada é impossível. Lucas 1:37","id_categoria":"176","id":"3869"},
        {"descricao_categoria":"Superação","descricao":"Tu conservarás em paz aquele cuja mente está firme em ti; porque ele confia em ti. Isaías 26:3","id_categoria":"176","id":"3870"},
        {"descricao_categoria":"Superação","descricao":"Filhinhos, sois de Deus, e já os tendes vencido; porque maior é o que está em vós do que o que está no mundo. 1 João 4:4","id_categoria":"176","id":"3871"},
        {"descricao_categoria":"Superação","descricao":"E a luz resplandece nas trevas, e as trevas não a compreenderam. João 1:5","id_categoria":"176","id":"3874"},
        {"descricao_categoria":"Superação","descricao":"Irai-vos, e não pequeis; não se ponha o sol sobre a vossa ira. Não deis lugar ao diabo. Efésios 4:26,27","id_categoria":"176","id":"3876"},
        {"descricao_categoria":"Superação","descricao":"Mas em todas estas coisas somos mais do que vencedores, por aquele que nos amou. Romanos 8:37","id_categoria":"176","id":"3877"},
        {"descricao_categoria":"Superação","descricao":"Ao que vencer lhe concederei que se assente comigo no meu trono; assim como eu venci, e me assentei com meu Pai no seu trono. Apocalipse 3:21","id_categoria":"176","id":"3878"},
        {"descricao_categoria":"Superação","descricao":"Posso todas as coisas em Cristo que me fortalece. Filipenses 4:13","id_categoria":"176","id":"3881"},
        {"descricao_categoria":"Superação","descricao":"E quando chegou àquele lugar, disse-lhes: Orai, para que não entreis em tentação. Lucas 22:40","id_categoria":"176","id":"3882"},
        {"descricao_categoria":"Superação","descricao":"O SENHOR é a minha luz e a minha salvação; a quem temerei? O SENHOR é a força da minha vida; de quem me recearei? Salmos 27:1","id_categoria":"176","id":"3883"},
        {"descricao_categoria":"Superação","descricao":"Porém tu, Senhor, és um escudo para mim, a minha glória, e o que exalta a minha cabeça. Salmos 3:3","id_categoria":"176","id":"3885"},
        {"descricao_categoria":"Superação","descricao":"Porque naquilo que ele mesmo, sendo tentado, padeceu, pode socorrer aos que são tentados. Hebreus 2:18","id_categoria":"176","id":"3889"},
        {"descricao_categoria":"Superação","descricao":"Eis que vos dou poder para pisar serpentes e escorpiões, e toda a força do inimigo, e nada vos fará dano algum. Lucas 10:19","id_categoria":"176","id":"3892"},
        {"descricao_categoria":"Superação","descricao":"Porque todo o que é nascido de Deus vence o mundo; e esta é a vitória que vence o mundo, a nossa fé. 1 João 5:4","id_categoria":"176","id":"3898"},
        {"descricao_categoria":"Superação","descricao":"Pois o Senhor vosso Deus é o que vai convosco, a pelejar contra os vossos inimigos, para salvar-vos. Deuteronômio 20:4","id_categoria":"176","id":"3902"},
        {"descricao_categoria":"Superação","descricao":"Vigiai e orai, para que não entreis em tentação; o espírito, na verdade, está pronto, mas a carne é fraca. Marcos 14:38","id_categoria":"176","id":"3904"},
        {"descricao_categoria":"Superação","descricao":"Sede sóbrios; vigiai; porque o diabo, vosso adversário, anda em derredor, bramando como leão, buscando a quem possa tragar; 1 Pedro 5:8","id_categoria":"176","id":"3905"},
        {"descricao_categoria":"Superação","descricao":"Humilhai-vos perante o Senhor, e ele vos exaltará. Tiago 4:10","id_categoria":"176","id":"3906"},
        {"descricao_categoria":"Superação","descricao":"Quem é que vence o mundo, senão aquele que crê que Jesus é o Filho de Deus? 1 João 5:5","id_categoria":"176","id":"3908"},
        {"descricao_categoria":"Sobre ter filhos","descricao":"Educa a criança no caminho em que deve andar; e até quando envelhecer não se desviará dele. Provérbios 22:6","id_categoria":"177","id":"3911"},
        {"descricao_categoria":"Sobre ter filhos","descricao":"Antes que te formasse no ventre te conheci, e antes que saísses da madre, te santifiquei; às nações te dei por profeta. Jeremias 1:5","id_categoria":"177","id":"3913"},
        {"descricao_categoria":"Sobre ter filhos","descricao":"Castiga o teu filho, e te dará descanso; e dará delícias à tua alma. Provérbios 29:17","id_categoria":"177","id":"3914"},
        {"descricao_categoria":"Sobre ter filhos","descricao":"Jesus, porém, disse: Deixai os meninos, e não os estorveis de vir a mim; porque dos tais é o reino dos céus. Mateus 19:14","id_categoria":"177","id":"3917"},
        {"descricao_categoria":"Sobre ter filhos","descricao":"Tu ordenaste força da boca das crianças e dos que mamam, por causa dos teus inimigos, para fazer calar ao inimigo e ao vingador. Salmos 8:2","id_categoria":"177","id":"3919"},
        {"descricao_categoria":"Sobre ter filhos","descricao":"Vós, pais, não irriteis a vossos filhos, para que não percam o ânimo. Colossenses 3:21","id_categoria":"177","id":"3920"},
        {"descricao_categoria":"Sobre ter filhos","descricao":"Faz com que a mulher estéril habite em casa, e seja alegre mãe de filhos. Louvai ao Senhor. Salmos 113:9","id_categoria":"177","id":"3921"},
        {"descricao_categoria":"Falar","descricao":"Quando não há conselhos os planos se dispersam, mas havendo muitos conselheiros eles se firmam. Provérbios 15:22","id_categoria":"178","id":"3922"},
        {"descricao_categoria":"Falar","descricao":"Na multidão de palavras não falta pecado, mas o que modera os seus lábios é sábio. Provérbios 10:19","id_categoria":"178","id":"3923"},
        {"descricao_categoria":"Falar","descricao":"Ora, se teu irmão pecar contra ti, vai, e repreende-o entre ti e ele só; se te ouvir, ganhaste a teu irmão; Mateus 18:15","id_categoria":"178","id":"3924"},
        {"descricao_categoria":"Falar","descricao":"O mexeriqueiro revela o segredo, mas o fiel de espírito o mantém em oculto. Provérbios 11:13","id_categoria":"178","id":"3925"},
        {"descricao_categoria":"Falar","descricao":"Louvai ao SENHOR, e invocai o seu nome; fazei conhecidas as suas obras entre os povos. Salmos 105:1","id_categoria":"178","id":"3926"},
        {"descricao_categoria":"Falar","descricao":"E, orando, não useis de vãs repetições, como os gentios, que pensam que por muito falarem serão ouvidos. Mateus 6:7","id_categoria":"178","id":"3927"},
        {"descricao_categoria":"Falar","descricao":"A morte e a vida estão no poder da língua; e aquele que a ama comerá do seu fruto. Provérbios 18:21","id_categoria":"178","id":"3928"},
        {"descricao_categoria":"Falar","descricao":"O homem se alegra em responder bem, e quão boa é a palavra dita a seu tempo! Provérbios 15:23","id_categoria":"178","id":"3930"},
        {"descricao_categoria":"Falar","descricao":"Meus filhinhos, não amemos de palavra, nem de língua, mas por obra e em verdade. 1 João 3:18","id_categoria":"178","id":"3931"},
        {"descricao_categoria":"Falar","descricao":"O que guarda a sua boca conserva a sua alma, mas o que abre muito os seus lábios se destrói. Provérbios 13:3","id_categoria":"178","id":"3932"},
        {"descricao_categoria":"Falar","descricao":"Abre a tua boca a favor do mudo, pela causa de todos que são designados à destruição. Provérbios 31:8","id_categoria":"178","id":"3933"},
        {"descricao_categoria":"Falar","descricao":"Porquanto tudo o que em trevas dissestes, à luz será ouvido; e o que falastes ao ouvido no gabinete, sobre os telhados será apregoado. Lucas 12:3","id_categoria":"178","id":"3934"},
        {"descricao_categoria":"Falar","descricao":"A resposta branda desvia o furor, mas a palavra dura suscita a ira. Provérbios 15:1","id_categoria":"178","id":"3935"},
        {"descricao_categoria":"Falar","descricao":"Até o tolo, quando se cala, é reputado por sábio; e o que cerra os seus lábios é tido por entendido. Provérbios 17:28","id_categoria":"178","id":"3937"},
        {"descricao_categoria":"Falar","descricao":"Nas palavras da boca do sábio há favor, porém os lábios do tolo o devoram. Eclesiastes 10:12","id_categoria":"178","id":"3938"},
        {"descricao_categoria":"Falar","descricao":"Mas eu vos digo que de toda a palavra ociosa que os homens disserem hão de dar conta no dia do juízo. Mateus 12:36","id_categoria":"178","id":"3939"},
        {"descricao_categoria":"Falar","descricao":"A saber: Se com a tua boca confessares ao Senhor Jesus, e em teu coração creres que Deus o ressuscitou dentre os mortos, serás salvo. Romanos 10:9","id_categoria":"178","id":"3940"},
        {"descricao_categoria":"Falar","descricao":"A língua benigna é árvore de vida, mas a perversidade nela deprime o espírito. Provérbios 15:4","id_categoria":"178","id":"3941"},
        {"descricao_categoria":"Falar","descricao":"Encha-se a minha boca do teu louvor e da tua glória todo o dia. Salmos 71:8","id_categoria":"178","id":"3944"},
        {"descricao_categoria":"Falar","descricao":"Portanto, meus amados irmãos, todo o homem seja pronto para ouvir, tardio para falar, tardio para se irar. Tiago 1:19","id_categoria":"178","id":"3945"},
        {"descricao_categoria":"Falar","descricao":"Não saia da vossa boca nenhuma palavra torpe, mas só a que for boa para promover a edificação, para que dê graça aos que a ouvem. Efésios 4:29","id_categoria":"178","id":"3946"},
        {"descricao_categoria":"Família de Deus","descricao":"Ora o servo não fica para sempre em casa; o Filho fica para sempre. João 8:35","id_categoria":"179","id":"3955"},
        {"descricao_categoria":"Família de Deus","descricao":"Assim nós, que somos muitos, somos um só corpo em Cristo, mas individualmente somos membros uns dos outros. Romanos 12:5","id_categoria":"179","id":"3956"},
        {"descricao_categoria":"Família de Deus","descricao":"Então, enquanto temos tempo, façamos bem a todos, mas principalmente aos domésticos da fé. Gálatas 6:10","id_categoria":"179","id":"3958"},
        {"descricao_categoria":"Família","descricao":"A coroa dos velhos são os filhos dos filhos; e a glória dos filhos são seus pais. Provérbios 17:6","id_categoria":"180","id":"3965"},
        {"descricao_categoria":"Família","descricao":"Prepara de fora a tua obra, e aparelha-a no campo, e então edifica a tua casa. Provérbios 24:27","id_categoria":"180","id":"3969"},
        {"descricao_categoria":"Família","descricao":"Em todo o tempo ama o amigo e para a hora da angústia nasce o irmão. Provérbios 17:17","id_categoria":"180","id":"3971"},
        {"descricao_categoria":"Família","descricao":"E vós, pais, não provoqueis à ira a vossos filhos, mas criai-os na doutrina e admoestação do Senhor. Efésios 6:4","id_categoria":"180","id":"3972"},
        {"descricao_categoria":"Família","descricao":"Educa a criança no caminho em que deve andar; e até quando envelhecer não se desviará dele. Provérbios 22:6","id_categoria":"180","id":"3973"},
        {"descricao_categoria":"Família","descricao":"Honra teu pai e tua mãe, e amarás o teu próximo como a ti mesmo. Mateus 19:19","id_categoria":"180","id":"3974"},
        {"descricao_categoria":"Família","descricao":"O homem de muitos amigos deve mostrar-se amigável, mas há um amigo mais chegado do que um irmão. Provérbios 18:24","id_categoria":"180","id":"3975"},
        {"descricao_categoria":"Família","descricao":"Provérbios de Salomão: O filho sábio alegra a seu pai, mas o filho insensato é a tristeza de sua mãe. Provérbios 10:1","id_categoria":"180","id":"3976"},
        {"descricao_categoria":"Família","descricao":"Honra a teu pai e a tua mãe, para que se prolonguem os teus dias na terra que o Senhor teu Deus te dá. Êxodo 20:12","id_categoria":"180","id":"3977"},
        {"descricao_categoria":"Família","descricao":"Antes sede uns para com os outros benignos, misericordiosos, perdoando-vos uns aos outros, como também Deus vos perdoou em Cristo. Efésios 4:32","id_categoria":"180","id":"3980"},
        {"descricao_categoria":"Família","descricao":"O que agir com avareza perturba a sua casa, mas o que odeia presentes viverá. Provérbios 15:27","id_categoria":"180","id":"3984"},
        {"descricao_categoria":"Família","descricao":"Filho meu, guarda o mandamento de teu pai, e não deixes a lei da tua mãe; Provérbios 6:20","id_categoria":"180","id":"3985"},
        {"descricao_categoria":"Família","descricao":"Portanto deixará o homem o seu pai e a sua mãe, e apegar-se-á à sua mulher, e serão ambos uma carne. Gênesis 2:24","id_categoria":"180","id":"3988"},
        {"descricao_categoria":"Família","descricao":"Mas, se alguém não tem cuidado dos seus, e principalmente dos da sua família, negou a fé, e é pior do que o infiel. 1 Timóteo 5:8","id_categoria":"180","id":"3989"},
        {"descricao_categoria":"Família","descricao":"Filho meu, se o teu coração for sábio, alegrar-se-á o meu coração, sim, o meu próprio. Provérbios 23:15","id_categoria":"180","id":"3990"},
        {"descricao_categoria":"Família","descricao":"E eles disseram: Crê no Senhor Jesus Cristo e serás salvo, tu e a tua casa. Atos 16:31","id_categoria":"180","id":"3991"},
        {"descricao_categoria":"Família","descricao":"O Senhor nosso Deus seja conosco, como foi com nossos pais; não nos desampare, e não nos deixe. 1 Reis 8:57","id_categoria":"180","id":"3993"},
        {"descricao_categoria":"Família","descricao":"O que perturba a sua casa herdará o vento, e o tolo será servo do sábio de coração. Provérbios 11:29","id_categoria":"180","id":"3994"},
        {"descricao_categoria":"Família","descricao":"Grandemente se regozijará o pai do justo, e o que gerar um sábio, se alegrará nele. Provérbios 23:24","id_categoria":"180","id":"3996"},
        {"descricao_categoria":"Família","descricao":"Oh! quão bom e quão suave é que os irmãos vivam em união. Salmos 133:1","id_categoria":"180","id":"3998"},
        {"descricao_categoria":"Família","descricao":"Assim também vós, cada um em particular, ame a sua própria mulher como a si mesmo, e a mulher reverencie o marido. Efésios 5:33","id_categoria":"180","id":"3999"},
        {"descricao_categoria":"Fé","descricao":"Porque, assim como o corpo sem o espírito está morto, assim também a fé sem obras é morta. Tiago 2:26","id_categoria":"181","id":"4001"},
        {"descricao_categoria":"Fé","descricao":"Sabendo que a prova da vossa fé opera a paciência. Tiago 1:3","id_categoria":"181","id":"4002"},
        {"descricao_categoria":"Fé","descricao":"E Jesus disse-lhe: Se tu podes crer, tudo é possível ao que crê. Marcos 9:23","id_categoria":"181","id":"4003"},
        {"descricao_categoria":"Fé","descricao":"Se formos infiéis, ele permanece fiel; não pode negar-se a si mesmo. 2 Timóteo 2:13","id_categoria":"181","id":"4007"},
        {"descricao_categoria":"Fé","descricao":"Mas o justo viverá pela fé; E, se ele recuar, a minha alma não tem prazer nele. Hebreus 10:38","id_categoria":"181","id":"4008"},
        {"descricao_categoria":"Fé","descricao":"Porque todo o que é nascido de Deus vence o mundo; e esta é a vitória que vence o mundo, a nossa fé. 1 João 5:4","id_categoria":"181","id":"4009"},
        {"descricao_categoria":"Fé","descricao":"E Jesus lhes disse: Eu sou o pão da vida; aquele que vem a mim não terá fome, e quem crê em mim nunca terá sede. João 6:35","id_categoria":"181","id":"4010"},
        {"descricao_categoria":"Fé","descricao":"Combati o bom combate, acabei a carreira, guardei a fé. 2 Timóteo 4:7","id_categoria":"181","id":"4011"},
        {"descricao_categoria":"Fé","descricao":"Visto que com o coração se crê para a justiça, e com a boca se faz confissão para a salvação. Romanos 10:10","id_categoria":"181","id":"4012"},
        {"descricao_categoria":"Fé","descricao":"Porque nele se descobre a justiça de Deus de fé em fé, como está escrito: Mas o justo viverá pela fé. Romanos 1:17","id_categoria":"181","id":"4013"},
        {"descricao_categoria":"Fé","descricao":"Escolhi o caminho da verdade; propus-me seguir os teus juízos. Salmos 119:30","id_categoria":"181","id":"4014"},
        {"descricao_categoria":"Fé","descricao":"Mas tu, ó homem de Deus, foge destas coisas, e segue a justiça, a piedade, a fé, o amor, a paciência, a mansidão. 1 Timóteo 6:11","id_categoria":"181","id":"4015"},
        {"descricao_categoria":"Fé","descricao":"Por isso vos digo que todas as coisas que pedirdes, orando, crede receber, e tê-las-eis. Marcos 11:24","id_categoria":"181","id":"4016"},
        {"descricao_categoria":"Fé","descricao":"Aquele que crê no Filho tem a vida eterna; mas aquele que não crê no Filho não verá a vida, mas a ira de Deus sobre ele permanece. João 3:36","id_categoria":"181","id":"4017"},
        {"descricao_categoria":"Fé","descricao":"Ainda que ele me mate, nele esperarei; contudo os meus caminhos defenderei diante dele. Jó 13:15","id_categoria":"181","id":"4018"},
        {"descricao_categoria":"Fé","descricao":"(Porque andamos por fé, e não por vista). 2 Coríntios 5:7","id_categoria":"181","id":"4021"},
        {"descricao_categoria":"Fé","descricao":"Ora, a fé é o firme fundamento das coisas que se esperam, e a prova das coisas que se não vêem. Hebreus 11:1","id_categoria":"181","id":"4022"},
        {"descricao_categoria":"Fé","descricao":"Tomando sobretudo o escudo da fé, com o qual podereis apagar todos os dardos inflamados do maligno. Efésios 6:16","id_categoria":"181","id":"4023"},
        {"descricao_categoria":"Fé","descricao":"Disse-lhe Jesus: Não te hei dito que, se creres, verás a glória de Deus? João 11:40","id_categoria":"181","id":"4025"},
        {"descricao_categoria":"Fé","descricao":"Ora o Deus de esperança vos encha de todo o gozo e paz em crença, para que abundeis em esperança pela virtude do Espírito Santo. Romanos 15:13","id_categoria":"181","id":"4026"},
        {"descricao_categoria":"Fé","descricao":"Porque nós pelo Espírito da fé aguardamos a esperança da justiça. Gálatas 5:5","id_categoria":"181","id":"4028"},
        {"descricao_categoria":"Fé","descricao":"Porque para Deus nada é impossível. Lucas 1:37","id_categoria":"181","id":"4029"},
        {"descricao_categoria":"Fé","descricao":"Alcançando o fim da vossa fé, a salvação das vossas almas. 1 Pedro 1:9","id_categoria":"181","id":"4033"},
        {"descricao_categoria":"Fé","descricao":"De sorte que a fé é pelo ouvir, e o ouvir pela palavra de Deus. Romanos 10:17","id_categoria":"181","id":"4034"},
        {"descricao_categoria":"Fé","descricao":"Por esta razão, irmãos, ficamos consolados acerca de vós, em toda a nossa aflição e necessidade, pela vossa fé, 1 Tessalonicenses 3:7","id_categoria":"181","id":"4035"},
        {"descricao_categoria":"Fé","descricao":"E, tudo o que pedirdes em oração, crendo, o recebereis. Mateus 21:22","id_categoria":"181","id":"4036"},
        {"descricao_categoria":"Fé","descricao":"Para que a vossa fé não se apoiasse em sabedoria dos homens, mas no poder de Deus. 1 Coríntios 2:5","id_categoria":"181","id":"4037"},
        {"descricao_categoria":"Fé","descricao":"E Jesus, respondendo, disse-lhes: Tende fé em Deus; Marcos 11:22","id_categoria":"181","id":"4038"},
        {"descricao_categoria":"Fé","descricao":"Vigiai, estai firmes na fé; portai-vos varonilmente, e fortalecei-vos. 1 Coríntios 16:13","id_categoria":"181","id":"4040"},
        {"descricao_categoria":"Fé","descricao":"Mas, àquele que não pratica, mas crê naquele que justifica o ímpio, a sua fé lhe é imputada como justiça. Romanos 4:5","id_categoria":"181","id":"4045"},
        {"descricao_categoria":"Fé","descricao":"E Jesus lhe disse: Vai, a tua fé te salvou. E logo viu, e seguiu a Jesus pelo caminho. Marcos 10:52","id_categoria":"181","id":"4046"},
        {"descricao_categoria":"Fé","descricao":"Tocou então os olhos deles, dizendo: Seja-vos feito segundo a vossa fé. Mateus 9:29","id_categoria":"181","id":"4047"},
        {"descricao_categoria":"Fé","descricao":"E Jesus, vendo a fé deles, disse ao paralítico: Filho, tem bom ânimo, perdoados te são os teus pecados. Mateus 9:2","id_categoria":"181","id":"4048"},
        {"descricao_categoria":"Fé","descricao":"Mas aquele que tem dúvidas, se come está condenado, porque não come por fé; e tudo o que não é de fé é pecado. Romanos 14:23","id_categoria":"181","id":"4049"},
        {"descricao_categoria":"Fé","descricao":"Ao qual resisti firmes na fé, sabendo que as mesmas aflições se cumprem entre os vossos irmãos no mundo. 1 Pedro 5:9","id_categoria":"181","id":"4051"},
        {"descricao_categoria":"Fé","descricao":"Tendo sido, pois, justificados pela fé, temos paz com Deus, por nosso Senhor Jesus Cristo; Romanos 5:1","id_categoria":"181","id":"4056"},
        {"descricao_categoria":"Fé","descricao":"Em esperança da vida eterna, a qual Deus, que não pode mentir, prometeu antes dos tempos dos séculos; Tito 1:2","id_categoria":"181","id":"4057"},
        {"descricao_categoria":"Fé","descricao":"Ora, quanto ao que está enfermo na fé, recebei-o, não em contendas sobre dúvidas. Romanos 14:1","id_categoria":"181","id":"4058"},
        {"descricao_categoria":"Filho de Deus","descricao":"Quem tem o Filho tem a vida; quem não tem o Filho de Deus não tem a vida. 1 João 5:12","id_categoria":"182","id":"4061"},
        {"descricao_categoria":"Filho de Deus","descricao":"Qualquer que confessar que Jesus é o Filho de Deus, Deus está nele, e ele em Deus. 1 João 4:15","id_categoria":"182","id":"4066"},
        {"descricao_categoria":"Filho de Deus","descricao":"E o Verbo se fez carne, e habitou entre nós, e vimos a sua glória, como a glória do unigênito do Pai, cheio de graça e de verdade. João 1:14","id_categoria":"182","id":"4070"},
        {"descricao_categoria":"Filho de Deus","descricao":"Deus nunca foi visto por alguém. O Filho unigênito, que está no seio do Pai, esse o revelou. João 1:18","id_categoria":"182","id":"4073"},
        {"descricao_categoria":"Filho do Homem","descricao":"Ora, achando-se eles na Galiléia, disse-lhes Jesus: O Filho do homem será entregue nas mãos dos homens; Mateus 17:22","id_categoria":"183","id":"4079"},
        {"descricao_categoria":"Filho do Homem","descricao":"E ele, respondendo, disse-lhes: O que semeia a boa semente, é o Filho do homem; Mateus 13:37","id_categoria":"183","id":"4081"},
        {"descricao_categoria":"Filho do Homem","descricao":"Mas em certo lugar testificou alguém, dizendo:Que é o homem, para que dele te lembres?Ou o filho do homem, para que o visites? Hebreus 2:6","id_categoria":"183","id":"4082"},
        {"descricao_categoria":"Filho do Homem","descricao":"E disse: Eis que vejo os céus abertos, e o Filho do homem, que está em pé à mão direita de Deus. Atos 7:56","id_categoria":"183","id":"4084"},
        {"descricao_categoria":"Filho do Homem","descricao":"Por isso, estai vós apercebidos também; porque o Filho do homem há de vir à hora em que não penseis. Mateus 24:44","id_categoria":"183","id":"4085"},
        {"descricao_categoria":"Filho do Homem","descricao":"Porque o Filho do homem também não veio para ser servido, mas para servir e dar a sua vida em resgate de muitos. Marcos 10:45","id_categoria":"183","id":"4086"},
        {"descricao_categoria":"Filho do Homem","descricao":"E quando o Filho do homem vier em sua glória, e todos os santos anjos com ele, então se assentará no trono da sua glória; Mateus 25:31","id_categoria":"183","id":"4087"},
        {"descricao_categoria":"Filho do Homem","descricao":"Porque o Filho do homem veio salvar o que se tinha perdido. Mateus 18:11","id_categoria":"183","id":"4090"},
        {"descricao_categoria":"Filho do Homem","descricao":"E disse-lhe Jesus: As raposas têm covis, e as aves do céu, ninhos, mas o Filho do homem não tem onde reclinar a cabeça. Lucas 9:58","id_categoria":"183","id":"4092"},
        {"descricao_categoria":"Filhos de Deus","descricao":"Estes, porém, foram escritos para que creiais que Jesus é o Cristo, o Filho de Deus, e para que, crendo, tenhais vida em seu nome. João 20:31","id_categoria":"184","id":"4095"},
        {"descricao_categoria":"Filhos de Deus","descricao":"Bem-aventurados os pacificadores, porque eles serão chamados filhos de Deus; Mateus 5:9","id_categoria":"184","id":"4098"},
        {"descricao_categoria":"Filhos de Deus","descricao":"O mesmo Espírito testifica com o nosso espírito que somos filhos de Deus. Romanos 8:16","id_categoria":"184","id":"4102"},
        {"descricao_categoria":"Filhos de Deus","descricao":"Assim que já não és mais servo, mas filho; e, se és filho, és também herdeiro de Deus por Cristo. Gálatas 4:7","id_categoria":"184","id":"4104"},
        {"descricao_categoria":"Filhos de Deus","descricao":"Isto é, não são os filhos da carne que são filhos de Deus, mas os filhos da promessa são contados como descendência. Romanos 9:8","id_categoria":"184","id":"4108"},
        {"descricao_categoria":"Filhos","descricao":"E crescia Jesus em sabedoria, e em estatura, e em graça para com Deus e os homens. Lucas 2:52","id_categoria":"185","id":"4117"},
        {"descricao_categoria":"Filhos","descricao":"O que não faz uso da vara odeia seu filho, mas o que o ama, desde cedo o castiga. Provérbios 13:24","id_categoria":"185","id":"4118"},
        {"descricao_categoria":"Filhos","descricao":"Educa a criança no caminho em que deve andar; e até quando envelhecer não se desviará dele. Provérbios 22:6","id_categoria":"185","id":"4121"},
        {"descricao_categoria":"Filhos","descricao":"A estultícia está ligada ao coração da criança, mas a vara da correção a afugentará dela. Provérbios 22:15","id_categoria":"185","id":"4123"},
        {"descricao_categoria":"Filhos","descricao":"Ouvi, filhos, a instrução do pai, e estai atentos para conhecerdes a prudência. Provérbios 4:1","id_categoria":"185","id":"4125"},
        {"descricao_categoria":"Filhos","descricao":"Bendito o fruto do teu ventre, e o fruto da tua terra, e o fruto dos teus animais; e as crias das tuas vacas e das tuas ovelhas. Deuteronômio 28:4","id_categoria":"185","id":"4126"},
        {"descricao_categoria":"Filhos","descricao":"O justo anda na sua sinceridade; bem-aventurados serão os seus filhos depois dele. Provérbios 20:7","id_categoria":"185","id":"4130"},
        {"descricao_categoria":"Filhos","descricao":"E todos os teus filhos serão ensinados do Senhor; e a paz de teus filhos será abundante. Isaías 54:13","id_categoria":"185","id":"4131"},
        {"descricao_categoria":"Filhos","descricao":"Assim como um pai se compadece de seus filhos, assim o Senhor se compadece daqueles que o temem. Salmos 103:13","id_categoria":"185","id":"4134"},
        {"descricao_categoria":"Filhos","descricao":"A coroa dos velhos são os filhos dos filhos; e a glória dos filhos são seus pais. Provérbios 17:6","id_categoria":"185","id":"4136"},
        {"descricao_categoria":"Filhos","descricao":"A vara e a repreensão dão sabedoria, mas a criança entregue a si mesma, envergonha a sua mãe. Provérbios 29:15","id_categoria":"185","id":"4137"},
        {"descricao_categoria":"Filhos","descricao":"Antes que te formasse no ventre te conheci, e antes que saísses da madre, te santifiquei; às nações te dei por profeta. Jeremias 1:5","id_categoria":"185","id":"4139"},
        {"descricao_categoria":"Filhos","descricao":"Honra a teu pai e a tua mãe, para que se prolonguem os teus dias na terra que o Senhor teu Deus te dá. Êxodo 20:12","id_categoria":"185","id":"4141"},
        {"descricao_categoria":"Filhos","descricao":"Provérbios de Salomão: O filho sábio alegra a seu pai, mas o filho insensato é a tristeza de sua mãe. Provérbios 10:1","id_categoria":"185","id":"4143"},
        {"descricao_categoria":"Filhos","descricao":"Sobre ti fui lançado desde a madre; tu és o meu Deus desde o ventre de minha mãe. Salmos 22:10","id_categoria":"185","id":"4144"},
        {"descricao_categoria":"Filhos","descricao":"Vós, pais, não irriteis a vossos filhos, para que não percam o ânimo. Colossenses 3:21","id_categoria":"185","id":"4145"},
        {"descricao_categoria":"Filhos","descricao":"E vós, pais, não provoqueis à ira a vossos filhos, mas criai-os na doutrina e admoestação do Senhor. Efésios 6:4","id_categoria":"185","id":"4146"},
        {"descricao_categoria":"Filhos","descricao":"Vós, filhos, obedecei em tudo a vossos pais, porque isto é agradável ao Senhor. Colossenses 3:20","id_categoria":"185","id":"4147"},
        {"descricao_categoria":"Filhos","descricao":"Não retires a disciplina da criança; pois se a fustigares com a vara, nem por isso morrerá. Provérbios 23:13","id_categoria":"185","id":"4148"},
        {"descricao_categoria":"Filhos","descricao":"Bendito serás mais do que todos os povos; não haverá estéril entre ti, seja homem, seja mulher, nem entre os teus animais. Deuteronômio 7:14","id_categoria":"185","id":"4150"},
        {"descricao_categoria":"Filhos","descricao":"A sua semente será poderosa na terra; a geração dos retos será abençoada. Salmos 112:2","id_categoria":"185","id":"4152"},
        {"descricao_categoria":"Fim do mundo","descricao":"O céu e a terra passarão, mas as minhas palavras não hão de passar. Mateus 24:35","id_categoria":"186","id":"4153"},
        {"descricao_categoria":"Fim do mundo","descricao":"Eis que vem o dia do Senhor, horrendo, com furor e ira ardente, para pôr a terra em assolação, e dela destruir os pecadores. Isaías 13:9","id_categoria":"186","id":"4159"},
        {"descricao_categoria":"Fim do mundo","descricao":"Mas daquele dia e hora ninguém sabe, nem os anjos do céu, mas unicamente meu Pai. Mateus 24:36","id_categoria":"186","id":"4165"},
        {"descricao_categoria":"Fim do mundo","descricao":"Mas daquele dia e hora ninguém sabe, nem os anjos que estão no céu, nem o Filho, senão o Pai. Marcos 13:32","id_categoria":"186","id":"4167"},
        {"descricao_categoria":"Fofoca","descricao":"O hipócrita com a boca destrói o seu próximo, mas os justos se libertam pelo conhecimento. Provérbios 11:9","id_categoria":"187","id":"4169"},
        {"descricao_categoria":"Fofoca","descricao":"O homem perverso instiga a contenda, e o intrigante separa os maiores amigos. Provérbios 16:28","id_categoria":"187","id":"4170"},
        {"descricao_categoria":"Fofoca","descricao":"Não saia da vossa boca nenhuma palavra torpe, mas só a que for boa para promover a edificação, para que dê graça aos que a ouvem. Efésios 4:29","id_categoria":"187","id":"4171"},
        {"descricao_categoria":"Força","descricao":"Espera no Senhor, anima-te, e ele fortalecerá o teu coração; espera, pois, no Senhor. Salmos 27:14","id_categoria":"188","id":"4223"},
        {"descricao_categoria":"Força","descricao":"Mas a salvação dos justos vem do Senhor; ele é a sua fortaleza no tempo da angústia. Salmos 37:39","id_categoria":"188","id":"4224"},
        {"descricao_categoria":"Força","descricao":"Vigiai, estai firmes na fé; portai-vos varonilmente, e fortalecei-vos. 1 Coríntios 16:13","id_categoria":"188","id":"4229"},
        {"descricao_categoria":"Força","descricao":"No demais, irmãos meus, fortalecei-vos no Senhor e na força do seu poder. Efésios 6:10","id_categoria":"188","id":"4230"},
        {"descricao_categoria":"Força","descricao":"Perto está o Senhor dos que têm o coração quebrantado, e salva os contritos de espírito. Salmos 34:18","id_categoria":"188","id":"4231"},
        {"descricao_categoria":"Fraqueza","descricao":"Posso todas as coisas em Cristo que me fortalece. Filipenses 4:13","id_categoria":"189","id":"4258"},
        {"descricao_categoria":"Fraqueza","descricao":"Vinde a mim, todos os que estais cansados e oprimidos, e eu vos aliviarei. Mateus 11:28","id_categoria":"189","id":"4261"},
        {"descricao_categoria":"Fraqueza","descricao":"Dá força ao cansado, e multiplica as forças ao que não tem nenhum vigor. Isaías 40:29","id_categoria":"189","id":"4263"},
        {"descricao_categoria":"Fraqueza","descricao":"Vigiai e orai, para que não entreis em tentação; na verdade, o espírito está pronto, mas a carne é fraca. Mateus 26:41","id_categoria":"189","id":"4264"},
        {"descricao_categoria":"Fraqueza","descricao":"Porque Deus não nos deu o espírito de temor, mas de fortaleza, e de amor, e de moderação. 2 Timóteo 1:7","id_categoria":"189","id":"4265"},
        {"descricao_categoria":"Fraqueza","descricao":"Abre a tua boca a favor do mudo, pela causa de todos que são designados à destruição. Provérbios 31:8","id_categoria":"189","id":"4266"},
        {"descricao_categoria":"Frutos do Espírito","descricao":"Dizei ao justo que bem lhe irá; porque comerão do fruto das suas obras. Isaías 3:10","id_categoria":"190","id":"4270"},
        {"descricao_categoria":"Frutos do Espírito","descricao":"Nisto é glorificado meu Pai, que deis muito fruto; e assim sereis meus discípulos. João 15:8","id_categoria":"190","id":"4272"},
        {"descricao_categoria":"Gentileza","descricao":"Permaneça o amor fraternal. Não vos esqueçais da hospitalidade, porque por ela alguns, não o sabendo, hospedaram anjos. Hebreus 13:1,2","id_categoria":"193","id":"4321"},
        {"descricao_categoria":"Gentileza","descricao":"Assim que não nos julguemos mais uns aos outros; antes seja o vosso propósito não pôr tropeço ou escândalo ao irmão. Romanos 14:13","id_categoria":"193","id":"4322"},
        {"descricao_categoria":"Gentileza","descricao":"A resposta branda desvia o furor, mas a palavra dura suscita a ira. Provérbios 15:1","id_categoria":"193","id":"4324"},
        {"descricao_categoria":"Gentileza","descricao":"As palavras suaves são favos de mel, doces para a alma, e saúde para os ossos. Provérbios 16:24","id_categoria":"193","id":"4325"},
        {"descricao_categoria":"Gentileza","descricao":"Então, enquanto temos tempo, façamos bem a todos, mas principalmente aos domésticos da fé. Gálatas 6:10","id_categoria":"193","id":"4327"},
        {"descricao_categoria":"Gentileza","descricao":"Na verdade, na verdade vos digo: Se alguém receber o que eu enviar, me recebe a mim, e quem me recebe a mim, recebe aquele que me enviou. João 13:20","id_categoria":"193","id":"4328"},
        {"descricao_categoria":"Graça","descricao":"Das profundezas a ti clamo, ó SENHOR. Senhor, escuta a minha voz; sejam os teus ouvidos atentos à voz das minhas súplicas. Salmos 130:1,2","id_categoria":"194","id":"4329"},
        {"descricao_categoria":"Graça","descricao":"A graça de nosso Senhor Jesus Cristo seja com o vosso espírito. Amém. Filemom 1:25","id_categoria":"194","id":"4332"},
        {"descricao_categoria":"Graça","descricao":"Certamente que a bondade e a misericórdia me seguirão todos os dias da minha vida; e habitarei na casa do Senhor por longos dias. Salmos 23:6","id_categoria":"194","id":"4333"},
        {"descricao_categoria":"Graça","descricao":"Porque o pecado não terá domínio sobre vós, pois não estais debaixo da lei, mas debaixo da graça. Romanos 6:14","id_categoria":"194","id":"4335"},
        {"descricao_categoria":"Graça","descricao":"Para que, assim como o pecado reinou na morte, também a graça reinasse pela justiça para a vida eterna, por Jesus Cristo nosso Senhor. Romanos 5:21","id_categoria":"194","id":"4338"},
        {"descricao_categoria":"Graça","descricao":"Misericordioso e piedoso é o Senhor; longânimo e grande em benignidade. Salmos 103:8","id_categoria":"194","id":"4339"},
        {"descricao_categoria":"Gratidão","descricao":"Bendize, ó minha alma, ao Senhor, e não te esqueças de nenhum de seus benefícios. Salmos 103:2","id_categoria":"197","id":"4405"},
        {"descricao_categoria":"Gratidão","descricao":"Dando sempre graças por tudo a nosso Deus e Pai, em nome de nosso Senhor Jesus Cristo; Efésios 5:20","id_categoria":"197","id":"4406"},
        {"descricao_categoria":"Gratidão","descricao":"Para que em tudo enriqueçais para toda a beneficência, a qual faz que por nós se dêem graças a Deus. 2 Coríntios 9:11","id_categoria":"197","id":"4407"},
        {"descricao_categoria":"Gratidão","descricao":"Apresentemo-nos ante a sua face com louvores, e celebremo-lo com salmos. Salmos 95:2","id_categoria":"197","id":"4408"},
        {"descricao_categoria":"Gratidão","descricao":"Sempre e em todo o lugar, ó potentíssimo Félix, com todo o agradecimento o queremos reconhecer. Atos 24:3","id_categoria":"197","id":"4409"},
        {"descricao_categoria":"Gratidão","descricao":"Louvai ao Senhor, invocai o seu nome, fazei conhecidas as suas obras entre os povos. 1 Crônicas 16:8","id_categoria":"197","id":"4435"},
        {"descricao_categoria":"Guardar o Coração","descricao":"Portanto, se já ressuscitastes com Cristo, buscai as coisas que são de cima, onde Cristo está assentado à destra de Deus. Colossenses 3:1","id_categoria":"198","id":"4436"},
        {"descricao_categoria":"Guardar o Coração","descricao":"Sobre tudo o que se deve guardar, guarda o teu coração, porque dele procedem as fontes da vida. Provérbios 4:23","id_categoria":"198","id":"4437"},
        {"descricao_categoria":"Guardar o Coração","descricao":"Dá-me, filho meu, o teu coração, e os teus olhos observem os meus caminhos. Provérbios 23:26","id_categoria":"198","id":"4438"},
        {"descricao_categoria":"Guardar o Coração","descricao":"Chegai-vos a Deus, e ele se chegará a vós. Alimpai as mãos, pecadores; e, vós de duplo ânimo, purificai os corações. Tiago 4:8","id_categoria":"198","id":"4440"},
        {"descricao_categoria":"Guardar o Coração","descricao":"Preparado está o meu coração, ó Deus, preparado está o meu coração; cantarei, e darei louvores. Salmos 57:7","id_categoria":"198","id":"4443"},
        {"descricao_categoria":"Guardar o Coração","descricao":"Bem-aventurados os limpos de coração, porque eles verão a Deus; Mateus 5:8","id_categoria":"198","id":"4446"},
        {"descricao_categoria":"Guardar o Coração","descricao":"Sede vós também pacientes, fortalecei os vossos corações; porque já a vinda do Senhor está próxima. Tiago 5:8","id_categoria":"198","id":"4448"},
        {"descricao_categoria":"Guardar o Coração","descricao":"Cria em mim, ó Deus, um coração puro, e renova em mim um espírito reto. Salmos 51:10","id_categoria":"198","id":"4452"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"Posso todas as coisas em Cristo que me fortalece. Filipenses 4:13","id_categoria":"199","id":"4455"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"Inclina para mim os teus ouvidos, livra-me depressa; sê a minha firme rocha, uma casa fortíssima que me salve. Salmos 31:2","id_categoria":"199","id":"4456"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"Bendito serás mais do que todos os povos; não haverá estéril entre ti, seja homem, seja mulher, nem entre os teus animais. Deuteronômio 7:14","id_categoria":"199","id":"4457"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"Desde o fim da terra clamarei a ti, quando o meu coração estiver desmaiado; leva-me para a rocha que é mais alta do que eu. Salmos 61:2","id_categoria":"199","id":"4459"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"A sua semente será poderosa na terra; a geração dos retos será abençoada. Salmos 112:2","id_categoria":"199","id":"4460"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"Salvar-se-á, porém, dando à luz filhos, se permanecer com modéstia na fé, no amor e na santificação. 1 Timóteo 2:15","id_categoria":"199","id":"4462"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"Digna-te, Senhor, livrar-me: Senhor, apressa-te em meu auxílio. Salmos 40:13","id_categoria":"199","id":"4463"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"Faz com que a mulher estéril habite em casa, e seja alegre mãe de filhos. Louvai ao Senhor. Salmos 113:9","id_categoria":"199","id":"4464"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"Aquele que me formou no ventre não o fez também a ele? Ou não nos formou do mesmo modo na madre? Jó 31:15","id_categoria":"199","id":"4465"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"E invoca-me no dia da angústia; eu te livrarei, e tu me glorificarás. Salmos 50:15","id_categoria":"199","id":"4468"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"Porque fortaleceu os ferrolhos das tuas portas; abençoa aos teus filhos dentro de ti. Salmos 147:13","id_categoria":"199","id":"4469"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"Pois possuíste os meus rins; cobriste-me no ventre de minha mãe. Salmos 139:13","id_categoria":"199","id":"4470"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"Porquanto tão encarecidamente me amou, também eu o livrarei; pô-lo-ei em retiro alto, porque conheceu o meu nome. Salmos 91:14","id_categoria":"199","id":"4471"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"Bem-aventurada a que creu, pois hão de cumprir-se as coisas que da parte do Senhor lhe foram ditas. Lucas 1:45","id_categoria":"199","id":"4473"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"Ainda que junte as mãos, o mau não ficará impune, mas a semente dos justos será liberada. Provérbios 11:21","id_categoria":"199","id":"4474"},
        {"descricao_categoria":"Gravidez e Maternidade","descricao":"Antes que te formasse no ventre te conheci, e antes que saísses da madre, te santifiquei; às nações te dei por profeta. Jeremias 1:5","id_categoria":"199","id":"4475"},
        {"descricao_categoria":"Homem de Deus","descricao":"Ora, nós sabemos que Deus não ouve a pecadores; mas, se alguém é temente a Deus, e faz a sua vontade, a esse ouve. João 9:31","id_categoria":"200","id":"4492"},
        {"descricao_categoria":"Homem de Deus","descricao":"Estas são as gerações de Noé. Noé era homem justo e perfeito em suas gerações; Noé andava com Deus. Gênesis 6:9","id_categoria":"200","id":"4493"},
        {"descricao_categoria":"Homem de Deus","descricao":"No rosto do entendido se vê a sabedoria, mas os olhos do tolo vagam pelas extremidades da terra. Provérbios 17:24","id_categoria":"200","id":"4497"},
        {"descricao_categoria":"Homem de Deus","descricao":"Havia um homem na terra de Uz, cujo nome era Jó; e era este homem íntegro, reto e temente a Deus e desviava-se do mal. Jó 1:1","id_categoria":"200","id":"4499"},
        {"descricao_categoria":"Homem","descricao":"Assim diz o Senhor: Maldito o homem que confia no homem, e faz da carne o seu braço, e aparta o seu coração do Senhor! Jeremias 17:5","id_categoria":"201","id":"4500"},
        {"descricao_categoria":"Homem","descricao":"O homem, nascido da mulher, é de poucos dias e farto de inquietação. Jó 14:1","id_categoria":"201","id":"4501"},
        {"descricao_categoria":"Homem","descricao":"NÃO repreendas asperamente o ancião, mas admoesta-o como a pai; aos moços como a irmãos; 1 Timóteo 5:1","id_categoria":"201","id":"4502"},
        {"descricao_categoria":"Homem","descricao":"Que é o homem, para que tanto o engrandeças, e ponhas nele o teu coração, Jó 7:17","id_categoria":"201","id":"4503"},
        {"descricao_categoria":"Homem","descricao":"Mas em certo lugar testificou alguém, dizendo:Que é o homem, para que dele te lembres?Ou o filho do homem, para que o visites? Hebreus 2:6","id_categoria":"201","id":"4506"},
        {"descricao_categoria":"Homem","descricao":"Não pense tal homem que receberá do Senhor alguma coisa. O homem de coração dobre é inconstante em todos os seus caminhos. Tiago 1:7,8","id_categoria":"201","id":"4507"},
        {"descricao_categoria":"Honestidade","descricao":"Os tesouros da impiedade de nada aproveitam; mas a justiça livra da morte. Provérbios 10:2","id_categoria":"202","id":"4524"},
        {"descricao_categoria":"Honestidade","descricao":"Procura apresentar-te a Deus aprovado, como obreiro que não tem de que se envergonhar, que maneja bem a palavra da verdade. 2 Timóteo 2:15","id_categoria":"202","id":"4527"},
        {"descricao_categoria":"Honestidade","descricao":"A ninguém torneis mal por mal; procurai as coisas honestas, perante todos os homens. Romanos 12:17","id_categoria":"202","id":"4538"},
        {"descricao_categoria":"Honestidade","descricao":"Então entenderás a justiça, o juízo, a eqüidade e todas as boas veredas. Provérbios 2:9","id_categoria":"202","id":"4539"},
        {"descricao_categoria":"Honestidade","descricao":"O homem bom se compadece, e empresta; disporá as suas coisas com juízo; Salmos 112:5","id_categoria":"202","id":"4541"},
        {"descricao_categoria":"Honestidade","descricao":"Bem-aventurados os limpos de coração, porque eles verão a Deus; Mateus 5:8","id_categoria":"202","id":"4543"},
        {"descricao_categoria":"Honestidade","descricao":"Os teus olhos olhem para a frente, e as tuas pálpebras olhem direto diante de ti. Provérbios 4:25","id_categoria":"202","id":"4544"},
        {"descricao_categoria":"Honrar os Pais","descricao":"E quem amaldiçoar a seu pai ou a sua mãe, certamente será morto. Êxodo 21:17","id_categoria":"203","id":"4545"},
        {"descricao_categoria":"Honrar os Pais","descricao":"Vós, filhos, obedecei em tudo a vossos pais, porque isto é agradável ao Senhor. Colossenses 3:20","id_categoria":"203","id":"4546"},
        {"descricao_categoria":"Honrar os Pais","descricao":"Diante das cãs te levantarás, e honrarás a face do ancião; e temerás o teu Deus. Eu sou o Senhor. Levítico 19:32","id_categoria":"203","id":"4548"},
        {"descricao_categoria":"Honrar os Pais","descricao":"Mas, se alguém não tem cuidado dos seus, e principalmente dos da sua família, negou a fé, e é pior do que o infiel. 1 Timóteo 5:8","id_categoria":"203","id":"4549"},
        {"descricao_categoria":"Honrar os Pais","descricao":"O filho sábio atende à instrução do pai; mas o escarnecedor não ouve a repreensão. Provérbios 13:1","id_categoria":"203","id":"4550"},
        {"descricao_categoria":"Honrar os Pais","descricao":"Honra a teu pai e a tua mãe, que é o primeiro mandamento com promessa; Para que te vá bem, e vivas muito tempo sobre a terra. Efésios 6:2,3","id_categoria":"203","id":"4551"},
        {"descricao_categoria":"Honrar os Pais","descricao":"O que aflige o seu pai, ou manda embora sua mãe, é filho que traz vergonha e desonra. Provérbios 19:26","id_categoria":"203","id":"4552"},
        {"descricao_categoria":"Honrar os Pais","descricao":"Ouvi, filhos, a instrução do pai, e estai atentos para conhecerdes a prudência. Provérbios 4:1","id_categoria":"203","id":"4553"},
        {"descricao_categoria":"Humildade","descricao":"Ora, a nosso Deus e Pai seja dada glória para todo o sempre. Amém. Filipenses 4:20","id_categoria":"204","id":"4580"},
        {"descricao_categoria":"Humildade","descricao":"Humilhai-vos, pois, debaixo da potente mão de Deus, para que a seu tempo vos exalte; 1 Pedro 5:6","id_categoria":"204","id":"4582"},
        {"descricao_categoria":"Humildade","descricao":"Em vindo a soberba, virá também a afronta; mas com os humildes está a sabedoria. Provérbios 11:2","id_categoria":"204","id":"4583"},
        {"descricao_categoria":"Humildade","descricao":"Humilhai-vos perante o Senhor, e ele vos exaltará. Tiago 4:10","id_categoria":"204","id":"4585"},
        {"descricao_categoria":"Humildade","descricao":"Quem dentre vós é sábio e entendido? Mostre pelo seu bom trato as suas obras em mansidão de sabedoria. Tiago 3:13","id_categoria":"204","id":"4586"},
        {"descricao_categoria":"Humildade","descricao":"Com toda a humildade e mansidão, com longanimidade, suportando-vos uns aos outros em amor, Efésios 4:2","id_categoria":"204","id":"4587"},
        {"descricao_categoria":"Humildade","descricao":"Sede unânimes entre vós; não ambicioneis coisas altas, mas acomodai-vos às humildes; não sejais sábios em vós mesmos; Romanos 12:16","id_categoria":"204","id":"4590"},
        {"descricao_categoria":"Ídolos","descricao":"Revesti-vos de toda a armadura de Deus, para que possais estar firmes contra as astutas ciladas do diabo. Efésios 6:11","id_categoria":"205","id":"4591"},
        {"descricao_categoria":"Ídolos","descricao":"Filhinhos, guardai-vos dos ídolos. Amém. 1 João 5:21","id_categoria":"205","id":"4592"},
        {"descricao_categoria":"Ídolos","descricao":"Não fareis outros deuses comigo; deuses de prata ou deuses de ouro não fareis para vós. Êxodo 20:23","id_categoria":"205","id":"4601"},
        {"descricao_categoria":"Ídolos","descricao":"Ora, o aguilhão da morte é o pecado, e a força do pecado é a lei. 1 Coríntios 15:56","id_categoria":"205","id":"4603"},
        {"descricao_categoria":"Incentivo","descricao":"Na minha angústia clamei ao SENHOR, e me ouviu. Salmos 120:1","id_categoria":"206","id":"4623"},
        {"descricao_categoria":"Incentivo","descricao":"Entrega o teu caminho ao Senhor; confia nele, e ele o fará. Salmos 37:5","id_categoria":"206","id":"4624"},
        {"descricao_categoria":"Incentivo","descricao":"Não temerei dez milhares de pessoas que se puseram contra mim e me cercam. Salmos 3:6","id_categoria":"206","id":"4627"},
        {"descricao_categoria":"Intimidade com Deus","descricao":"E será que antes que clamem eu responderei; estando eles ainda falando, eu os ouvirei. Isaías 65:24","id_categoria":"207","id":"4629"},
        {"descricao_categoria":"Intimidade com Deus","descricao":"Clama a mim, e responder-te-ei, e anunciar-te-ei coisas grandes e firmes que não sabes. Jeremias 33:3","id_categoria":"207","id":"4632"},
        {"descricao_categoria":"Intimidade com Deus","descricao":"Chegai-vos a Deus, e ele se chegará a vós. Alimpai as mãos, pecadores; e, vós de duplo ânimo, purificai os corações. Tiago 4:8","id_categoria":"207","id":"4633"},
        {"descricao_categoria":"Intimidade com Deus","descricao":"Se confessarmos os nossos pecados, ele é fiel e justo para nos perdoar os pecados, e nos purificar de toda a injustiça. 1 João 1:9","id_categoria":"207","id":"4638"},
        {"descricao_categoria":"Irmãos de Jesus","descricao":"E foram ter com ele sua mãe e seus irmãos, e não podiam aproximar-se dele, por causa da multidão. Lucas 8:19","id_categoria":"208","id":"4639"},
        {"descricao_categoria":"Irmãos de Jesus","descricao":"Todos estes perseveravam unanimemente em oração e súplicas, com as mulheres, e Maria mãe de Jesus, e com seus irmãos. Atos 1:14","id_categoria":"208","id":"4643"},
        {"descricao_categoria":"Irmãos de Jesus","descricao":"E não vi a nenhum outro dos apóstolos, senão a Tiago, irmão do Senhor. Gálatas 1:19","id_categoria":"208","id":"4646"},
        {"descricao_categoria":"Irmãos","descricao":"Não odiarás a teu irmão no teu coração; não deixarás de repreender o teu próximo, e por causa dele não sofrerás pecado. Levítico 19:17","id_categoria":"209","id":"4660"},
        {"descricao_categoria":"Irmãos","descricao":"Porquanto, qualquer que fizer a vontade de Deus, esse é meu irmão, e minha irmã, e minha mãe. Marcos 3:35","id_categoria":"209","id":"4662"},
        {"descricao_categoria":"Irmãos","descricao":"Todo aquele que crê que Jesus é o Cristo, é nascido de Deus; e todo aquele que ama ao que o gerou também ama ao que dele é nascido. 1 João 5:1","id_categoria":"209","id":"4663"},
        {"descricao_categoria":"Irmãos","descricao":"O homem de muitos amigos deve mostrar-se amigável, mas há um amigo mais chegado do que um irmão. Provérbios 18:24","id_categoria":"209","id":"4664"},
        {"descricao_categoria":"Irmãos","descricao":"Aquele que ama a seu irmão está na luz, e nele não há escândalo. 1 João 2:10","id_categoria":"209","id":"4667"},
        {"descricao_categoria":"Irrepreensível","descricao":"Até agora nada pedistes em meu nome; pedi, e recebereis, para que o vosso gozo se cumpra. João 16:24","id_categoria":"210","id":"4671"},
        {"descricao_categoria":"Jejum e Oração","descricao":"Ainda assim, agora mesmo diz o Senhor: Convertei-vos a mim de todo o vosso coração; e isso com jejuns, e com choro, e com pranto. Joel 2:12","id_categoria":"211","id":"4694"},
        {"descricao_categoria":"Jejum e Oração","descricao":"Nós, pois, jejuamos, e pedimos isto ao nosso Deus, e moveu-se pelas nossas orações. Esdras 8:23","id_categoria":"211","id":"4697"},
        {"descricao_categoria":"Jejum e Oração","descricao":"E era viúva, de quase oitenta e quatro anos, e não se afastava do templo, servindo a Deus em jejuns e orações, de noite e de dia. Lucas 2:37","id_categoria":"211","id":"4700"},
        {"descricao_categoria":"Jejum e Oração","descricao":"E quarenta dias foi tentado pelo diabo, e naqueles dias não comeu coisa alguma; e, terminados eles, teve fome. Lucas 4:2","id_categoria":"211","id":"4704"},
        {"descricao_categoria":"Jejum e Oração","descricao":"E eu dirigi o meu rosto ao Senhor Deus, para o buscar com oração e súplicas, com jejum, e saco e cinza. Daniel 9:3","id_categoria":"211","id":"4708"},
        {"descricao_categoria":"Jejum e Oração","descricao":"Em trabalhos e fadiga, em vigílias muitas vezes, em fome e sede, em jejum muitas vezes, em frio e nudez. 2 Coríntios 11:27","id_categoria":"211","id":"4709"},
        {"descricao_categoria":"Jesus me Ama","descricao":"Nós o amamos a ele porque ele nos amou primeiro. 1 João 4:19","id_categoria":"212","id":"4715"},
        {"descricao_categoria":"Jesus me Ama","descricao":"A ti, ó fortaleza minha, cantarei salmos; porque Deus é a minha defesa e o Deus da minha misericórdia. Salmos 59:17","id_categoria":"212","id":"4720"},
        {"descricao_categoria":"Jesus me Ama","descricao":"Mas Deus prova o seu amor para conosco, em que Cristo morreu por nós, sendo nós ainda pecadores. Romanos 5:8","id_categoria":"212","id":"4724"},
        {"descricao_categoria":"Jesus me Ama","descricao":"Conhecemos o amor nisto: que ele deu a sua vida por nós, e nós devemos dar a vida pelos irmãos. 1 João 3:16","id_categoria":"212","id":"4726"},
        {"descricao_categoria":"Jesus me Ama","descricao":"Ninguém tem maior amor do que este, de dar alguém a sua vida pelos seus amigos. João 15:13","id_categoria":"212","id":"4727"},
        {"descricao_categoria":"Jesus te ama","descricao":"E andai em amor, como também Cristo vos amou, e se entregou a si mesmo por nós, em oferta e sacrifício a Deus, em cheiro suave. Efésios 5:2","id_categoria":"213","id":"4729"},
        {"descricao_categoria":"Jesus Cristo","descricao":"E andai em amor, como também Cristo vos amou, e se entregou a si mesmo por nós, em oferta e sacrifício a Deus, em cheiro suave. Efésios 5:2","id_categoria":"214","id":"4799"},
        {"descricao_categoria":"Jesus Cristo","descricao":"Quem é que vence o mundo, senão aquele que crê que Jesus é o Filho de Deus? 1 João 5:5","id_categoria":"214","id":"4800"},
        {"descricao_categoria":"Jovens","descricao":"Até a criança se dará a conhecer pelas suas ações, se a sua obra é pura e reta. Provérbios 20:11","id_categoria":"215","id":"4802"},
        {"descricao_categoria":"Jovens","descricao":"Pois tu és a minha esperança, Senhor DEUS; tu és a minha confiança desde a minha mocidade. Salmos 71:5","id_categoria":"215","id":"4803"},
        {"descricao_categoria":"Jovens","descricao":"Exorta semelhantemente os jovens a que sejam moderados. Tito 2:6","id_categoria":"215","id":"4805"},
        {"descricao_categoria":"Jovens","descricao":"Afasta, pois, a ira do teu coração, e remove da tua carne o mal, porque a adolescência e a juventude são vaidade. Eclesiastes 11:10","id_categoria":"215","id":"4807"},
        {"descricao_categoria":"Julgamento","descricao":"Portanto, ninguém vos julgue pelo comer, ou pelo beber, ou por causa dos dias de festa, ou da lua nova, ou dos sábados, Colossenses 2:16","id_categoria":"217","id":"4875"},
        {"descricao_categoria":"Julgamento","descricao":"O que come não despreze o que não come; e o que não come, não julgue o que come; porque Deus o recebeu por seu. Romanos 14:3","id_categoria":"217","id":"4876"},
        {"descricao_categoria":"Julgamento","descricao":"De palavras de falsidade te afastarás, e não matarás o inocente e o justo; porque não justificarei o ímpio. Êxodo 23:7","id_categoria":"217","id":"4885"},
        {"descricao_categoria":"Julgamento","descricao":"Não julgueis, e não sereis julgados; não condeneis, e não sereis condenados; soltai, e soltar-vos-ão. Lucas 6:37","id_categoria":"217","id":"4886"},
        {"descricao_categoria":"Julgamento","descricao":"Mas eu vos digo que de toda a palavra ociosa que os homens disserem hão de dar conta no dia do juízo. Mateus 12:36","id_categoria":"217","id":"4895"},
        {"descricao_categoria":"Justiça","descricao":"E não nos cansemos de fazer bem, porque a seu tempo ceifaremos, se não houvermos desfalecido. Gálatas 6:9","id_categoria":"218","id":"4898"},
        {"descricao_categoria":"Justiça","descricao":"Fazer justiça e juízo é mais aceitável ao Senhor do que sacrifício. Provérbios 21:3","id_categoria":"218","id":"4915"},
        {"descricao_categoria":"Justiça","descricao":"Porque nunca será abalado; o justo estará em memória eterna. Salmos 112:6","id_categoria":"218","id":"4916"},
        {"descricao_categoria":"Justiça","descricao":"Mas também, se padecerdes por amor da justiça, sois bemaventurados. E não temais com medo deles, nem vos turbeis; 1 Pedro 3:14","id_categoria":"218","id":"4917"},
        {"descricao_categoria":"Justiça","descricao":"O que segue a justiça e a beneficência achará a vida, a justiça e a honra. Provérbios 21:21","id_categoria":"218","id":"4918"},
        {"descricao_categoria":"Justiça","descricao":"O ímpio faz obra falsa, mas para o que semeia justiça haverá galardão fiel. Provérbios 11:18","id_categoria":"218","id":"4919"},
        {"descricao_categoria":"Justiça","descricao":"Mas tu, ó homem de Deus, foge destas coisas, e segue a justiça, a piedade, a fé, o amor, a paciência, a mansidão. 1 Timóteo 6:11","id_categoria":"218","id":"4920"},
        {"descricao_categoria":"Justiça","descricao":"Laço é para o homem apropriar-se do que é santo, e só refletir depois de feitos os votos. Provérbios 20:25","id_categoria":"218","id":"4921"},
        {"descricao_categoria":"Legalidades","descricao":"Porque uma porta grande e eficaz se me abriu; e há muitos adversários. 1 Coríntios 16:9","id_categoria":"219","id":"4924"},
        {"descricao_categoria":"Legalidades","descricao":"A ninguém devais coisa alguma, a não ser o amor com que vos ameis uns aos outros; porque quem ama aos outros cumpriu a lei. Romanos 13:8","id_categoria":"219","id":"4930"},
        {"descricao_categoria":"Lei","descricao":"Escolhi o caminho da verdade; propus-me seguir os teus juízos. Salmos 119:30","id_categoria":"220","id":"4954"},
        {"descricao_categoria":"Lei","descricao":"Porque toda a lei se cumpre numa só palavra, nesta: Amarás ao teu próximo como a ti mesmo. Gálatas 5:14","id_categoria":"220","id":"4955"},
        {"descricao_categoria":"Lei","descricao":"E dele temos este mandamento: que quem ama a Deus, ame também a seu irmão. 1 João 4:21","id_categoria":"220","id":"4956"},
        {"descricao_categoria":"Ler a Bíblia","descricao":"Persiste em ler, exortar e ensinar, até que eu vá. 1 Timóteo 4:13","id_categoria":"221","id":"4960"},
        {"descricao_categoria":"Ler a Bíblia","descricao":"A entrada das tuas palavras dá luz, dá entendimento aos símplices. Salmos 119:130","id_categoria":"221","id":"4961"},
        {"descricao_categoria":"Ler a Bíblia","descricao":"Examinais as Escrituras, porque vós cuidais ter nelas a vida eterna, e são elas que de mim testificam; João 5:39","id_categoria":"221","id":"4963"},
        {"descricao_categoria":"Ler a Bíblia","descricao":"E leram no livro, na lei de Deus; e declarando, e explicando o sentido, faziam que, lendo, se entendesse. Neemias 8:8","id_categoria":"221","id":"4969"},
        {"descricao_categoria":"Ler a Bíblia","descricao":"Lâmpada para os meus pés é tua palavra, e luz para o meu caminho. Salmos 119:105","id_categoria":"221","id":"4971"},
        {"descricao_categoria":"Ler a Bíblia","descricao":"E Jesus, respondendo, disse-lhes: Porventura não errais vós em razão de não saberdes as Escrituras nem o poder de Deus? Marcos 12:24","id_categoria":"221","id":"4972"},
        {"descricao_categoria":"Liberdade","descricao":"E andarei em liberdade; pois busco os teus preceitos. Salmos 119:45","id_categoria":"222","id":"4974"},
        {"descricao_categoria":"Liberdade","descricao":"Pois que? Pecaremos porque não estamos debaixo da lei, mas debaixo da graça? De modo nenhum. Romanos 6:15","id_categoria":"222","id":"4975"},
        {"descricao_categoria":"Língua","descricao":"A morte e a vida estão no poder da língua; e aquele que a ama comerá do seu fruto. Provérbios 18:21","id_categoria":"223","id":"5007"},
        {"descricao_categoria":"Língua","descricao":"Despedaça, Senhor, e divide as suas línguas, pois tenho visto violência e contenda na cidade. Salmos 55:9","id_categoria":"223","id":"5008"},
        {"descricao_categoria":"Língua","descricao":"E assim a minha língua falará da tua justiça e do teu louvor todo o dia. Salmos 35:28","id_categoria":"223","id":"5009"},
        {"descricao_categoria":"Língua","descricao":"O lábio da verdade permanece para sempre, mas a língua da falsidade, dura por um só momento. Provérbios 12:19","id_categoria":"223","id":"5010"},
        {"descricao_categoria":"Língua","descricao":"Há alguns que falam como que espada penetrante, mas a língua dos sábios é saúde. Provérbios 12:18","id_categoria":"223","id":"5012"},
        {"descricao_categoria":"Louvor e adoração","descricao":"Bem-aventurado o povo que conhece o som alegre; andará, ó Senhor, na luz da tua face. Salmos 89:15","id_categoria":"226","id":"5134"},
        {"descricao_categoria":"Louvor e adoração","descricao":"Bendito seja o Senhor, que de dia em dia nos carrega de benefícios; o Deus que é a nossa salvação. (Selá.). Salmos 68:19","id_categoria":"226","id":"5138"},
        {"descricao_categoria":"Louvor e adoração","descricao":"Bendize, ó minha alma, ao Senhor, e não te esqueças de nenhum de seus benefícios. Salmos 103:2","id_categoria":"226","id":"5141"},
        {"descricao_categoria":"Louvor e adoração","descricao":"Tu pois, sofre as aflições, como bom soldado de Jesus Cristo. 2 Timóteo 2:3","id_categoria":"226","id":"5143"},
        {"descricao_categoria":"Louvor e adoração","descricao":"Alegrai-vos, ó justos, no Senhor, e dai louvores à memória da sua santidade. Salmos 97:12","id_categoria":"226","id":"5144"},
        {"descricao_categoria":"Louvor e adoração","descricao":"Servi ao Senhor com temor, e alegrai-vos com tremor. Salmos 2:11","id_categoria":"226","id":"5148"},
        {"descricao_categoria":"Louvor e adoração","descricao":"Mas eu esperarei continuamente, e te louvarei cada vez mais. Salmos 71:14","id_categoria":"226","id":"5150"},
        {"descricao_categoria":"Luz do Mundo","descricao":"Enquanto estou no mundo, sou a luz do mundo. João 9:5","id_categoria":"227","id":"5174"},
        {"descricao_categoria":"Luz do Mundo","descricao":"Porquanto tudo o que em trevas dissestes, à luz será ouvido; e o que falastes ao ouvido no gabinete, sobre os telhados será apregoado. Lucas 12:3","id_categoria":"227","id":"5176"},
        {"descricao_categoria":"Luz do Mundo","descricao":"Porque em ti está o manancial da vida; na tua luz veremos a luz. Salmos 36:9","id_categoria":"227","id":"5177"},
        {"descricao_categoria":"Luz do Mundo","descricao":"Faze-nos voltar, Senhor Deus dos Exércitos; faze resplandecer o teu rosto, e seremos salvos. Salmos 80:19","id_categoria":"227","id":"5179"},
        {"descricao_categoria":"Luz do Mundo","descricao":"E a luz resplandece nas trevas, e as trevas não a compreenderam. João 1:5","id_categoria":"227","id":"5180"},
        {"descricao_categoria":"Luz do Mundo","descricao":"Toda a boa dádiva e todo o dom perfeito vem do alto, descendo do Pai das luzes, em quem não há mudança nem sombra de variação. Tiago 1:17","id_categoria":"227","id":"5181"},
        {"descricao_categoria":"Luz do Mundo","descricao":"Falou-lhes, pois, Jesus outra vez, dizendo: Eu sou a luz do mundo; quem me segue não andará em trevas, mas terá a luz da vida. João 8:12","id_categoria":"227","id":"5182"},
        {"descricao_categoria":"Luz do Mundo","descricao":"Porque todos vós sois filhos da luz e filhos do dia; nós não somos da noite nem das trevas. 1 Tessalonicenses 5:5","id_categoria":"227","id":"5189"},
        {"descricao_categoria":"Luz do Mundo","descricao":"Vós sois a luz do mundo; não se pode esconder uma cidade edificada sobre um monte; Mateus 5:14","id_categoria":"227","id":"5190"},
        {"descricao_categoria":"Luz do Mundo","descricao":"O SENHOR é a minha luz e a minha salvação; a quem temerei? O SENHOR é a força da minha vida; de quem me recearei? Salmos 27:1","id_categoria":"227","id":"5192"},
        {"descricao_categoria":"Luz do Mundo","descricao":"O povo que andava em trevas, viu uma grande luz, e sobre os que habitavam na região da sombra da morte resplandeceu a luz. Isaías 9:2","id_categoria":"227","id":"5195"},
        {"descricao_categoria":"Luz do Mundo","descricao":"Faze resplandecer o teu rosto sobre o teu servo; salva-me por tuas misericórdias. Salmos 31:16","id_categoria":"227","id":"5196"},
        {"descricao_categoria":"Luz do Mundo","descricao":"A entrada das tuas palavras dá luz, dá entendimento aos símplices. Salmos 119:130","id_categoria":"227","id":"5199"},
        {"descricao_categoria":"Mãe","descricao":"Mulher virtuosa quem a achará? O seu valor muito excede ao de rubis. Provérbios 31:10","id_categoria":"228","id":"5202"},
        {"descricao_categoria":"Mãe","descricao":"Honra a teu pai e a tua mãe, para que se prolonguem os teus dias na terra que o Senhor teu Deus te dá. Êxodo 20:12","id_categoria":"228","id":"5203"},
        {"descricao_categoria":"Mãe","descricao":"O que não faz uso da vara odeia seu filho, mas o que o ama, desde cedo o castiga. Provérbios 13:24","id_categoria":"228","id":"5204"},
        {"descricao_categoria":"Mãe","descricao":"O filho sábio alegra seu pai, mas o homem insensato despreza a sua mãe. Provérbios 15:20","id_categoria":"228","id":"5206"},
        {"descricao_categoria":"Mãe","descricao":"Educa a criança no caminho em que deve andar; e até quando envelhecer não se desviará dele. Provérbios 22:6","id_categoria":"228","id":"5209"},
        {"descricao_categoria":"Mãe","descricao":"Eis que os filhos são herança do Senhor, e o fruto do ventre o seu galardão. Salmos 127:3","id_categoria":"228","id":"5212"},
        {"descricao_categoria":"Mãe","descricao":"Como alguém a quem consola sua mãe, assim eu vos consolarei; e em Jerusalém vós sereis consolados. Isaías 66:13","id_categoria":"228","id":"5213"},
        {"descricao_categoria":"Mal","descricao":"O temor do Senhor é odiar o mal; a soberba e a arrogância, o mau caminho e a boca perversa, eu odeio. Provérbios 8:13","id_categoria":"229","id":"5214"},
        {"descricao_categoria":"Mal","descricao":"Vós, que amais ao Senhor, odiai o mal. Ele guarda as almas dos seus santos; ele os livra das mãos dos ímpios. Salmos 97:10","id_categoria":"229","id":"5216"},
        {"descricao_categoria":"Mal","descricao":"Ordena os meus passos na tua palavra, e não se apodere de mim iniqüidade alguma. Salmos 119:133","id_categoria":"229","id":"5217"},
        {"descricao_categoria":"Mal","descricao":"Se confessarmos os nossos pecados, ele é fiel e justo para nos perdoar os pecados, e nos purificar de toda a injustiça. 1 João 1:9","id_categoria":"229","id":"5218"},
        {"descricao_categoria":"Mal","descricao":"E não nos conduzas à tentação; mas livra-nos do mal; porque teu é o reino, e o poder, e a glória, para sempre. Amém. Mateus 6:13","id_categoria":"229","id":"5219"},
        {"descricao_categoria":"Mal","descricao":"O Senhor te guardará de todo o mal; guardará a tua alma. O Senhor guardará a tua entrada e a tua saída, desde agora e para sempre. Salmos 121:7,8","id_categoria":"229","id":"5221"},
        {"descricao_categoria":"Mal","descricao":"Vede que ninguém dê a outrem mal por mal, mas segui sempre o bem, tanto uns para com os outros, como para com todos. 1 Tessalonicenses 5:15","id_categoria":"229","id":"5222"},
        {"descricao_categoria":"Mal","descricao":"O ódio excita contendas, mas o amor cobre todos os pecados. Provérbios 10:12","id_categoria":"229","id":"5225"},
        {"descricao_categoria":"Mal","descricao":"E o Senhor me livrará de toda a má obra, e guardar-me-á para o seu reino celestial; a quem seja glória para todo o sempre. Amém. 2 Timóteo 4:18","id_categoria":"229","id":"5226"},
        {"descricao_categoria":"Maria","descricao":"Todos estes perseveravam unanimemente em oração e súplicas, com as mulheres, e Maria mãe de Jesus, e com seus irmãos. Atos 1:14","id_categoria":"230","id":"5250"},
        {"descricao_categoria":"Marido e Mulher","descricao":"A mulher virtuosa é a coroa do seu marido, mas a que o envergonha é como podridão nos seus ossos. Provérbios 12:4","id_categoria":"231","id":"5254"},
        {"descricao_categoria":"Marido e Mulher","descricao":"E criou Deus o homem à sua imagem; à imagem de Deus o criou; homem e mulher os criou. Gênesis 1:27","id_categoria":"231","id":"5256"},
        {"descricao_categoria":"Marido e Mulher","descricao":"Assim também vós, cada um em particular, ame a sua própria mulher como a si mesmo, e a mulher reverencie o marido. Efésios 5:33","id_categoria":"231","id":"5261"},
        {"descricao_categoria":"Marido e Mulher","descricao":"Portanto deixará o homem o seu pai e a sua mãe, e apegar-se-á à sua mulher, e serão ambos uma carne. Gênesis 2:24","id_categoria":"231","id":"5262"},
        {"descricao_categoria":"Marido e Mulher","descricao":"E disse o Senhor Deus: Não é bom que o homem esteja só; far-lhe-ei uma ajudadora idônea para ele. Gênesis 2:18","id_categoria":"231","id":"5264"},
        {"descricao_categoria":"Marido e Mulher","descricao":"A casa e os bens são herança dos pais; porém do Senhor vem a esposa prudente. Provérbios 19:14","id_categoria":"231","id":"5265"},
        {"descricao_categoria":"Marido e Mulher","descricao":"Aquele que encontra uma esposa, acha o bem, e alcança a benevolência do Senhor. Provérbios 18:22","id_categoria":"231","id":"5273"},
        {"descricao_categoria":"Materialismo","descricao":"O cobiçoso cobiça o dia todo, mas o justo dá, e nada retém. Provérbios 21:26","id_categoria":"232","id":"5297"},
        {"descricao_categoria":"Materialismo","descricao":"Como saiu do ventre de sua mãe, assim nu tornará, indo-se como veio; e nada tomará do seu trabalho, que possa levar na sua mão. Eclesiastes 5:15","id_categoria":"232","id":"5298"},
        {"descricao_categoria":"Mediador","descricao":"E aquele que examina os corações sabe qual é a intenção do Espírito; e é ele que segundo Deus intercede pelos santos. Romanos 8:27","id_categoria":"233","id":"5299"},
        {"descricao_categoria":"Mediador","descricao":"Porque há um só Deus, e um só Mediador entre Deus e os homens, Jesus Cristo homem. 1 Timóteo 2:5","id_categoria":"233","id":"5300"},
        {"descricao_categoria":"Mediador","descricao":"Disse-lhe Jesus: Eu sou o caminho, e a verdade e a vida; ninguém vem ao Pai, senão por mim. João 14:6","id_categoria":"233","id":"5301"},
        {"descricao_categoria":"Mediador","descricao":"Àquele que não conheceu pecado, o fez pecado por nós; para que nele fôssemos feitos justiça de Deus. 2 Coríntios 5:21","id_categoria":"233","id":"5303"},
        {"descricao_categoria":"Mediador","descricao":"Não peço que os tires do mundo, mas que os livres do mal. João 17:15","id_categoria":"233","id":"5304"},
        {"descricao_categoria":"Meditação","descricao":"Sejam agradáveis as palavras da minha boca e a meditação do meu coração perante a tua face, Senhor, Rocha minha e Redentor meu! Salmos 19:14","id_categoria":"234","id":"5308"},
        {"descricao_categoria":"Meditação","descricao":"A minha boca falará de sabedoria, e a meditação do meu coração será de entendimento. Salmos 49:3","id_categoria":"234","id":"5309"},
        {"descricao_categoria":"Meditação","descricao":"Lembro-me dos dias antigos; considero todos os teus feitos; medito na obra das tuas mãos. Salmos 143:5","id_categoria":"234","id":"5311"},
        {"descricao_categoria":"Meditação","descricao":"Lembramo-nos, ó Deus, da tua benignidade, no meio do teu templo. Salmos 48:9","id_categoria":"234","id":"5313"},
        {"descricao_categoria":"Medo da Morte","descricao":"E não temais os que matam o corpo e não podem matar a alma; temei antes aquele que pode fazer perecer no inferno a alma e o corpo. Mateus 10:28","id_categoria":"235","id":"5319"},
        {"descricao_categoria":"Medo da Morte","descricao":"Entrará em paz; descansarão nas suas camas, os que houverem andado na sua retidão. Isaías 57:2","id_categoria":"235","id":"5320"},
        {"descricao_categoria":"Medo da Morte","descricao":"Em verdade, em verdade vos digo que, se alguém guardar a minha palavra, nunca verá a morte. João 8:51","id_categoria":"235","id":"5323"},
        {"descricao_categoria":"Medo da Morte","descricao":"Porque todo aquele que invocar o nome do Senhor será salvo. Romanos 10:13","id_categoria":"235","id":"5325"},
        {"descricao_categoria":"Mente","descricao":"Quem dentre vós é sábio e entendido? Mostre pelo seu bom trato as suas obras em mansidão de sabedoria. Tiago 3:13","id_categoria":"237","id":"5383"},
        {"descricao_categoria":"Mente","descricao":"Pensai nas coisas que são de cima, e não nas que são da terra; Colossenses 3:2","id_categoria":"237","id":"5384"},
        {"descricao_categoria":"Mente","descricao":"Como o ferro com ferro se aguça, assim o homem afia o rosto do seu amigo. Provérbios 27:17","id_categoria":"237","id":"5385"},
        {"descricao_categoria":"Mente","descricao":"Porque na muita sabedoria há muito enfado; e o que aumenta em conhecimento, aumenta em dor. Eclesiastes 1:18","id_categoria":"237","id":"5386"},
        {"descricao_categoria":"Mente","descricao":"Portanto, vede prudentemente como andais, não como néscios, mas como sábios, Remindo o tempo; porquanto os dias são maus. Efésios 5:15,16","id_categoria":"237","id":"5387"},
        {"descricao_categoria":"Mente","descricao":"Porque nada me propus saber entre vós, senão a Jesus Cristo, e este crucificado. 1 Coríntios 2:2","id_categoria":"237","id":"5388"},
        {"descricao_categoria":"Messias","descricao":"Pois, na cidade de Davi, vos nasceu hoje o Salvador, que é Cristo, o Senhor. Lucas 2:11","id_categoria":"239","id":"5414"},
        {"descricao_categoria":"Messias","descricao":"E todos os dias, no templo e nas casas, não cessavam de ensinar, e de anunciar a Jesus Cristo. Atos 5:42","id_categoria":"239","id":"5418"},
        {"descricao_categoria":"Messias","descricao":"Disse-lhes ele: E vós, quem dizeis que eu sou? E Simão Pedro, respondendo, disse: Tu és o Cristo, o Filho do Deus vivo. Mateus 16:15,16","id_categoria":"239","id":"5419"},
        {"descricao_categoria":"Messias","descricao":"Por isso, estai vós apercebidos também; porque o Filho do homem há de vir à hora em que não penseis. Mateus 24:44","id_categoria":"239","id":"5420"},
        {"descricao_categoria":"Milagres de Jesus","descricao":"Trouxeram-lhe, então, um endemoninhado cego e mudo; e, de tal modo o curou, que o cego e mudo falava e via. Mateus 12:22","id_categoria":"240","id":"5456"},
        {"descricao_categoria":"Milagres de Jesus","descricao":"Mas, à quarta vigília da noite, dirigiu-se Jesus para eles, andan-do por cima do mar. Mateus 14:25","id_categoria":"240","id":"5486"},
        {"descricao_categoria":"Milagres de Jesus","descricao":"E estava ele expulsando um demônio, o qual era mudo. E aconteceu que, saindo o demônio, o mudo falou; e maravilhou-se a multidão. Lucas 11:14","id_categoria":"240","id":"5491"},
        {"descricao_categoria":"Milagres","descricao":"E caí por terra, e ouvi uma voz que me dizia: Saulo, Saulo, por que me persegues? Atos 22:7","id_categoria":"241","id":"5495"},
        {"descricao_categoria":"Morte","descricao":"Porque assim como a morte veio por um homem, também a ressurreição dos mortos veio por um homem. 1 Coríntios 15:21","id_categoria":"244","id":"5603"},
        {"descricao_categoria":"Morte","descricao":"Entrará em paz; descansarão nas suas camas, os que houverem andado na sua retidão. Isaías 57:2","id_categoria":"244","id":"5604"},
        {"descricao_categoria":"Morte","descricao":"Melhor é a boa fama do que o melhor ungüento, e o dia da morte do que o dia do nascimento de alguém. Eclesiastes 7:1","id_categoria":"244","id":"5605"},
        {"descricao_categoria":"Morte","descricao":"Porque aquele que quiser salvar a sua vida, perdê-la-á, e quem perder a sua vida por amor de mim, achá-la-á. Mateus 16:25","id_categoria":"244","id":"5611"},
        {"descricao_categoria":"Morte","descricao":"Depois, havendo a concupiscência concebido, dá à luz o pecado; e o pecado, sendo consumado, gera a morte. Tiago 1:15","id_categoria":"244","id":"5613"},
        {"descricao_categoria":"Motivação","descricao":"E tudo quanto fizerdes, fazei-o de todo o coração, como ao Senhor, e não aos homens, Colossenses 3:23","id_categoria":"245","id":"5618"},
        {"descricao_categoria":"Motivação","descricao":"Porque Deus é o que opera em vós tanto o querer como o efetuar, segundo a sua boa vontade. Filipenses 2:13","id_categoria":"245","id":"5619"},
        {"descricao_categoria":"Motivação","descricao":"Posso todas as coisas em Cristo que me fortalece. Filipenses 4:13","id_categoria":"245","id":"5621"},
        {"descricao_categoria":"Motivação","descricao":"Mil cairão ao teu lado, e dez mil à tua direita, mas não chegará a ti. Salmos 91:7","id_categoria":"245","id":"5627"},
        {"descricao_categoria":"Mudança de vida","descricao":"Assim que, se alguém está em Cristo, nova criatura é; as coisas velhas já passaram; eis que tudo se fez novo. 2 Coríntios 5:17","id_categoria":"246","id":"5636"},
        {"descricao_categoria":"Mudança de vida","descricao":"Procura apresentar-te a Deus aprovado, como obreiro que não tem de que se envergonhar, que maneja bem a palavra da verdade. 2 Timóteo 2:15","id_categoria":"246","id":"5638"},
        {"descricao_categoria":"Mudança de vida","descricao":"Não saia da vossa boca nenhuma palavra torpe, mas só a que for boa para promover a edificação, para que dê graça aos que a ouvem. Efésios 4:29","id_categoria":"246","id":"5640"},
        {"descricao_categoria":"Mudança de vida","descricao":"Se confessarmos os nossos pecados, ele é fiel e justo para nos perdoar os pecados, e nos purificar de toda a injustiça. 1 João 1:9","id_categoria":"246","id":"5642"},
        {"descricao_categoria":"Mudança","descricao":"Tudo tem o seu tempo determinado, e há tempo para todo o propósito debaixo do céu. Eclesiastes 3:1","id_categoria":"247","id":"5645"},
        {"descricao_categoria":"Mudança","descricao":"Produzi, pois, frutos dignos de arrependimento; Mateus 3:8","id_categoria":"247","id":"5646"},
        {"descricao_categoria":"Mudança","descricao":"Porque eu, o Senhor, não mudo; por isso vós, ó filhos de Jacó, não sois consumidos. Malaquias 3:6","id_categoria":"247","id":"5648"},
        {"descricao_categoria":"Mudança","descricao":"Eis que faço uma coisa nova, agora sairá à luz; porventura não a percebeis? Eis que porei um caminho no deserto, e rios no ermo. Isaías 43:19","id_categoria":"247","id":"5649"},
        {"descricao_categoria":"Mulher","descricao":"E criou Deus o homem à sua imagem; à imagem de Deus o criou; homem e mulher os criou. Gênesis 1:27","id_categoria":"248","id":"5666"},
        {"descricao_categoria":"Mulher","descricao":"Agora, pois, minha filha, não temas; tudo quanto disseste te farei, pois toda a cidade do meu povo sabe que és mulher virtuosa. Rute 3:11","id_categoria":"248","id":"5667"},
        {"descricao_categoria":"Mulher","descricao":"Da mesma sorte as esposas sejam honestas, não maldizentes, sóbrias e fiéis em tudo. 1 Timóteo 3:11","id_categoria":"248","id":"5668"},
        {"descricao_categoria":"Mulher","descricao":"É melhor morar numa terra deserta do que com a mulher rixosa e irritadiça. Provérbios 21:19","id_categoria":"248","id":"5669"},
        {"descricao_categoria":"Mundo","descricao":"Dei-lhes a tua palavra, e o mundo os odiou, porque não são do mundo, assim como eu não sou do mundo. João 17:14","id_categoria":"249","id":"5705"},
        {"descricao_categoria":"Mundo","descricao":"Pois que aproveita ao homem ganhar o mundo inteiro, se perder a sua alma? Ou que dará o homem em recompensa da sua alma? Mateus 16:26","id_categoria":"249","id":"5708"},
        {"descricao_categoria":"Mundo","descricao":"E a condenação é esta: Que a luz veio ao mundo, e os homens amaram mais as trevas do que a luz, porque as suas obras eram más. João 3:19","id_categoria":"249","id":"5709"},
        {"descricao_categoria":"Mundo","descricao":"Porque todo o que é nascido de Deus vence o mundo; e esta é a vitória que vence o mundo, a nossa fé. 1 João 5:4","id_categoria":"249","id":"5711"},
        {"descricao_categoria":"Mundo","descricao":"E o mundo passa, e a sua concupiscência; mas aquele que faz a vontade de Deus permanece para sempre. 1 João 2:17","id_categoria":"249","id":"5713"},
        {"descricao_categoria":"Mundo","descricao":"Porque, andando na carne, não militamos segundo a carne. 2 Coríntios 10:3","id_categoria":"249","id":"5714"},
        {"descricao_categoria":"Mundo","descricao":"Deixo-vos a paz, a minha paz vos dou; não vo-la dou como o mundo a dá. Não se turbe o vosso coração, nem se atemorize. João 14:27","id_categoria":"249","id":"5717"},
        {"descricao_categoria":"Mundo","descricao":"Não peço que os tires do mundo, mas que os livres do mal. João 17:15","id_categoria":"249","id":"5719"},
        {"descricao_categoria":"Mundo","descricao":"Teus são os céus, e tua é a terra; o mundo e a sua plenitude tu os fundaste. Salmos 89:11","id_categoria":"249","id":"5720"},
        {"descricao_categoria":"Mundo","descricao":"Estava no mundo, e o mundo foi feito por ele, e o mundo não o conheceu. João 1:10","id_categoria":"249","id":"5722"},
        {"descricao_categoria":"Mundo","descricao":"Falou-lhes, pois, Jesus outra vez, dizendo: Eu sou a luz do mundo; quem me segue não andará em trevas, mas terá a luz da vida. João 8:12","id_categoria":"249","id":"5724"},
        {"descricao_categoria":"Mundo","descricao":"Pois, que aproveitaria ao homem ganhar todo o mundo e perder a sua alma? Marcos 8:36","id_categoria":"249","id":"5727"},
        {"descricao_categoria":"Namoro e Sexo","descricao":"E qualquer que nele tem esta esperança purifica-se a si mesmo, como também ele é puro. 1 João 3:3","id_categoria":"250","id":"5729"},
        {"descricao_categoria":"Namoro","descricao":"Mas, se não podem conter-se, casem-se. Porque é melhor casar do que abrasar-se. 1 Coríntios 7:9","id_categoria":"251","id":"5734"},
        {"descricao_categoria":"Namoro","descricao":"Conjuro-vos, ó filhas de Jerusalém, que não acordeis nem desperteis o meu amor, até que queira. Cânticos 8:4","id_categoria":"251","id":"5735"},
        {"descricao_categoria":"Namoro","descricao":"Porventura andarão dois juntos, se não estiverem de acordo? Amós 3:3","id_categoria":"251","id":"5736"},
        {"descricao_categoria":"Não Desista!","descricao":"Tendo por certo isto mesmo, que aquele que em vós começou a boa obra a aperfeiçoará até ao dia de Jesus Cristo; Filipenses 1:6","id_categoria":"252","id":"5776"},
        {"descricao_categoria":"Não Desista!","descricao":"De sorte que a fé é pelo ouvir, e o ouvir pela palavra de Deus. Romanos 10:17","id_categoria":"252","id":"5777"},
        {"descricao_categoria":"Não Desista!","descricao":"Esforçai-vos, e ele fortalecerá o vosso coração, vós todos que esperais no Senhor. Salmos 31:24","id_categoria":"252","id":"5779"},
        {"descricao_categoria":"Não Desista!","descricao":"E não nos cansemos de fazer bem, porque a seu tempo ceifaremos, se não houvermos desfalecido. Gálatas 6:9","id_categoria":"252","id":"5781"},
        {"descricao_categoria":"Não Desista!","descricao":"Aqui está a paciência dos santos; aqui estão os que guardam os mandamentos de Deus e a fé em Jesus. Apocalipse 14:12","id_categoria":"252","id":"5785"},
        {"descricao_categoria":"Não Desista!","descricao":"O Senhor é bom, ele serve de fortaleza no dia da angústia, e conhece os que confiam nele. Naum 1:7","id_categoria":"252","id":"5787"},
        {"descricao_categoria":"Não Temas","descricao":"E vendo isto Simão Pedro, prostrou-se aos pés de Jesus, dizendo: Senhor, ausenta-te de mim, que sou um homem pecador. Lucas 5:8","id_categoria":"253","id":"5792"},
        {"descricao_categoria":"Não Temas","descricao":"Não temas, ó terra: regozija-te e alegra-te, porque o Senhor fez grandes coisas. Joel 2:21","id_categoria":"253","id":"5797"},
        {"descricao_categoria":"Não Temas","descricao":"E não temais os que matam o corpo e não podem matar a alma; temei antes aquele que pode fazer perecer no inferno a alma e o corpo. Mateus 10:28","id_categoria":"253","id":"5801"},
        {"descricao_categoria":"Não Temas","descricao":"Disse-lhe, então, o anjo: Maria, não temas, porque achaste graça diante de Deus. Lucas 1:30","id_categoria":"253","id":"5802"},
        {"descricao_categoria":"Não Temas","descricao":"E Jesus, tendo ouvido estas palavras, disse ao principal da sinagoga: Não temas, crê somente. Marcos 5:36","id_categoria":"253","id":"5804"},
        {"descricao_categoria":"Nascer de Novo","descricao":"Em verdade vos digo que qualquer que não receber o reino de Deus como menino, de maneira nenhuma entrará nele. Marcos 10:15","id_categoria":"254","id":"5808"},
        {"descricao_categoria":"Nascer de Novo","descricao":"Assim que, se alguém está em Cristo, nova criatura é; as coisas velhas já passaram; eis que tudo se fez novo. 2 Coríntios 5:17","id_categoria":"254","id":"5810"},
        {"descricao_categoria":"Nascer de Novo","descricao":"Sendo de novo gerados, não de semente corruptível, mas da incorruptível, pela palavra de Deus, viva, e que permanece para sempre. 1 Pedro 1:23","id_categoria":"254","id":"5812"},
        {"descricao_categoria":"Nascer de Novo","descricao":"Assim também vós considerai-vos certamente mortos para o pecado, mas vivos para Deus em Cristo Jesus nosso Senhor. Romanos 6:11","id_categoria":"254","id":"5813"},
        {"descricao_categoria":"Nascer de Novo","descricao":"Jesus respondeu, e disse-lhe: Na verdade, na verdade te digo que aquele que não nascer de novo, não pode ver o reino de Deus. João 3:3","id_categoria":"254","id":"5826"},
        {"descricao_categoria":"Nascimento de Bebê","descricao":"E, tomando-os nos seus braços, e impondo-lhes as mãos, os abençoou. Marcos 10:16","id_categoria":"255","id":"5827"},
        {"descricao_categoria":"Nascimento de Bebê","descricao":"Pois possuíste os meus rins; cobriste-me no ventre de minha mãe. Salmos 139:13","id_categoria":"255","id":"5828"},
        {"descricao_categoria":"Nascimento de Bebê","descricao":"Por este menino orava eu; e o Senhor atendeu à minha petição, que eu lhe tinha feito. 1 Samuel 1:27","id_categoria":"255","id":"5829"},
        {"descricao_categoria":"Nascimento de Bebê","descricao":"Mas tu és o que me tiraste do ventre; fizeste-me confiar, estando aos seios de minha mãe. Salmos 22:9","id_categoria":"255","id":"5831"},
        {"descricao_categoria":"Nascimento de Bebê","descricao":"Antes que te formasse no ventre te conheci, e antes que saísses da madre, te santifiquei; às nações te dei por profeta. Jeremias 1:5","id_categoria":"255","id":"5832"},
        {"descricao_categoria":"Nascimento de Bebê","descricao":"Abriria eu a madre, e não geraria? diz o Senhor; geraria eu, e fecharia a madre? diz o teu Deus. Isaías 66:9","id_categoria":"255","id":"5834"},
        {"descricao_categoria":"Nascimento","descricao":"Pois tu, Senhor, me alegraste pelos teus feitos; exultarei nas obras das tuas mãos. Salmos 92:4","id_categoria":"257","id":"5866"},
        {"descricao_categoria":"Nascimento","descricao":"Eis que os filhos são herança do Senhor, e o fruto do ventre o seu galardão. Salmos 127:3","id_categoria":"257","id":"5873"},
        {"descricao_categoria":"Nascimento","descricao":"Cantai-lhe, salmodiai-lhe, atentamente falai de todas as suas maravilhas. 1 Crônicas 16:9","id_categoria":"257","id":"5874"},
        {"descricao_categoria":"Natal","descricao":"Glória a Deus nas alturas, Paz na terra, boa vontade para com os homens. Lucas 2:14","id_categoria":"258","id":"5876"},
        {"descricao_categoria":"Natal","descricao":"E voltaram os pastores, glorificando e louvando a Deus por tudo o que tinham ouvido e visto, como lhes havia sido dito. Lucas 2:20","id_categoria":"258","id":"5877"},
        {"descricao_categoria":"Natal","descricao":"Pois, na cidade de Davi, vos nasceu hoje o Salvador, que é Cristo, o Senhor. Lucas 2:11","id_categoria":"258","id":"5878"},
        {"descricao_categoria":"Natal","descricao":"E o anjo lhes disse: Não temais, porque eis aqui vos trago novas de grande alegria, que será para todo o povo: Lucas 2:10","id_categoria":"258","id":"5879"},
        {"descricao_categoria":"Natal","descricao":"Portanto o mesmo Senhor vos dará um sinal: Eis que a virgem conceberá, e dará à luz um filho, e chamará o seu nome Emanuel. Isaías 7:14","id_categoria":"258","id":"5880"},
        {"descricao_categoria":"Natal","descricao":"E dará à luz um filho e chamarás o seu nome JESUS; porque ele salvará o seu povo dos seus pecados. Mateus 1:21","id_categoria":"258","id":"5883"},
        {"descricao_categoria":"Negação de Cristo","descricao":"Mas vós negastes o Santo e o Justo, e pedistes que se vos desse um homem homicida. Atos 3:14","id_categoria":"259","id":"5885"},
        {"descricao_categoria":"Negação de Cristo","descricao":"Mas qualquer que me negar diante dos homens, eu o negarei também diante de meu Pai, que está nos céus. Mateus 10:33","id_categoria":"259","id":"5886"},
        {"descricao_categoria":"Negação de Cristo","descricao":"Se sofrermos, também com ele reinaremos; se o negarmos, também ele nos negará; 2 Timóteo 2:12","id_categoria":"259","id":"5889"},
        {"descricao_categoria":"Negação de Cristo","descricao":"Mas ele negou diante de todos, dizendo: Não sei o que dizes. Mateus 26:70","id_categoria":"259","id":"5890"},
        {"descricao_categoria":"Negação de Cristo","descricao":"Quem é o mentiroso, senão aquele que nega que Jesus é o Cristo? É o anticristo esse mesmo que nega o Pai e o Filho. 1 João 2:22","id_categoria":"259","id":"5891"},
        {"descricao_categoria":"Negação de Cristo","descricao":"Disse-lhe Jesus: Em verdade te digo que, nesta mesma noite, antes que o galo cante, três vezes me negarás. Mateus 26:34","id_categoria":"259","id":"5892"},
        {"descricao_categoria":"Nem só de pão vive o homem","descricao":"E Jesus lhes disse: Eu sou o pão da vida; aquele que vem a mim não terá fome, e quem crê em mim nunca terá sede. João 6:35","id_categoria":"260","id":"5895"},
        {"descricao_categoria":"Nem só de pão vive o homem","descricao":"Ele, porém, respondendo, disse: Está escrito: Nem só de pão viverá o homem, mas de toda a palavra que sai da boca de Deus. Mateus 4:4","id_categoria":"260","id":"5900"},
        {"descricao_categoria":"Nova Aliança","descricao":"Dizendo Nova aliança, envelheceu a primeira. Ora, o que foi tornado velho, e se envelhece, perto está de acabar. Hebreus 8:13","id_categoria":"261","id":"5912"},
        {"descricao_categoria":"Paciência","descricao":"Ora, o Deus de paciência e consolação vos conceda o mesmo sentimento uns para com os outros, segundo Cristo Jesus, Romanos 15:5","id_categoria":"263","id":"5941"},
        {"descricao_categoria":"Paciência","descricao":"E não nos cansemos de fazer bem, porque a seu tempo ceifaremos, se não houvermos desfalecido. Gálatas 6:9","id_categoria":"263","id":"5942"},
        {"descricao_categoria":"Paciência","descricao":"Alegrai-vos na esperança, sede pacientes na tribulação, perseverai na oração; Romanos 12:12","id_categoria":"263","id":"5944"},
        {"descricao_categoria":"Paciência","descricao":"Vigiai, pois, porque não sabeis a que hora há de vir o vosso Senhor. Mateus 24:42","id_categoria":"263","id":"5945"},
        {"descricao_categoria":"Paciência","descricao":"Eis que venho sem demora; guarda o que tens, para que ninguém tome a tua coroa. Apocalipse 3:11","id_categoria":"263","id":"5947"},
        {"descricao_categoria":"Paciência","descricao":"Misericordioso e piedoso é o Senhor; longânimo e grande em benignidade. Salmos 103:8","id_categoria":"263","id":"5948"},
        {"descricao_categoria":"Paciência","descricao":"Mas, se esperamos o que não vemos, com paciência o esperamos. Romanos 8:25","id_categoria":"263","id":"5950"},
        {"descricao_categoria":"Paciência","descricao":"Espera no Senhor, anima-te, e ele fortalecerá o teu coração; espera, pois, no Senhor. Salmos 27:14","id_categoria":"263","id":"5951"},
        {"descricao_categoria":"Pai da Mentira","descricao":"A esse cuja vinda é segundo a eficácia de Satanás, com todo o poder, e sinais e prodígios de mentira, 2 Tessalonicenses 2:9","id_categoria":"264","id":"5952"},
        {"descricao_categoria":"Pai da Mentira","descricao":"Sujeitai-vos, pois, a Deus, resisti ao diabo, e ele fugirá de vós. Tiago 4:7","id_categoria":"264","id":"5954"},
        {"descricao_categoria":"Pai da Mentira","descricao":"Sede sóbrios; vigiai; porque o diabo, vosso adversário, anda em derredor, bramando como leão, buscando a quem possa tragar; 1 Pedro 5:8","id_categoria":"264","id":"5956"},
        {"descricao_categoria":"Pai da Mentira","descricao":"E não é maravilha, porque o próprio Satanás se transfigura em anjo de luz. 2 Coríntios 11:14","id_categoria":"264","id":"5959"},
        {"descricao_categoria":"Pai da Mentira","descricao":"Já não falarei muito convosco, porque se aproxima o príncipe deste mundo, e nada tem em mim; João 14:30","id_categoria":"264","id":"5960"},
        {"descricao_categoria":"Pai da Mentira","descricao":"Disse-lhe Jesus: Eu sou o caminho, e a verdade e a vida; ninguém vem ao Pai, senão por mim. João 14:6","id_categoria":"264","id":"5962"},
        {"descricao_categoria":"Pai da Mentira","descricao":"E, acabada a ceia, tendo já o diabo posto no coração de Judas Iscariotes, filho de Simão, que o traísse, João 13:2","id_categoria":"264","id":"5966"},
        {"descricao_categoria":"Pais e Filhos","descricao":"E vós, pais, não provoqueis à ira a vossos filhos, mas criai-os na doutrina e admoestação do Senhor. Efésios 6:4","id_categoria":"265","id":"5969"},
        {"descricao_categoria":"Pais e Filhos","descricao":"O que aflige o seu pai, ou manda embora sua mãe, é filho que traz vergonha e desonra. Provérbios 19:26","id_categoria":"265","id":"5970"},
        {"descricao_categoria":"Pais e Filhos","descricao":"Grandemente se regozijará o pai do justo, e o que gerar um sábio, se alegrará nele. Provérbios 23:24","id_categoria":"265","id":"5971"},
        {"descricao_categoria":"Pais e Filhos","descricao":"Educa a criança no caminho em que deve andar; e até quando envelhecer não se desviará dele. Provérbios 22:6","id_categoria":"265","id":"5972"},
        {"descricao_categoria":"Pais e Filhos","descricao":"Ouve teu pai, que te gerou, e não desprezes tua mãe, quando vier a envelhecer. Provérbios 23:22","id_categoria":"265","id":"5973"},
        {"descricao_categoria":"Pais e Filhos","descricao":"Os pais não morrerão pelos filhos, nem os filhos pelos pais; cada um morrerá pelo seu pecado. Deuteronômio 24:16","id_categoria":"265","id":"5975"},
        {"descricao_categoria":"Pais e Filhos","descricao":"A coroa dos velhos são os filhos dos filhos; e a glória dos filhos são seus pais. Provérbios 17:6","id_categoria":"265","id":"5976"},
        {"descricao_categoria":"Pais e Filhos","descricao":"O que amaldiçoa seu pai ou sua mãe, apagar-se-á a sua lâmpada em negras trevas. Provérbios 20:20","id_categoria":"265","id":"5978"},
        {"descricao_categoria":"Pais e Filhos","descricao":"Honra a teu pai e a tua mãe, para que se prolonguem os teus dias na terra que o Senhor teu Deus te dá. Êxodo 20:12","id_categoria":"265","id":"5979"},
        {"descricao_categoria":"Pais e Filhos","descricao":"A vara e a repreensão dão sabedoria, mas a criança entregue a si mesma, envergonha a sua mãe. Provérbios 29:15","id_categoria":"265","id":"5980"},
        {"descricao_categoria":"Pais","descricao":"A coroa dos velhos são os filhos dos filhos; e a glória dos filhos são seus pais. Provérbios 17:6","id_categoria":"266","id":"6038"},
        {"descricao_categoria":"Pais","descricao":"O que amaldiçoa seu pai ou sua mãe, apagar-se-á a sua lâmpada em negras trevas. Provérbios 20:20","id_categoria":"266","id":"6039"},
        {"descricao_categoria":"Pais","descricao":"Jesus respondeu, e disse-lhe: Se alguém me ama, guardará a minha palavra, e meu Pai o amará, e viremos para ele, e faremos nele morada. João 14:23","id_categoria":"266","id":"6040"},
        {"descricao_categoria":"Paixão","descricao":"Amados, peço-vos, como a peregrinos e forasteiros, que vos abstenhais das concupiscências carnais, que combatem contra a alma; 1 Pedro 2:11","id_categoria":"267","id":"6042"},
        {"descricao_categoria":"Paixão","descricao":"E os que são de Cristo crucificaram a carne com as suas paixões e concupiscências. Gálatas 5:24","id_categoria":"267","id":"6044"},
        {"descricao_categoria":"Paixão","descricao":"Portanto, quer comais quer bebais, ou façais outra qualquer coisa, fazei tudo para glória de Deus. 1 Coríntios 10:31","id_categoria":"267","id":"6045"},
        {"descricao_categoria":"Paixão","descricao":"Mas, se não podem conter-se, casem-se. Porque é melhor casar do que abrasar-se. 1 Coríntios 7:9","id_categoria":"267","id":"6048"},
        {"descricao_categoria":"Paixão","descricao":"Pedis, e não recebeis, porque pedis mal, para o gastardes em vossos deleites. Tiago 4:3","id_categoria":"267","id":"6049"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"Sejam vossos costumes sem avareza, contentando-vos com o que tendes; porque ele disse: Não te deixarei, nem te desampararei. Hebreus 13:5","id_categoria":"268","id":"6051"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"Porque, como as aflições de Cristo são abundantes em nós, assim também é abundante a nossa consolação por meio de Cristo. 2 Coríntios 1:5","id_categoria":"268","id":"6053"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"Porque não desprezou nem abominou a aflição do aflito, nem escondeu dele o seu rosto; antes, quando ele clamou, o ouviu. Salmos 22:24","id_categoria":"268","id":"6054"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"O Senhor é bom, ele serve de fortaleza no dia da angústia, e conhece os que confiam nele. Naum 1:7","id_categoria":"268","id":"6055"},
        {"descricao_categoria":"Palavras de Consolo","descricao":"Ainda que eu andasse pelo vale da sombra da morte, não temeria mal algum, porque tu estás comigo; a tua vara e o teu cajado me consolam. Salmos 23:4","id_categoria":"269","id":"6100"},
        {"descricao_categoria":"Palavras de Consolo","descricao":"Mostra-me um sinal para bem, para que o vejam aqueles que me odeiam, e se confundam; porque tu, Senhor, me ajudaste e me consolaste. Salmos 86:17","id_categoria":"269","id":"6101"},
        {"descricao_categoria":"Palavras de Consolo","descricao":"Sirva pois a tua benignidade para me consolar, segundo a palavra que deste ao teu servo. Salmos 119:76","id_categoria":"269","id":"6102"},
        {"descricao_categoria":"Palavras de Consolo","descricao":"O Senhor será também um alto refúgio para o oprimido; um alto refúgio em tempos de angústia. Salmos 9:9","id_categoria":"269","id":"6104"},
        {"descricao_categoria":"Palavras de Consolo","descricao":"O SENHOR é a minha luz e a minha salvação; a quem temerei? O SENHOR é a força da minha vida; de quem me recearei? Salmos 27:1","id_categoria":"269","id":"6105"},
        {"descricao_categoria":"Palavras de Consolo","descricao":"Pois tu és a minha esperança, Senhor DEUS; tu és a minha confiança desde a minha mocidade. Salmos 71:5","id_categoria":"269","id":"6106"},
        {"descricao_categoria":"Pão","descricao":"Porque todas as vezes que comerdes este pão e beberdes este cálice anunciais a morte do Senhor, até que venha. 1 Coríntios 11:26","id_categoria":"270","id":"6109"},
        {"descricao_categoria":"Pão","descricao":"O que vê com bons olhos será abençoado, porque dá do seu pão ao pobre. Provérbios 22:9","id_categoria":"270","id":"6112"},
        {"descricao_categoria":"Pão","descricao":"Fui moço, e agora sou velho; mas nunca vi desamparado o justo, nem a sua semente a mendigar o pão. Salmos 37:25","id_categoria":"270","id":"6114"},
        {"descricao_categoria":"Pão","descricao":"Ele, porém, respondendo, disse: Está escrito: Nem só de pão viverá o homem, mas de toda a palavra que sai da boca de Deus. Mateus 4:4","id_categoria":"270","id":"6118"},
        {"descricao_categoria":"Pão","descricao":"E chamou a casa de Israel o seu nome maná; e era como semente de coentro branco, e o seu sabor como bolos de mel. Êxodo 16:31","id_categoria":"270","id":"6119"},
        {"descricao_categoria":"Páscoa","descricao":"E por cima da sua cabeça puseram escrita a sua acusação: este e jesus, o rei dos judeus. Mateus 27:37","id_categoria":"271","id":"6125"},
        {"descricao_categoria":"Páscoa","descricao":"E, quando Jesus tomou o vinagre, disse: Está consumado. E, inclinando a cabeça, entregou o espírito. João 19:30","id_categoria":"271","id":"6127"},
        {"descricao_categoria":"Páscoa","descricao":"E, clamando Jesus com grande voz, disse: Pai, nas tuas mãos entrego o meu espírito. E, havendo dito isto, expirou. Lucas 23:46","id_categoria":"271","id":"6134"},
        {"descricao_categoria":"Paz","descricao":"Tu conservarás em paz aquele cuja mente está firme em ti; porque ele confia em ti. Isaías 26:3","id_categoria":"272","id":"6175"},
        {"descricao_categoria":"Paz","descricao":"Não cuideis que vim trazer a paz à terra; não vim trazer paz, mas espada; Mateus 10:34","id_categoria":"272","id":"6176"},
        {"descricao_categoria":"Paz","descricao":"Os profetas de Israel, que profetizam acerca de Jerusalém, e vêem para ela visão de paz, não havendo paz, diz o Senhor DEUS. Ezequiel 13:16","id_categoria":"272","id":"6177"},
        {"descricao_categoria":"Paz","descricao":"E ele lhe disse: Filha, a tua fé te salvou; vai em paz, e sê curada deste teu mal. Marcos 5:34","id_categoria":"272","id":"6178"},
        {"descricao_categoria":"Paz","descricao":"Porque a inclinação da carne é morte; mas a inclinação do Espírito é vida e paz. Romanos 8:6","id_categoria":"272","id":"6179"},
        {"descricao_categoria":"Paz","descricao":"Sendo os caminhos do homem agradáveis ao Senhor, até a seus inimigos faz que tenham paz com ele. Provérbios 16:7","id_categoria":"272","id":"6181"},
        {"descricao_categoria":"Paz","descricao":"E tu irás a teus pais em paz; em boa velhice serás sepultado. Gênesis 15:15","id_categoria":"272","id":"6186"},
        {"descricao_categoria":"Paz","descricao":"Mas o fruto do Espírito é: amor, gozo, paz, longanimidade, benignidade, bondade, fé, mansidão, temperança. Gálatas 5:22","id_categoria":"272","id":"6187"},
        {"descricao_categoria":"Paz","descricao":"Eis que eu trarei a ela saúde e cura, e os sararei, e lhes manifestarei abundância de paz e de verdade. Jeremias 33:6","id_categoria":"272","id":"6190"},
        {"descricao_categoria":"Paz","descricao":"Graça e paz da parte de Deus nosso Pai, e do Senhor Jesus Cristo. 1 Coríntios 1:3","id_categoria":"272","id":"6191"},
        {"descricao_categoria":"Paz","descricao":"Nota o homem sincero, e considera o reto, porque o fim desse homem é a paz. Salmos 37:37","id_categoria":"272","id":"6192"},
        {"descricao_categoria":"Paz","descricao":"Aparte-se do mal, e faça o bem; Busque a paz, e siga-a. 1 Pedro 3:11","id_categoria":"272","id":"6193"},
        {"descricao_categoria":"Paz","descricao":"Dizendo: Bendito o Rei que vem em nome do Senhor; paz no céu, e glória nas alturas. Lucas 19:38","id_categoria":"272","id":"6194"},
        {"descricao_categoria":"Paz","descricao":"Para iluminar aos que estão assentados em trevas e na sombra da morte; A fim de dirigir os nossos pés pelo caminho da paz. Lucas 1:79","id_categoria":"272","id":"6195"},
        {"descricao_categoria":"Paz","descricao":"E curam superficialmente a ferida da filha do meu povo, dizendo: Paz, paz; quando não há paz. Jeremias 6:14","id_categoria":"272","id":"6198"},
        {"descricao_categoria":"Paz","descricao":"E todos os teus filhos serão ensinados do Senhor; e a paz de teus filhos será abundante. Isaías 54:13","id_categoria":"272","id":"6200"},
        {"descricao_categoria":"Paz","descricao":"E a todos quantos andarem conforme esta regra, paz e misericórdia sobre eles e sobre o Israel de Deus. Gálatas 6:16","id_categoria":"272","id":"6201"},
        {"descricao_categoria":"Paz","descricao":"Aquietai-vos, e sabei que eu sou Deus; serei exaltado entre os gentios; serei exaltado sobre a terra. Salmos 46:10","id_categoria":"272","id":"6202"},
        {"descricao_categoria":"Paz","descricao":"De outra maneira, estando o outro ainda longe, manda embaixadores, e pede condições de paz. Lucas 14:32","id_categoria":"272","id":"6203"},
        {"descricao_categoria":"Paz","descricao":"E calçados os pés na preparação do evangelho da paz; Efésios 6:15","id_categoria":"272","id":"6204"},
        {"descricao_categoria":"Paz","descricao":"E disse à mulher: A tua fé te salvou; vai-te em paz. Lucas 7:50","id_categoria":"272","id":"6205"},
        {"descricao_categoria":"Paz","descricao":"E o Deus de paz seja com todos vós. Amém. Romanos 15:33","id_categoria":"272","id":"6206"},
        {"descricao_categoria":"Paz","descricao":"Porque Deus não é Deus de confusão, senão de paz, como em todas as igrejas dos santos. 1 Coríntios 14:33","id_categoria":"272","id":"6207"},
        {"descricao_categoria":"Paz","descricao":"Em seus caminhos há destruição e miséria; E não conheceram o caminho da paz. Não há temor de Deus diante de seus olhos. Romanos 3:16-18","id_categoria":"272","id":"6208"},
        {"descricao_categoria":"Pecado","descricao":"E dará à luz um filho e chamarás o seu nome JESUS; porque ele salvará o seu povo dos seus pecados. Mateus 1:21","id_categoria":"273","id":"6228"},
        {"descricao_categoria":"Pecado","descricao":"Porque, se perdoardes aos homens as suas ofensas, também vosso Pai celestial vos perdoará a vós; Mateus 6:14","id_categoria":"273","id":"6230"},
        {"descricao_categoria":"Pecado","descricao":"Quem pode entender os seus erros? Expurga-me tu dos que me são ocultos. Salmos 19:12","id_categoria":"273","id":"6231"},
        {"descricao_categoria":"Pecado","descricao":"Respondeu-lhes Jesus: Em verdade, em verdade vos digo que todo aquele que comete pecado é servo do pecado. João 8:34","id_categoria":"273","id":"6233"},
        {"descricao_categoria":"Pecado","descricao":"Por amor do teu nome, Senhor, perdoa a minha iniqüidade, pois é grande. Salmos 25:11","id_categoria":"273","id":"6234"},
        {"descricao_categoria":"Pecado","descricao":"Qualquer que comete pecado, também comete iniqüidade; porque o pecado é iniqüidade. 1 João 3:4","id_categoria":"273","id":"6236"},
        {"descricao_categoria":"Pecado","descricao":"Sabemos que todo aquele que é nascido de Deus não peca; mas o que de Deus é gerado conserva-se a si mesmo, e o maligno não lhe toca. 1 João 5:18","id_categoria":"273","id":"6241"},
        {"descricao_categoria":"Pecado","descricao":"Se dissermos que temos comunhão com ele, e andarmos em trevas, mentimos, e não praticamos a verdade. 1 João 1:6","id_categoria":"273","id":"6243"},
        {"descricao_categoria":"Pecado","descricao":"Chegai-vos a Deus, e ele se chegará a vós. Alimpai as mãos, pecadores; e, vós de duplo ânimo, purificai os corações. Tiago 4:8","id_categoria":"273","id":"6247"},
        {"descricao_categoria":"Pecado","descricao":"Por isso nenhuma carne será justificada diante dele pelas obras da lei, porque pela lei vem o conhecimento do pecado. Romanos 3:20","id_categoria":"273","id":"6249"},
        {"descricao_categoria":"Pecado","descricao":"Pois que? Pecaremos porque não estamos debaixo da lei, mas debaixo da graça? De modo nenhum. Romanos 6:15","id_categoria":"273","id":"6251"},
        {"descricao_categoria":"Pecado","descricao":"Ora, se teu irmão pecar contra ti, vai, e repreende-o entre ti e ele só; se te ouvir, ganhaste a teu irmão; Mateus 18:15","id_categoria":"273","id":"6253"},
        {"descricao_categoria":"Pecado","descricao":"Mas a fornicação, e toda a impureza ou avareza, nem ainda se nomeie entre vós, como convém a santos; Efésios 5:3","id_categoria":"273","id":"6256"},
        {"descricao_categoria":"Pensamentos","descricao":"Todo caminho do homem é reto aos seus olhos, mas o Senhor sonda os corações. Provérbios 21:2","id_categoria":"274","id":"6278"},
        {"descricao_categoria":"Pensamentos","descricao":"Pondera a vereda de teus pés, e todos os teus caminhos sejam bem ordenados! Provérbios 4:26","id_categoria":"274","id":"6279"},
        {"descricao_categoria":"Pensamentos","descricao":"Porque os meus pensamentos não são os vossos pensamentos, nem os vossos caminhos os meus caminhos, diz o Senhor. Isaías 55:8","id_categoria":"274","id":"6280"},
        {"descricao_categoria":"Pensamentos","descricao":"SENHOR, tu me sondaste, e me conheces. Tu sabes o meu assentar e o meu levantar; de longe entendes o meu pensamento. Salmos 139:1,2","id_categoria":"274","id":"6281"},
        {"descricao_categoria":"Pensamentos","descricao":"Fiz aliança com os meus olhos; como, pois, os fixaria numa virgem? Jó 31:1","id_categoria":"274","id":"6282"},
        {"descricao_categoria":"Pensamentos","descricao":"Ninguém se engane a si mesmo. Se alguém dentre vós se tem por sábio neste mundo, faça-se louco para ser sábio. 1 Coríntios 3:18","id_categoria":"274","id":"6287"},
        {"descricao_categoria":"Pensamentos","descricao":"Há um caminho que ao homem parece direito, mas o fim dele são os caminhos da morte. Provérbios 14:12","id_categoria":"274","id":"6288"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Antes sede uns para com os outros benignos, misericordiosos, perdoando-vos uns aos outros, como também Deus vos perdoou em Cristo. Efésios 4:32","id_categoria":"275","id":"6320"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Porque, se perdoardes aos homens as suas ofensas, também vosso Pai celestial vos perdoará a vós; Mateus 6:14","id_categoria":"275","id":"6321"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Aquele que encobre a transgressão busca a amizade, mas o que revolve o assunto separa os maiores amigos. Provérbios 17:9","id_categoria":"275","id":"6322"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"O que encobre as suas transgressões nunca prosperará, mas o que as confessa e deixa, alcançará misericórdia. Provérbios 28:13","id_categoria":"275","id":"6323"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"E ele é a propiciação pelos nossos pecados, e não somente pelos nossos, mas também pelos de todo o mundo. 1 João 2:2","id_categoria":"275","id":"6328"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Se confessarmos os nossos pecados, ele é fiel e justo para nos perdoar os pecados, e nos purificar de toda a injustiça. 1 João 1:9","id_categoria":"275","id":"6329"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Assim como está longe o oriente do ocidente, assim afasta de nós as nossas transgressões. Salmos 103:12","id_categoria":"275","id":"6331"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Bem-aventurados os misericordiosos, porque eles alcançarão misericórdia; Mateus 5:7","id_categoria":"275","id":"6332"},
        {"descricao_categoria":"Perseguição","descricao":"Os meus tempos estão nas tuas mãos; livra-me das mãos dos meus inimigos e dos que me perseguem. Salmos 31:15","id_categoria":"276","id":"6333"},
        {"descricao_categoria":"Perseguição","descricao":"Ainda que eu andasse pelo vale da sombra da morte, não temeria mal algum, porque tu estás comigo; a tua vara e o teu cajado me consolam. Salmos 23:4","id_categoria":"276","id":"6335"},
        {"descricao_categoria":"Perseguição","descricao":"Sede sóbrios; vigiai; porque o diabo, vosso adversário, anda em derredor, bramando como leão, buscando a quem possa tragar; 1 Pedro 5:8","id_categoria":"276","id":"6337"},
        {"descricao_categoria":"Perseguição","descricao":"Se o mundo vos odeia, sabei que, primeiro do que a vós, me odiou a mim. João 15:18","id_categoria":"276","id":"6338"},
        {"descricao_categoria":"Perseguição","descricao":"Abençoai aos que vos perseguem, abençoai, e não amaldiçoeis. Romanos 12:14","id_categoria":"276","id":"6339"},
        {"descricao_categoria":"Planejamento","descricao":"Tudo tem o seu tempo determinado, e há tempo para todo o propósito debaixo do céu. Eclesiastes 3:1","id_categoria":"277","id":"6351"},
        {"descricao_categoria":"Planejamento","descricao":"Não te fatigues para enriqueceres; e não apliques nisso a tua sabedoria. Provérbios 23:4","id_categoria":"277","id":"6353"},
        {"descricao_categoria":"Planejamento","descricao":"Certamente o Senhor DEUS não fará coisa alguma, sem ter revelado o seu segredo aos seus servos, os profetas. Amós 3:7","id_categoria":"277","id":"6354"},
        {"descricao_categoria":"Planejamento","descricao":"Quando não há conselhos os planos se dispersam, mas havendo muitos conselheiros eles se firmam. Provérbios 15:22","id_categoria":"277","id":"6356"},
        {"descricao_categoria":"Planejamento","descricao":"Pois qual de vós, querendo edificar uma torre, não se assenta primeiro a fazer as contas dos gastos, para ver se tem com que a acabar? Lucas 14:28","id_categoria":"277","id":"6357"},
        {"descricao_categoria":"Planejamento","descricao":"O coração do homem planeja o seu caminho, mas o Senhor lhe dirige os passos. Provérbios 16:9","id_categoria":"277","id":"6358"},
        {"descricao_categoria":"Planejamento","descricao":"Até isto procede do Senhor dos Exércitos; porque é maravilhoso em conselho e grande em obra. Isaías 28:29","id_categoria":"277","id":"6361"},
        {"descricao_categoria":"Planejamento","descricao":"Mas Deus no-las revelou pelo seu Espírito; porque o Espírito penetra todas as coisas, ainda as profundezas de Deus. 1 Coríntios 2:10","id_categoria":"277","id":"6362"},
        {"descricao_categoria":"Planejamento","descricao":"Confia ao Senhor as tuas obras, e teus pensamentos serão estabelecidos. Provérbios 16:3","id_categoria":"277","id":"6363"},
        {"descricao_categoria":"Poder de Decisão","descricao":"Porque o Senhor DEUS me ajuda, assim não me confundo; por isso pus o meu rosto como um seixo, porque sei que não serei envergonhado. Isaías 50:7","id_categoria":"278","id":"6366"},
        {"descricao_categoria":"Poder de Decisão","descricao":"Se alguém quiser fazer a vontade dele, pela mesma doutrina conhecerá se ela é de Deus, ou se eu falo de mim mesmo. João 7:17","id_categoria":"278","id":"6367"},
        {"descricao_categoria":"Poder de Decisão","descricao":"A sorte se lança no regaço, mas do Senhor procede toda a determinação. Provérbios 16:33","id_categoria":"278","id":"6368"},
        {"descricao_categoria":"Poder de Deus","descricao":"Deus falou uma vez; duas vezes ouvi isto: que o poder pertence a Deus. Salmos 62:11","id_categoria":"279","id":"6387"},
        {"descricao_categoria":"Poder de Deus","descricao":"No demais, irmãos meus, fortalecei-vos no Senhor e na força do seu poder. Efésios 6:10","id_categoria":"279","id":"6388"},
        {"descricao_categoria":"Poder de Deus","descricao":"Na palavra da verdade, no poder de Deus, pelas armas da justiça, à direita e à esquerda, 2 Coríntios 6:7","id_categoria":"279","id":"6390"},
        {"descricao_categoria":"Poder de Deus","descricao":"Com mão forte, e com braço estendido; porque a sua benignidade dura para sempre; Salmos 136:12","id_categoria":"279","id":"6391"},
        {"descricao_categoria":"Poder de Deus","descricao":"Ele fez a terra com o seu poder; ele estabeleceu o mundo com a sua sabedoria, e com a sua inteligência estendeu os céus. Jeremias 10:12","id_categoria":"279","id":"6392"},
        {"descricao_categoria":"Poder de Deus","descricao":"E Jesus, olhando para eles, disse-lhes: Aos homens é isso impossível, mas a Deus tudo é possível. Mateus 19:26","id_categoria":"279","id":"6393"},
        {"descricao_categoria":"Poder","descricao":"Corroborados em toda a fortaleza, segundo a força da sua glória, em toda a paciência, e longanimidade com gozo; Colossenses 1:11","id_categoria":"280","id":"6394"},
        {"descricao_categoria":"Poder","descricao":"Posso todas as coisas em Cristo que me fortalece. Filipenses 4:13","id_categoria":"280","id":"6396"},
        {"descricao_categoria":"Poder","descricao":"Porque Deus não nos deu o espírito de temor, mas de fortaleza, e de amor, e de moderação. 2 Timóteo 1:7","id_categoria":"280","id":"6397"},
        {"descricao_categoria":"Preocupação","descricao":"Não se turbe o vosso coração; credes em Deus, crede também em mim. João 14:1","id_categoria":"281","id":"6422"},
        {"descricao_categoria":"Preservação das Escrituras","descricao":"Escondi a tua palavra no meu coração, para eu não pecar contra ti. Salmos 119:11","id_categoria":"282","id":"6424"},
        {"descricao_categoria":"Preservação das Escrituras","descricao":"E conhecer o amor de Cristo, que excede todo o entendimento, para que sejais cheios de toda a plenitude de Deus. Efésios 3:19","id_categoria":"282","id":"6425"},
        {"descricao_categoria":"Preservação das Escrituras","descricao":"Mas que diz? A palavra está junto de ti, na tua boca e no teu coração; esta é a palavra da fé, que pregamos, Romanos 10:8","id_categoria":"282","id":"6431"},
        {"descricao_categoria":"Preservação das Escrituras","descricao":"E o Verbo se fez carne, e habitou entre nós, e vimos a sua glória, como a glória do unigênito do Pai, cheio de graça e de verdade. João 1:14","id_categoria":"282","id":"6435"},
        {"descricao_categoria":"Preservação das Escrituras","descricao":"Seca-se a erva, e cai a flor, porém a palavra de nosso Deus subsiste eternamente. Isaías 40:8","id_categoria":"282","id":"6438"},
        {"descricao_categoria":"Preservação das Escrituras","descricao":"O céu e a terra passarão, mas as minhas palavras não hão de passar. Mateus 24:35","id_categoria":"282","id":"6439"},
        {"descricao_categoria":"Profecia","descricao":"Portanto o mesmo Senhor vos dará um sinal: Eis que a virgem conceberá, e dará à luz um filho, e chamará o seu nome Emanuel. Isaías 7:14","id_categoria":"283","id":"6443"},
        {"descricao_categoria":"Profecia","descricao":"Filhinhos, sois de Deus, e já os tendes vencido; porque maior é o que está em vós do que o que está no mundo. 1 João 4:4","id_categoria":"283","id":"6444"},
        {"descricao_categoria":"Profecia","descricao":"Portanto, tudo o que vós quereis que os homens vos façam, fazei-lho também vós, porque esta é a lei e os profetas. Mateus 7:12","id_categoria":"283","id":"6445"},
        {"descricao_categoria":"Profecia","descricao":"Examinai tudo. Retende o bem. Abstende-vos de toda a aparência do mal. 1 Tessalonicenses 5:21,22","id_categoria":"283","id":"6448"},
        {"descricao_categoria":"Profecia","descricao":"Certamente o Senhor DEUS não fará coisa alguma, sem ter revelado o seu segredo aos seus servos, os profetas. Amós 3:7","id_categoria":"283","id":"6450"},
        {"descricao_categoria":"Profecia","descricao":"Porque a profecia nunca foi produzida por vontade de homem algum, mas os homens santos de Deus falaram inspirados pelo Espírito Santo. 2 Pedro 1:21","id_categoria":"283","id":"6451"},
        {"descricao_categoria":"Profecia","descricao":"Acautelai-vos, porém, dos falsos profetas, que vêm até vós vestidos como ovelhas, mas, interiormente, são lobos devoradores. Mateus 7:15","id_categoria":"283","id":"6455"},
        {"descricao_categoria":"Promessas","descricao":"Fiel é o que vos chama, o qual também o fará. 1 Tessalonicenses 5:24","id_categoria":"284","id":"6458"},
        {"descricao_categoria":"Promessas","descricao":"Pedi, e dar-se-vos-á; buscai, e encontrareis; batei, e abrir-se-vos-á. Mateus 7:7","id_categoria":"284","id":"6463"},
        {"descricao_categoria":"Promessas","descricao":"Retenhamos firmes a confissão da nossa esperança; porque fiel é o que prometeu. Hebreus 10:23","id_categoria":"284","id":"6467"},
        {"descricao_categoria":"Promessas","descricao":"Mas a Escritura encerrou tudo debaixo do pecado, para que a promessa pela fé em Jesus Cristo fosse dada aos crentes. Gálatas 3:22","id_categoria":"284","id":"6469"},
        {"descricao_categoria":"Promessas","descricao":"Eis que estou à porta, e bato; se alguém ouvir a minha voz, e abrir a porta, entrarei em sua casa, e com ele cearei, e ele comigo. Apocalipse 3:20","id_categoria":"284","id":"6472"},
        {"descricao_categoria":"Promessas","descricao":"O meu arco tenho posto nas nuvens; este será por sinal da aliança entre mim e a terra. Gênesis 9:13","id_categoria":"284","id":"6476"},
        {"descricao_categoria":"Protecção e Segurança","descricao":"Posso todas as coisas em Cristo que me fortalece. Filipenses 4:13","id_categoria":"285","id":"6576"},
        {"descricao_categoria":"Protecção e Segurança","descricao":"Não durmamos, pois, como os demais, mas vigiemos, e sejamos sóbrios; 1 Tessalonicenses 5:6","id_categoria":"285","id":"6577"},
        {"descricao_categoria":"Protecção e Segurança","descricao":"Porque satisfiz a alma cansada, e toda a alma entristecida saciei. Jeremias 31:25","id_categoria":"285","id":"6578"},
        {"descricao_categoria":"Proximidade","descricao":"Buscai ao Senhor e a sua força; buscai a sua face continuamente. Salmos 105:4","id_categoria":"286","id":"6601"},
        {"descricao_categoria":"Proximidade","descricao":"Buscai ao Senhor e a sua força; buscai a sua face continuamente. 1 Crônicas 16:11","id_categoria":"286","id":"6602"},
        {"descricao_categoria":"Proximidade","descricao":"Buscai ao Senhor enquanto se pode achar, invocai-o enquanto está perto. Isaías 55:6","id_categoria":"286","id":"6603"},
        {"descricao_categoria":"Proximidade","descricao":"O Senhor nosso Deus seja conosco, como foi com nossos pais; não nos desampare, e não nos deixe. 1 Reis 8:57","id_categoria":"286","id":"6604"},
        {"descricao_categoria":"Proximidade","descricao":"Não vos deixarei órfãos; voltarei para vós. João 14:18","id_categoria":"286","id":"6605"},
        {"descricao_categoria":"Purificação","descricao":"O qual se deu a si mesmo por nós para nos remir de toda a iniqüidade, e purificar para si um povo seu especial, zeloso de boas obras. Tito 2:14","id_categoria":"287","id":"6606"},
        {"descricao_categoria":"Purificação","descricao":"Chegai-vos a Deus, e ele se chegará a vós. Alimpai as mãos, pecadores; e, vós de duplo ânimo, purificai os corações. Tiago 4:8","id_categoria":"287","id":"6609"},
        {"descricao_categoria":"Purificação","descricao":"Cria em mim, ó Deus, um coração puro, e renova em mim um espírito reto. Salmos 51:10","id_categoria":"287","id":"6610"},
        {"descricao_categoria":"Purificação","descricao":"E agora por que te deténs? Levanta-te, e batiza-te, e lava os teus pecados, invocando o nome do Senhor. Atos 22:16","id_categoria":"287","id":"6614"},
        {"descricao_categoria":"Purificação","descricao":"Se confessarmos os nossos pecados, ele é fiel e justo para nos perdoar os pecados, e nos purificar de toda a injustiça. 1 João 1:9","id_categoria":"287","id":"6615"},
        {"descricao_categoria":"Raiva","descricao":"Vede, irmãos, que nunca haja em qualquer de vós um coração mau e infiel, para se apartar do Deus vivo. Hebreus 3:12","id_categoria":"288","id":"6624"},
        {"descricao_categoria":"Raiva","descricao":"Portanto, meus amados irmãos, todo o homem seja pronto para ouvir, tardio para falar, tardio para se irar. Tiago 1:19","id_categoria":"288","id":"6625"},
        {"descricao_categoria":"Raiva","descricao":"Honroso é para o homem desviar-se de questões, mas todo tolo é intrometido. Provérbios 20:3","id_categoria":"288","id":"6626"},
        {"descricao_categoria":"Raiva","descricao":"A resposta branda desvia o furor, mas a palavra dura suscita a ira. Provérbios 15:1","id_categoria":"288","id":"6627"},
        {"descricao_categoria":"Raiva","descricao":"O longânimo é grande em entendimento, mas o que é de espírito impaciente mostra a sua loucura. Provérbios 14:29","id_categoria":"288","id":"6631"},
        {"descricao_categoria":"Raiva","descricao":"O tolo revela todo o seu pensamento, mas o sábio o guarda até o fim. Provérbios 29:11","id_categoria":"288","id":"6632"},
        {"descricao_categoria":"Raiva","descricao":"A ira do insensato se conhece no mesmo dia, mas o prudente encobre a afronta. Provérbios 12:16","id_categoria":"288","id":"6633"},
        {"descricao_categoria":"Raiva","descricao":"O ódio excita contendas, mas o amor cobre todos os pecados. Provérbios 10:12","id_categoria":"288","id":"6634"},
        {"descricao_categoria":"Recompensa","descricao":"E se fizerdes bem aos que vos fazem bem, que recompensa tereis? Também os pecadores fazem o mesmo. Lucas 6:33","id_categoria":"289","id":"6636"},
        {"descricao_categoria":"Recompensa","descricao":"Porque o salário do pecado é a morte, mas o dom gratuito de Deus é a vida eterna, por Cristo Jesus nosso Senhor. Romanos 6:23","id_categoria":"289","id":"6638"},
        {"descricao_categoria":"Recompensa","descricao":"E o seu senhor lhe disse: Bem está, servo bom e fiel. Sobre o pouco foste fiel, sobre muito te colocarei; entra no gozo do teu senhor. Mateus 25:21","id_categoria":"289","id":"6639"},
        {"descricao_categoria":"Reconhecer","descricao":"A saber: Se com a tua boca confessares ao Senhor Jesus, e em teu coração creres que Deus o ressuscitou dentre os mortos, serás salvo. Romanos 10:9","id_categoria":"290","id":"6684"},
        {"descricao_categoria":"Reconhecer","descricao":"A minha porção é o Senhor, diz a minha alma; portanto esperarei nele. Lamentações 3:24","id_categoria":"290","id":"6685"},
        {"descricao_categoria":"Reconhecer","descricao":"E toda a língua confesse que Jesus Cristo é o Senhor, para glória de Deus Pai. Filipenses 2:11","id_categoria":"290","id":"6686"},
        {"descricao_categoria":"Reconhecer","descricao":"Quem é este Rei da Glória? O Senhor dos Exércitos, ele é o Rei da Glória. (Selá.) Salmos 24:10","id_categoria":"290","id":"6687"},
        {"descricao_categoria":"Reconhecer","descricao":"Porque está escrito:Como eu vivo, diz o Senhor, que todo o joelho se dobrará a mim,E toda a língua confessará a Deus. Romanos 14:11","id_categoria":"290","id":"6688"},
        {"descricao_categoria":"Reconhecer","descricao":"Visto que com o coração se crê para a justiça, e com a boca se faz confissão para a salvação. Romanos 10:10","id_categoria":"290","id":"6689"},
        {"descricao_categoria":"Redentor","descricao":"Em ti, SENHOR, confio; nunca me deixes confundido. Livra-me pela tua justiça. Salmos 31:1","id_categoria":"291","id":"6691"},
        {"descricao_categoria":"Redentor","descricao":"Vós, que amais ao Senhor, odiai o mal. Ele guarda as almas dos seus santos; ele os livra das mãos dos ímpios. Salmos 97:10","id_categoria":"291","id":"6692"},
        {"descricao_categoria":"Redentor","descricao":"O qual se deu a si mesmo por nós para nos remir de toda a iniqüidade, e purificar para si um povo seu especial, zeloso de boas obras. Tito 2:14","id_categoria":"291","id":"6693"},
        {"descricao_categoria":"Redentor","descricao":"Apaguei as tuas transgressões como a névoa, e os teus pecados como a nuvem; torna-te para mim, porque eu te remi. Isaías 44:22","id_categoria":"291","id":"6694"},
        {"descricao_categoria":"Redentor","descricao":"Muitas são as aflições do justo, mas o Senhor o livra de todas. Salmos 34:19","id_categoria":"291","id":"6695"},
        {"descricao_categoria":"Redentor","descricao":"Estai, pois, firmes na liberdade com que Cristo nos libertou, e não torneis a colocar-vos debaixo do jugo da servidão. Gálatas 5:1","id_categoria":"291","id":"6696"},
        {"descricao_categoria":"Redentor","descricao":"Senhor, livra a minha alma dos lábios mentirosos e da língua enganadora. Salmos 120:2","id_categoria":"291","id":"6697"},
        {"descricao_categoria":"Redentor","descricao":"E dará à luz um filho e chamarás o seu nome JESUS; porque ele salvará o seu povo dos seus pecados. Mateus 1:21","id_categoria":"291","id":"6699"},
        {"descricao_categoria":"Redentor","descricao":"Se, pois, o Filho vos libertar, verdadeiramente sereis livres. João 8:36","id_categoria":"291","id":"6702"},
        {"descricao_categoria":"Redentor","descricao":"Mas agora, libertados do pecado, e feitos servos de Deus, tendes o vosso fruto para santificação, e por fim a vida eterna. Romanos 6:22","id_categoria":"291","id":"6703"},
        {"descricao_categoria":"Redentor","descricao":"Os meus tempos estão nas tuas mãos; livra-me das mãos dos meus inimigos e dos que me perseguem. Salmos 31:15","id_categoria":"291","id":"6705"},
        {"descricao_categoria":"Redentor","descricao":"Ajuda-nos, ó Deus da nossa salvação, pela glória do teu nome; e livra-nos, e perdoa os nossos pecados por amor do teu nome. Salmos 79:9","id_categoria":"291","id":"6706"},
        {"descricao_categoria":"Redentor","descricao":"Os meus lábios exultarão quando eu te cantar, assim como a minha alma, que tu remiste. Salmos 71:23","id_categoria":"291","id":"6708"},
        {"descricao_categoria":"Reino dos Céus","descricao":"E o Senhor me livrará de toda a má obra, e guardar-me-á para o seu reino celestial; a quem seja glória para todo o sempre. Amém. 2 Timóteo 4:18","id_categoria":"292","id":"6711"},
        {"descricao_categoria":"Reino dos Céus","descricao":"Nem todo o que me diz: Senhor, Senhor! entrará no reino dos céus, mas aquele que faz a vontade de meu Pai, que está nos céus. Mateus 7:21","id_categoria":"292","id":"6712"},
        {"descricao_categoria":"Reino dos Céus","descricao":"Mas eu vos digo que muitos virão do oriente e do ocidente, e assentar-se-ão à mesa com Abraão, e Isaque, e Jacó, no reino dos céus; Mateus 8:11","id_categoria":"292","id":"6716"},
        {"descricao_categoria":"Reino dos Céus","descricao":"Jesus, porém, disse: Deixai os meninos, e não os estorveis de vir a mim; porque dos tais é o reino dos céus. Mateus 19:14","id_categoria":"292","id":"6720"},
        {"descricao_categoria":"Reino dos Céus","descricao":"Desde então começou Jesus a pregar, e a dizer: Arrependei-vos, porque é chegado o reino dos céus. Mateus 4:17","id_categoria":"292","id":"6726"},
        {"descricao_categoria":"Reino dos Céus","descricao":"Não temais, ó pequeno rebanho, porque a vosso Pai agradou dar-vos o reino. Lucas 12:32","id_categoria":"292","id":"6731"},
        {"descricao_categoria":"Reino dos Céus","descricao":"Mas os santos do Altíssimo receberão o reino, e o possuirão para todo o sempre, e de eternidade em eternidade. Daniel 7:18","id_categoria":"292","id":"6733"},
        {"descricao_categoria":"Reino dos Céus","descricao":"E, indo, pregai, dizendo: É chegado o reino dos céus. Mateus 10:7","id_categoria":"292","id":"6734"},
        {"descricao_categoria":"Reino dos Céus","descricao":"Porque bem sabeis isto: que nenhum devasso, ou impuro, ou avarento, o qual é idólatra, tem herança no reino de Cristo e de Deus. Efésios 5:5","id_categoria":"292","id":"6735"},
        {"descricao_categoria":"Reino","descricao":"E curai os enfermos que nela houver, e dizei-lhes: É chegado a vós o reino de Deus. Lucas 10:9","id_categoria":"293","id":"6738"},
        {"descricao_categoria":"Reino","descricao":"Porque o Senhor é o nosso Juiz; o Senhor é o nosso legislador; o Senhor é o nosso rei, ele nos salvará. Isaías 33:22","id_categoria":"293","id":"6740"},
        {"descricao_categoria":"Relacionamentos","descricao":"Mas, se alguém não tem cuidado dos seus, e principalmente dos da sua família, negou a fé, e é pior do que o infiel. 1 Timóteo 5:8","id_categoria":"294","id":"6757"},
        {"descricao_categoria":"Relacionamentos","descricao":"Honra teu pai e tua mãe, e amarás o teu próximo como a ti mesmo. Mateus 19:19","id_categoria":"294","id":"6758"},
        {"descricao_categoria":"Relacionamentos","descricao":"E o Senhor vos aumente, e faça crescer em amor uns para com os outros, e para com todos, como também o fazemos para convosco; 1 Tessalonicenses 3:12","id_categoria":"294","id":"6759"},
        {"descricao_categoria":"Relacionamentos","descricao":"Assim também vós, cada um em particular, ame a sua própria mulher como a si mesmo, e a mulher reverencie o marido. Efésios 5:33","id_categoria":"294","id":"6760"},
        {"descricao_categoria":"Relacionamentos","descricao":"Portanto, tudo o que vós quereis que os homens vos façam, fazei-lho também vós, porque esta é a lei e os profetas. Mateus 7:12","id_categoria":"294","id":"6771"},
        {"descricao_categoria":"Relacionamentos","descricao":"E disse o Senhor Deus: Não é bom que o homem esteja só; far-lhe-ei uma ajudadora idônea para ele. Gênesis 2:18","id_categoria":"294","id":"6774"},
        {"descricao_categoria":"Relacionamentos","descricao":"E, se alguém prevalecer contra um, os dois lhe resistirão; e o cordão de três dobras não se quebra tão depressa. Eclesiastes 4:12","id_categoria":"294","id":"6777"},
        {"descricao_categoria":"Renascimento","descricao":"Tendo por certo isto mesmo, que aquele que em vós começou a boa obra a aperfeiçoará até ao dia de Jesus Cristo; Filipenses 1:6","id_categoria":"295","id":"6779"},
        {"descricao_categoria":"Renascimento","descricao":"Assim que, se alguém está em Cristo, nova criatura é; as coisas velhas já passaram; eis que tudo se fez novo. 2 Coríntios 5:17","id_categoria":"295","id":"6781"},
        {"descricao_categoria":"Renascimento","descricao":"O que é nascido da carne é carne, e o que é nascido do Espírito é espírito. João 3:6","id_categoria":"295","id":"6783"},
        {"descricao_categoria":"Renascimento","descricao":"Porque todo o que é nascido de Deus vence o mundo; e esta é a vitória que vence o mundo, a nossa fé. 1 João 5:4","id_categoria":"295","id":"6784"},
        {"descricao_categoria":"Renascimento","descricao":"Amados, amemo-nos uns aos outros; porque o amor é de Deus; e qualquer que ama é nascido de Deus e conhece a Deus. 1 João 4:7","id_categoria":"295","id":"6785"},
        {"descricao_categoria":"Renascimento","descricao":"Sendo de novo gerados, não de semente corruptível, mas da incorruptível, pela palavra de Deus, viva, e que permanece para sempre. 1 Pedro 1:23","id_categoria":"295","id":"6786"},
        {"descricao_categoria":"Renascimento","descricao":"Jesus respondeu: Na verdade, na verdade te digo que aquele que não nascer da água e do Espírito, não pode entrar no reino de Deus. João 3:5","id_categoria":"295","id":"6787"},
        {"descricao_categoria":"Renascimento","descricao":"Desejai afetuosamente, como meninos novamente nascidos, o leite racional, não falsificado, para que por ele vades crescendo; 1 Pedro 2:2","id_categoria":"295","id":"6788"},
        {"descricao_categoria":"Renascimento","descricao":"Jesus respondeu, e disse-lhe: Na verdade, na verdade te digo que aquele que não nascer de novo, não pode ver o reino de Deus. João 3:3","id_categoria":"295","id":"6789"},
        {"descricao_categoria":"Repouso e Férias","descricao":"Não terás medo do terror de noite nem da seta que voa de dia,. Salmos 91:5","id_categoria":"296","id":"6791"},
        {"descricao_categoria":"Repouso e Férias","descricao":"Doce é o sono do trabalhador, quer coma pouco quer muito; mas a fartura do rico não o deixa dormir. Eclesiastes 5:12","id_categoria":"296","id":"6793"},
        {"descricao_categoria":"Repouso e Férias","descricao":"Entrará em paz; descansarão nas suas camas, os que houverem andado na sua retidão. Isaías 57:2","id_categoria":"296","id":"6794"},
        {"descricao_categoria":"Repouso e Férias","descricao":"Quando te deitares, não temerás; ao contrário, o teu sono será suave ao te deitares. Provérbios 3:24","id_categoria":"296","id":"6795"},
        {"descricao_categoria":"Repouso e Férias","descricao":"Inútil vos será levantar de madrugada, repousar tarde, comer o pão de dores, pois assim dá ele aos seus amados o sono. Salmos 127:2","id_categoria":"296","id":"6796"},
        {"descricao_categoria":"Repouso e Férias","descricao":"Em paz também me deitarei e dormirei, porque só tu, Senhor, me fazes habitar em segurança. Salmos 4:8","id_categoria":"296","id":"6797"},
        {"descricao_categoria":"Repouso e Férias","descricao":"Eu me deitei e dormi; acordei, porque o Senhor me sustentou. Salmos 3:5","id_categoria":"296","id":"6798"},
        {"descricao_categoria":"Repouso e Férias","descricao":"Portanto, quer comais quer bebais, ou façais outra qualquer coisa, fazei tudo para glória de Deus. 1 Coríntios 10:31","id_categoria":"296","id":"6800"},
        {"descricao_categoria":"Resistir","descricao":"E, convocando os seus doze discípulos, deu-lhes virtude e poder sobre todos os demônios, para curarem enfermidades. Lucas 9:1","id_categoria":"297","id":"6803"},
        {"descricao_categoria":"Resistir","descricao":"E voltaram os setenta com alegria, dizendo: Senhor, pelo teu nome, até os demônios se nos sujeitam. Lucas 10:17","id_categoria":"297","id":"6809"},
        {"descricao_categoria":"Resistir","descricao":"Eis que vos dou poder para pisar serpentes e escorpiões, e toda a força do inimigo, e nada vos fará dano algum. Lucas 10:19","id_categoria":"297","id":"6810"},
        {"descricao_categoria":"Resistir","descricao":"Filhinhos, sois de Deus, e já os tendes vencido; porque maior é o que está em vós do que o que está no mundo. 1 João 4:4","id_categoria":"297","id":"6811"},
        {"descricao_categoria":"Resistir","descricao":"Não deis lugar ao diabo. Efésios 4:27","id_categoria":"297","id":"6813"},
        {"descricao_categoria":"Ressurreição","descricao":"Porque assim como a morte veio por um homem, também a ressurreição dos mortos veio por um homem. 1 Coríntios 15:21","id_categoria":"298","id":"6817"},
        {"descricao_categoria":"Ressurreição","descricao":"Porque, se cremos que Jesus morreu e ressuscitou, assim também aos que em Jesus dormem, Deus os tornará a trazer com ele. 1 Tessalonicenses 4:14","id_categoria":"298","id":"6819"},
        {"descricao_categoria":"Ressurreição","descricao":"Para conhecê-lo, e à virtude da sua ressurreição, e à comunicação de suas aflições, sendo feito conforme à sua morte; Filipenses 3:10","id_categoria":"298","id":"6831"},
        {"descricao_categoria":"Roupas","descricao":"E fez o Senhor Deus a Adão e à sua mulher túnicas de peles, e os vestiu. Gênesis 3:21","id_categoria":"299","id":"6834"},
        {"descricao_categoria":"Roupas","descricao":"Então foram abertos os olhos de ambos, e conheceram que estavam nus; e coseram folhas de figueira, e fizeram para si aventais. Gênesis 3:7","id_categoria":"299","id":"6835"},
        {"descricao_categoria":"Roupas","descricao":"Acautelai-vos, porém, dos falsos profetas, que vêm até vós vestidos como ovelhas, mas, interiormente, são lobos devoradores. Mateus 7:15","id_categoria":"299","id":"6838"},
        {"descricao_categoria":"Uma só Carne","descricao":"E criou Deus o homem à sua imagem; à imagem de Deus o criou; homem e mulher os criou. Gênesis 1:27","id_categoria":"300","id":"6847"},
        {"descricao_categoria":"Uma só Carne","descricao":"E disse o Senhor Deus: Não é bom que o homem esteja só; far-lhe-ei uma ajudadora idônea para ele. Gênesis 2:18","id_categoria":"300","id":"6849"},
        {"descricao_categoria":"Uma só Carne","descricao":"Por isso deixai a mentira, e falai a verdade cada um com o seu próximo; porque somos membros uns dos outros. Efésios 4:25","id_categoria":"300","id":"6850"},
        {"descricao_categoria":"Valioso","descricao":"Porque em Jesus Cristo nem a circuncisão nem a incircuncisão tem valor algum; mas sim a fé que opera pelo amor. Gálatas 5:6","id_categoria":"301","id":"6855"},
        {"descricao_categoria":"Valioso","descricao":"Pois, quanto mais vale um homem do que uma ovelha? É, por conseqüência, lícito fazer bem nos sábados. Mateus 12:12","id_categoria":"301","id":"6857"},
        {"descricao_categoria":"Valioso","descricao":"Prata escolhida é a língua do justo; o coração dos perversos é de nenhum valor. Provérbios 10:20","id_categoria":"301","id":"6860"},
        {"descricao_categoria":"Valioso","descricao":"Visto que foste precioso aos meus olhos, também foste honrado, e eu te amei, assim dei os homens por ti, e os povos pela tua vida. Isaías 43:4","id_categoria":"301","id":"6861"},
        {"descricao_categoria":"Valioso","descricao":"Levanta-te, resplandece, porque vem a tua luz, e a glória do SENHOR vai nascendo sobre ti; Isaías 60:1","id_categoria":"301","id":"6863"},
        {"descricao_categoria":"Valioso","descricao":"Mulher virtuosa quem a achará? O seu valor muito excede ao de rubis. Provérbios 31:10","id_categoria":"301","id":"6864"},
        {"descricao_categoria":"Vaso","descricao":"Mas agora, ó Senhor, tu és nosso Pai; nós o barro e tu o nosso oleiro; e todos nós a obra das tuas mãos. Isaías 64:8","id_categoria":"302","id":"6867"},
        {"descricao_categoria":"Vaso","descricao":"Temos, porém, este tesouro em vasos de barro, para que a excelência do poder seja de Deus, e não de nós. 2 Coríntios 4:7","id_categoria":"302","id":"6870"},
        {"descricao_categoria":"Vaso","descricao":"Como o caco de vaso coberto de escórias de prata, assim são os lábios ardentes com o coração maligno. Provérbios 26:23","id_categoria":"302","id":"6872"},
        {"descricao_categoria":"Vaso","descricao":"Os preciosos filhos de Sião, avaliados a puro ouro, como são agora reputados por vasos de barro, obra das mãos do oleiro! Lamentações 4:2","id_categoria":"302","id":"6877"},
        {"descricao_categoria":"Verdade","descricao":"E o Verbo se fez carne, e habitou entre nós, e vimos a sua glória, como a glória do unigênito do Pai, cheio de graça e de verdade. João 1:14","id_categoria":"303","id":"6880"},
        {"descricao_categoria":"Verdade","descricao":"Disse-lhe Jesus: Eu sou o caminho, e a verdade e a vida; ninguém vem ao Pai, senão por mim. João 14:6","id_categoria":"303","id":"6881"},
        {"descricao_categoria":"Verdade","descricao":"Os lábios do justo sabem o que agrada, mas a boca dos perversos, só perversidades. Provérbios 10:32","id_categoria":"303","id":"6882"},
        {"descricao_categoria":"Verdade","descricao":"Meus filhinhos, não amemos de palavra, nem de língua, mas por obra e em verdade. 1 João 3:18","id_categoria":"303","id":"6884"},
        {"descricao_categoria":"Verdade","descricao":"Procura apresentar-te a Deus aprovado, como obreiro que não tem de que se envergonhar, que maneja bem a palavra da verdade. 2 Timóteo 2:15","id_categoria":"303","id":"6885"},
        {"descricao_categoria":"Verdade","descricao":"Desvia de ti a falsidade da boca, e afasta de ti a perversidade dos lábios. Provérbios 4:24","id_categoria":"303","id":"6887"},
        {"descricao_categoria":"Verdade","descricao":"Toda a Palavra de Deus é pura; escudo é para os que confiam nele. Provérbios 30:5","id_categoria":"303","id":"6888"},
        {"descricao_categoria":"Verdade","descricao":"Segundo a sua vontade, ele nos gerou pela palavra da verdade, para que fôssemos como primícias das suas criaturas. Tiago 1:18","id_categoria":"303","id":"6889"},
        {"descricao_categoria":"Verdade","descricao":"Escolhi o caminho da verdade; propus-me seguir os teus juízos. Salmos 119:30","id_categoria":"303","id":"6890"},
        {"descricao_categoria":"Verdade","descricao":"Guarda a tua língua do mal, e os teus lábios de falarem o engano. Salmos 34:13","id_categoria":"303","id":"6892"},
        {"descricao_categoria":"Verdade","descricao":"Se dissermos que temos comunhão com ele, e andarmos em trevas, mentimos, e não praticamos a verdade. 1 João 1:6","id_categoria":"303","id":"6893"},
        {"descricao_categoria":"Verdade","descricao":"Não tenho maior gozo do que este, o de ouvir que os meus filhos andam na verdade. 3 João 1:4","id_categoria":"303","id":"6894"},
        {"descricao_categoria":"Verdade","descricao":"Deus é Espírito, e importa que os que o adoram o adorem em espírito e em verdade. João 4:24","id_categoria":"303","id":"6895"},
        {"descricao_categoria":"Verdade","descricao":"Ora, a fé é o firme fundamento das coisas que se esperam, e a prova das coisas que se não vêem. Hebreus 11:1","id_categoria":"303","id":"6896"},
        {"descricao_categoria":"Verdade","descricao":"Antes, seguindo a verdade em amor, cresçamos em tudo naquele que é a cabeça, Cristo, Efésios 4:15","id_categoria":"303","id":"6898"},
        {"descricao_categoria":"Verdade","descricao":"Guia-me na tua verdade, e ensina-me, pois tu és o Deus da minha salvação; por ti estou esperando todo o dia. Salmos 25:5","id_categoria":"303","id":"6900"},
        {"descricao_categoria":"Verdade","descricao":"Porque a palavra do Senhor é reta, e todas as suas obras são fiéis. Salmos 33:4","id_categoria":"303","id":"6901"},
        {"descricao_categoria":"Vícios","descricao":"Se, pois, o Filho vos libertar, verdadeiramente sereis livres. João 8:36","id_categoria":"304","id":"6904"},
        {"descricao_categoria":"Vícios","descricao":"O que encobre as suas transgressões nunca prosperará, mas o que as confessa e deixa, alcançará misericórdia. Provérbios 28:13","id_categoria":"304","id":"6905"},
        {"descricao_categoria":"Vícios","descricao":"E não nos conduzas à tentação; mas livra-nos do mal; porque teu é o reino, e o poder, e a glória, para sempre. Amém. Mateus 6:13","id_categoria":"304","id":"6909"},
        {"descricao_categoria":"Vícios","descricao":"Estai, pois, firmes na liberdade com que Cristo nos libertou, e não torneis a colocar-vos debaixo do jugo da servidão. Gálatas 5:1","id_categoria":"304","id":"6910"},
        {"descricao_categoria":"Vícios","descricao":"E não vos embriagueis com vinho, em que há contenda, mas enchei-vos do Espírito; Efésios 5:18","id_categoria":"304","id":"6912"},
        {"descricao_categoria":"Vícios","descricao":"Sabendo que a prova da vossa fé opera a paciência. Tiago 1:3","id_categoria":"304","id":"6916"},
        {"descricao_categoria":"Vícios","descricao":"O vinho é escarnecedor, a bebida forte alvoroçadora; e todo aquele que neles errar nunca será sábio. Provérbios 20:1","id_categoria":"304","id":"6919"},
        {"descricao_categoria":"Vícios","descricao":"Vigiai e orai, para que não entreis em tentação; na verdade, o espírito está pronto, mas a carne é fraca. Mateus 26:41","id_categoria":"304","id":"6922"},
        {"descricao_categoria":"Vícios","descricao":"Sujeitai-vos, pois, a Deus, resisti ao diabo, e ele fugirá de vós. Tiago 4:7","id_categoria":"304","id":"6923"},
        {"descricao_categoria":"Vícios","descricao":"Respondeu-lhes Jesus: Em verdade, em verdade vos digo que todo aquele que comete pecado é servo do pecado. João 8:34","id_categoria":"304","id":"6924"},
        {"descricao_categoria":"Vícios","descricao":"Não bebas mais água só, mas usa de um pouco de vinho, por causa do teu estômago e das tuas freqüentes enfermidades. 1 Timóteo 5:23","id_categoria":"304","id":"6925"},
        {"descricao_categoria":"Vida Eterna","descricao":"E a vida eterna é esta: que te conheçam, a ti só, por único Deus verdadeiro, e a Jesus Cristo, a quem enviaste. João 17:3","id_categoria":"305","id":"6930"},
        {"descricao_categoria":"Vida Eterna","descricao":"Para que, assim como o pecado reinou na morte, também a graça reinasse pela justiça para a vida eterna, por Jesus Cristo nosso Senhor. Romanos 5:21","id_categoria":"305","id":"6931"},
        {"descricao_categoria":"Vida Eterna","descricao":"E irão estes para o tormento eterno, mas os justos para a vida eterna. Mateus 25:46","id_categoria":"305","id":"6932"},
        {"descricao_categoria":"Vida Eterna","descricao":"Eu sou o Alfa e o Ômega, o princípio e o fim, diz o Senhor, que é, e que era, e que há de vir, o Todo-Poderoso. Apocalipse 1:8","id_categoria":"305","id":"6935"},
        {"descricao_categoria":"Vida Eterna","descricao":"E o testemunho é este: que Deus nos deu a vida eterna; e esta vida está em seu Filho. 1 João 5:11","id_categoria":"305","id":"6936"},
        {"descricao_categoria":"Vida Eterna","descricao":"A vida eterna aos que, com perseverança em fazer bem, procuram glória, honra e incorrupção; Romanos 2:7","id_categoria":"305","id":"6937"},
        {"descricao_categoria":"Vida Eterna","descricao":"Portanto, pode também salvar perfeitamente os que por ele se chegam a Deus, vivendo sempre para interceder por eles. Hebreus 7:25","id_categoria":"305","id":"6938"},
        {"descricao_categoria":"Vida Eterna","descricao":"Aquele que crê no Filho tem a vida eterna; mas aquele que não crê no Filho não verá a vida, mas a ira de Deus sobre ele permanece. João 3:36","id_categoria":"305","id":"6939"},
        {"descricao_categoria":"Vida Eterna","descricao":"Porque o que me achar, achará a vida, e alcançará o favor do Senhor. Provérbios 8:35","id_categoria":"305","id":"6942"},
        {"descricao_categoria":"Vida Eterna","descricao":"E esta é a promessa que ele nos fez: a vida eterna. 1 João 2:25","id_categoria":"305","id":"6943"},
        {"descricao_categoria":"Vida Eterna","descricao":"E o mundo passa, e a sua concupiscência; mas aquele que faz a vontade de Deus permanece para sempre. 1 João 2:17","id_categoria":"305","id":"6946"},
        {"descricao_categoria":"Vida Eterna","descricao":"Respondeu-lhe, pois, Simão Pedro: Senhor, para quem iremos nós? Tu tens as palavras da vida eterna. João 6:68","id_categoria":"305","id":"6952"},
        {"descricao_categoria":"Vida Eterna","descricao":"Porque o salário do pecado é a morte, mas o dom gratuito de Deus é a vida eterna, por Cristo Jesus nosso Senhor. Romanos 6:23","id_categoria":"305","id":"6957"},
        {"descricao_categoria":"Vida Eterna","descricao":"Porque o que semeia na sua carne, da carne ceifará a corrupção; mas o que semeia no Espírito, do Espírito ceifará a vida eterna. Gálatas 6:8","id_categoria":"305","id":"6959"},
        {"descricao_categoria":"Vida Eterna","descricao":"Quem achar a sua vida perdê-la-á; e quem perder a sua vida, por amor de mim, achá-la-á. Mateus 10:39","id_categoria":"305","id":"6962"},
        {"descricao_categoria":"Vida Eterna","descricao":"Porque a nossa leve e momentânea tribulação produz para nós um peso eterno de glória mui excelente; 2 Coríntios 4:17","id_categoria":"305","id":"6963"},
        {"descricao_categoria":"Vida Nova","descricao":"Assim também vós considerai-vos certamente mortos para o pecado, mas vivos para Deus em Cristo Jesus nosso Senhor. Romanos 6:11","id_categoria":"306","id":"6969"},
        {"descricao_categoria":"Vida Nova","descricao":"Assim que, se alguém está em Cristo, nova criatura é; as coisas velhas já passaram; eis que tudo se fez novo. 2 Coríntios 5:17","id_categoria":"306","id":"6974"},
        {"descricao_categoria":"Vida Nova","descricao":"Se confessarmos os nossos pecados, ele é fiel e justo para nos perdoar os pecados, e nos purificar de toda a injustiça. 1 João 1:9","id_categoria":"306","id":"6977"},
        {"descricao_categoria":"Vida Nova","descricao":"Onde não há grego, nem judeu, circuncisão, nem incircuncisão, bárbaro, cita, servo ou livre; mas Cristo é tudo, e em todos. Colossenses 3:11","id_categoria":"306","id":"6979"},
        {"descricao_categoria":"Vida","descricao":"A minha carne e o meu coração desfalecem; mas Deus é a fortaleza do meu coração, e a minha porção para sempre. Salmos 73:26","id_categoria":"307","id":"6980"},
        {"descricao_categoria":"Vida","descricao":"Ouve, filho meu, e aceita as minhas palavras, e se multiplicarão os anos da tua vida. Provérbios 4:10","id_categoria":"307","id":"6982"},
        {"descricao_categoria":"Vida","descricao":"O que guarda a sua boca conserva a sua alma, mas o que abre muito os seus lábios se destrói. Provérbios 13:3","id_categoria":"307","id":"6983"},
        {"descricao_categoria":"Vida","descricao":"Faze-me saber os teus caminhos, Senhor; ensina-me as tuas veredas. Salmos 25:4","id_categoria":"307","id":"6984"},
        {"descricao_categoria":"Vida","descricao":"O que adquire entendimento ama a sua alma; o que cultiva a inteligência achará o bem. Provérbios 19:8","id_categoria":"307","id":"6985"},
        {"descricao_categoria":"Vida","descricao":"E a vida eterna é esta: que te conheçam, a ti só, por único Deus verdadeiro, e a Jesus Cristo, a quem enviaste. João 17:3","id_categoria":"307","id":"7000"},
        {"descricao_categoria":"Vida","descricao":"O Senhor te guardará de todo o mal; guardará a tua alma. O Senhor guardará a tua entrada e a tua saída, desde agora e para sempre. Salmos 121:7,8","id_categoria":"307","id":"7001"},
        {"descricao_categoria":"Vida","descricao":"Sobre tudo o que se deve guardar, guarda o teu coração, porque dele procedem as fontes da vida. Provérbios 4:23","id_categoria":"307","id":"7002"},
        {"descricao_categoria":"Vida","descricao":"Honra a teu pai e a tua mãe, para que se prolonguem os teus dias na terra que o Senhor teu Deus te dá. Êxodo 20:12","id_categoria":"307","id":"7004"},
        {"descricao_categoria":"Vida","descricao":"E Jesus lhes disse: Eu sou o pão da vida; aquele que vem a mim não terá fome, e quem crê em mim nunca terá sede. João 6:35","id_categoria":"307","id":"7005"},
        {"descricao_categoria":"Vida","descricao":"Cantarei ao Senhor enquanto eu viver; cantarei louvores ao meu Deus, enquanto eu tiver existência. Salmos 104:33","id_categoria":"307","id":"7006"},
        {"descricao_categoria":"Vida","descricao":"Portanto, vede prudentemente como andais, não como néscios, mas como sábios, Remindo o tempo; porquanto os dias são maus. Efésios 5:15,16","id_categoria":"307","id":"7027"},
        {"descricao_categoria":"Vida","descricao":"Como na água o rosto corresponde ao rosto, assim o coração do homem ao homem. Provérbios 27:19","id_categoria":"307","id":"7028"},
        {"descricao_categoria":"Vitória","descricao":"Portanto, tomai toda a armadura de Deus, para que possais resistir no dia mau e, havendo feito tudo, ficar firmes. Efésios 6:13","id_categoria":"308","id":"7030"},
        {"descricao_categoria":"Vitória","descricao":"E o Senhor disse a Josué: Não os temas, porque os tenho dado na tua mão; nenhum deles te poderá resistir. Josué 10:8","id_categoria":"308","id":"7031"},
        {"descricao_categoria":"Vizinho","descricao":"Um faz diferença entre dia e dia, mas outro julga iguais todos os dias. Cada um esteja inteiramente seguro em sua própria mente. Romanos 14:5","id_categoria":"309","id":"7056"},
        {"descricao_categoria":"Vizinho","descricao":"Por isso exortai-vos uns aos outros, e edificai-vos uns aos outros, como também o fazeis. 1 Tessalonicenses 5:11","id_categoria":"309","id":"7057"},
        {"descricao_categoria":"Vizinho","descricao":"Então, enquanto temos tempo, façamos bem a todos, mas principalmente aos domésticos da fé. Gálatas 6:10","id_categoria":"309","id":"7058"},
        {"descricao_categoria":"Vizinho","descricao":"Portanto, tudo o que vós quereis que os homens vos façam, fazei-lho também vós, porque esta é a lei e os profetas. Mateus 7:12","id_categoria":"309","id":"7059"},
        {"descricao_categoria":"Vizinho","descricao":"Ninguém busque o proveito próprio; antes cada um o que é de outrem. 1 Coríntios 10:24","id_categoria":"309","id":"7060"},
        {"descricao_categoria":"Vizinho","descricao":"E, finalmente, sede todos de um mesmo sentimento, compassivos, amando os irmãos, entranhavelmente misericordiosos e afáveis. 1 Pedro 3:8","id_categoria":"309","id":"7061"},
        {"descricao_categoria":"Vizinho","descricao":"O amor não faz mal ao próximo. De sorte que o cumprimento da lei é o amor. Romanos 13:10","id_categoria":"309","id":"7062"},
        {"descricao_categoria":"Vizinho","descricao":"Assim que não nos julguemos mais uns aos outros; antes seja o vosso propósito não pôr tropeço ou escândalo ao irmão. Romanos 14:13","id_categoria":"309","id":"7063"},
        {"descricao_categoria":"Vizinho","descricao":"Ao que está aflito devia o amigo mostrar compaixão, ainda ao que deixasse o temor do Todo-Poderoso. Jó 6:14","id_categoria":"309","id":"7065"},
        {"descricao_categoria":"Vizinho","descricao":"Nada façais por contenda ou por vanglória, mas por humildade; cada um considere os outros superiores a si mesmo. Filipenses 2:3","id_categoria":"309","id":"7067"},
        {"descricao_categoria":"Viúvas","descricao":"Abre a tua boca; julga retamente; e faze justiça aos pobres e aos necessitados. Provérbios 31:9","id_categoria":"310","id":"7068"},
        {"descricao_categoria":"Viúvas","descricao":"O Senhor guarda os estrangeiros; sustém o órfão e a viúva, mas transtorna o caminho dos ímpios. Salmos 146:9","id_categoria":"310","id":"7071"},
        {"descricao_categoria":"Viúvas","descricao":"Aprendei a fazer bem; procurai o que é justo; ajudai o oprimido; fazei justiça ao órfão; tratai da causa das viúvas. Isaías 1:17","id_categoria":"310","id":"7073"},
        {"descricao_categoria":"Motivação","descricao":"E tudo quanto fizerdes, fazei-o de todo o coração, como ao Senhor, e não aos homens, Colossenses 3:23","id_categoria":"323","id":"7452"},
        {"descricao_categoria":"Motivação","descricao":"Porque Deus é o que opera em vós tanto o querer como o efetuar, segundo a sua boa vontade. Filipenses 2:13","id_categoria":"323","id":"7453"},
        {"descricao_categoria":"Motivação","descricao":"Posso todas as coisas em Cristo que me fortalece. Filipenses 4:13","id_categoria":"323","id":"7455"},
        {"descricao_categoria":"Motivação","descricao":"Mil cairão ao teu lado, e dez mil à tua direita, mas não chegará a ti. Salmos 91:7","id_categoria":"323","id":"7461"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"Sejam vossos costumes sem avareza, contentando-vos com o que tendes; porque ele disse: Não te deixarei, nem te desampararei. Hebreus 13:5","id_categoria":"326","id":"7566"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"Porque, como as aflições de Cristo são abundantes em nós, assim também é abundante a nossa consolação por meio de Cristo. 2 Coríntios 1:5","id_categoria":"326","id":"7568"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"Porque não desprezou nem abominou a aflição do aflito, nem escondeu dele o seu rosto; antes, quando ele clamou, o ouviu. Salmos 22:24","id_categoria":"326","id":"7569"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"O Senhor é bom, ele serve de fortaleza no dia da angústia, e conhece os que confiam nele. Naum 1:7","id_categoria":"326","id":"7570"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"Isto é a minha consolação na minha aflição, porque a tua palavra me vivificou. Salmos 119:50","id_categoria":"326","id":"7571"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"Assim que, se alguém está em Cristo, nova criatura é; as coisas velhas já passaram; eis que tudo se fez novo. 2 Coríntios 5:17","id_categoria":"326","id":"7573"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"Deus é o nosso refúgio e fortaleza, socorro bem presente na angústia. Salmos 46:1","id_categoria":"326","id":"7582"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"Lança o teu cuidado sobre o Senhor, e ele te susterá; não permitirá jamais que o justo seja abalado. Salmos 55:22","id_categoria":"326","id":"7584"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"Como alguém a quem consola sua mãe, assim eu vos consolarei; e em Jerusalém vós sereis consolados. Isaías 66:13","id_categoria":"326","id":"7588"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"Porque a sua ira dura só um momento; no seu favor está a vida. O choro pode durar uma noite, mas a alegria vem pela manhã. Salmos 30:5","id_categoria":"326","id":"7589"},
        {"descricao_categoria":"Palavras de Conforto","descricao":"O Senhor será também um alto refúgio para o oprimido; um alto refúgio em tempos de angústia. Salmos 9:9","id_categoria":"326","id":"7590"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Eu, eu mesmo, sou o que apago as tuas transgressões por amor de mim, e dos teus pecados não me lembro. Isaías 43:25","id_categoria":"327","id":"7623"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"E perdoa-nos as nossas dívidas, assim como nós perdoamos aos nossos devedores; Mateus 6:12","id_categoria":"327","id":"7625"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"E dizia Jesus: Pai, perdoa-lhes, porque não sabem o que fazem. E, repartindo as suas vestes, lançaram sortes. Lucas 23:34","id_categoria":"327","id":"7628"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Não te vingarás nem guardarás ira contra os filhos do teu povo; mas amarás o teu próximo como a ti mesmo. Eu sou o Senhor. Levítico 19:18","id_categoria":"327","id":"7629"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Porque serei misericordioso para com suas iniqüidades, E de seus pecados e de suas prevaricações não me lembrarei mais. Hebreus 8:12","id_categoria":"327","id":"7630"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Mas Deus, não tendo em conta os tempos da ignorância, anuncia agora a todos os homens, e em todo o lugar, que se arrependam; Atos 17:30","id_categoria":"327","id":"7632"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Antes sede uns para com os outros benignos, misericordiosos, perdoando-vos uns aos outros, como também Deus vos perdoou em Cristo. Efésios 4:32","id_categoria":"327","id":"7633"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Porque, se perdoardes aos homens as suas ofensas, também vosso Pai celestial vos perdoará a vós; Mateus 6:14","id_categoria":"327","id":"7634"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Aquele que encobre a transgressão busca a amizade, mas o que revolve o assunto separa os maiores amigos. Provérbios 17:9","id_categoria":"327","id":"7635"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"O que encobre as suas transgressões nunca prosperará, mas o que as confessa e deixa, alcançará misericórdia. Provérbios 28:13","id_categoria":"327","id":"7636"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"E ele é a propiciação pelos nossos pecados, e não somente pelos nossos, mas também pelos de todo o mundo. 1 João 2:2","id_categoria":"327","id":"7641"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Se confessarmos os nossos pecados, ele é fiel e justo para nos perdoar os pecados, e nos purificar de toda a injustiça. 1 João 1:9","id_categoria":"327","id":"7642"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Assim como está longe o oriente do ocidente, assim afasta de nós as nossas transgressões. Salmos 103:12","id_categoria":"327","id":"7644"},
        {"descricao_categoria":"Perdão e Misericórdia","descricao":"Bem-aventurados os misericordiosos, porque eles alcançarão misericórdia; Mateus 5:7","id_categoria":"327","id":"7645"}
        ]
    return a
}