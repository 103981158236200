
export const  getRandomImage = () => {
  const pos = Math.floor(Math.random() * 1084);
  return "https://picsum.photos/id/"+pos+"/1080/1080.jpg";
}
export const  getPos = () => {
  const pos = Math.floor(Math.random() * 4);
  const arr = ["diagonal", "radial" , "horizontal", "vertical"]
  console.log(arr[pos])
  return arr[pos]
}
export const  getRandomFont = () => {
  const pos = Math.floor(Math.random() * 4);
  const arr = ["ECHELON" , "bebasneue", "lobster","rowdies"]
  console.log(arr[pos])
  return arr[pos]
}

export  const upload = (nome, id_categoria, formData) => {

  // file is from a <input> tag or from Drag'n Drop
  // Is the file an image?
  //if (!file || !file.type.match(/image.*/)) return;

  // It is!
  // Let's build a FormData object
  // var fd = new FormData();
  // fd.append("image", file); // Append the file

  // var fd = createBlob(file)

  // Create the XHR (Cross-Domain XHR FTW!!!)
  var xhr = new XMLHttpRequest();
  xhr.open("POST", "https://app.gmpti.com/tema-biblico/admin/form-imagem-robo.php?id_categoria="+id_categoria+"&image_name="+nome+"", true); // Boooom!
  xhr.onload = function() {
     // Big win!
     // The URL of the image is:
     console.log('upload OK => '+nome);
  }

  // Ok, I don't handle the errors. An exercice for the reader.
  // And now, we send the formdata
  // xhr.setRequestHeader("Content-Type", "image/jpeg");
  xhr.send(formData);
}

export const pSBC  = (p, c0, c1, l) => {
  let r,
    g,
    b,
    P,
    f,
    t,
    h,
    i = parseInt,
    m = Math.round,
    a = typeof c1 === "string";
  if (
    typeof p !== "number" ||
    p < -1 ||
    p > 1 ||
    typeof c0 !== "string" ||
    (!c0.startsWith("r") && !c0.startsWith("#")) ||
    (c1 && !a)
  )
    return null;
  //@ts-ignore
  if (!window.pSBCr) {
    //@ts-ignore
    window.pSBCr = (d) => {
      let n = d.length,
        //@ts-ignore
        x= {};
      if (n > 9) {
        [r, g, b, a] = d = d.split(",");
        n = d.length;
        if (n < 3 || n > 4) return null;
        x.r = i(r[3] === "a" ? r.slice(5) : r.slice(4));
        x.g = i(g);
        x.b = i(b);
        //@ts-ignore
        x.a = a ? parseFloat(a) : -1;
      } else {
        if (n === 8 || n === 6 || n < 4) return null;
        if (n < 6)
          d =
            "#" +
            d[1] +
            d[1] +
            d[2] +
            d[2] +
            d[3] +
            d[3] +
            (n > 4 ? d[4] + d[4] : "");
        d = i(d.slice(1), 16);
        if (n === 9 || n === 5) {
          x.r = (d >> 24) & 255;
          x.g = (d >> 16) & 255;
          x.b = (d >> 8) & 255;
          x.a = m((d & 255) / 0.255) / 1000;
        } else {
          x.r = d >> 16;
          x.g = (d >> 8) & 255;
          x.b = d & 255;
          x.a = -1;
        }
      }
      return x;
    };
  }
  h = c0.length > 9;
  h = a ? (c1.length > 9 ? true : c1 === "c" ? !h : false) : h;
  //@ts-ignore
  f = window.pSBCr(c0);
  P = p < 0;
  //@ts-ignore
  t =
    c1 && c1 !== "c"
      ? //@ts-ignore
        window.pSBCr(c1)
      : P
      ? { r: 0, g: 0, b: 0, a: -1 }
      : { r: 255, g: 255, b: 255, a: -1 };
  p = P ? p * -1 : p;
  P = 1 - p;
  if (!f || !t) return null;
  if (l) {
    r = m(P * f.r + p * t.r);
    g = m(P * f.g + p * t.g);
    b = m(P * f.b + p * t.b);
  } else {
    r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5);
    g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5);
    b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
  }
  a = f.a;
  t = t.a;
  //@ts-ignore
  f = a >= 0 || t >= 0;
  //@ts-ignore
  a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0;
  //@ts-ignore
  if (h)
    return (
      "rgb" +
      (f ? "a(" : "(") +
      r +
      "," +
      g +
      "," +
      b +
      //@ts-ignore
      (f ? "," + m(a * 1000) / 1000 : "") +
      ")"
    );
  //@ts-ignore
  else
    return (
      "#" +
      //@ts-ignore
      (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
        .toString(16)
        .slice(1, f ? undefined : -2)
    );
}

export const createBlob = (dataURL) => {
  var BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
    var parts = dataURL.split(',');
    var contentType = parts[0].split(':')[1];
    var raw = decodeURIComponent(parts[1]);
    return new Blob([raw], { type: contentType });
  }
  var parts = dataURL.split(BASE64_MARKER);
  var contentType = parts[0].split(':')[1];
  var raw = window.atob(parts[1]);
  var rawLength = raw.length;

  var uInt8Array = new Uint8Array(rawLength);

  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  //  return uInt8Array
  return new Blob([uInt8Array], { type: contentType });
}